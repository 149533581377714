import {
  useOnboardStripe,
  usePayoutOnboardStatus,
} from "@/services/UserService";
import { IActionCardProps } from "@/components/misc/ActionCard";
import { SITE_PATHS } from "@/config/routing";
import Icon from "@/components/misc/Icon";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { usePendingContractorOnboardingPrompt } from "@/features/contracts/PendingContractorOnboardingPrompt";

function useOnboardingAction() {
  const { onboardingCountry } = usePayoutOnboardStatus();
  const { isReady, onboard, isOnboarding } = useOnboardStripe();
  const { show: canOnboard } = usePendingContractorOnboardingPrompt();

  if (!isReady || !canOnboard) {
    return null;
  }

  const ctaProps = {
    children: "Complete verification",
    ...(onboardingCountry
      ? { onClick: () => onboard({ countryCode: onboardingCountry }) }
      : {
          link: SITE_PATHS.PAYOUT_ONBOARDING_PAGE,
        }),
  };
  const icon = (
    <Icon isSrcRelative src="verification_required.svg" customSize="1.5rem" />
  );
  const content =
    "You need to complete the identity verification to access your earnings.";

  return {
    icon,
    title: "Complete verification",
    content: (
      <>
        {content}
        {isOnboarding && <SplashScreen />}
      </>
    ),
    color: "#F1F5F9",
    ctaProps,
    priority: "urgent",
    isOnboarding,
  } satisfies IActionCardProps & Record<string, any>;
}

export default useOnboardingAction;
