"use client";
import React, { useMemo } from "react";

import {
  getLoggedInUserContractUserType,
  isContractCompleted,
  useContractList,
} from "@/services/ContractsService";
import { SITE_PATHS } from "@/config/routing";
import { generateTimelineItems } from "@/features/contracts/ContractTimeline";

import {
  StyledLink,
  StyledPrimaryHeading,
  StyledSectionHeadingContainer,
} from "../../HomePage.styles";
import {
  StyledContainer,
  StyledContractCard,
  StyledNoOffers,
} from "./RecentSection.styles";

const RecentSection: React.FC<{ className?: string }> = ({ className }) => {
  const { data: contractList } = useContractList({
    filters: { sort: "updated_at:asc" },
  });

  const contractListSorted = useMemo(
    () =>
      [...contractList]
        .map((contract) => {
          const userType = getLoggedInUserContractUserType(contract);
          let action = "";

          if (userType) {
            const { items, currentIndex } = generateTimelineItems({
              contract,
              userType,
            });
            action = items[currentIndex]?.ctaAction || "";
          }

          return {
            ...contract,
            action,
          };
        })
        .filter(({ action }: any) => action === "submit-work" || !action)
        .sort((a, b) => +isContractCompleted(a) - +isContractCompleted(b))
        .sort((a, b) => +!!b.action - +!!a.action)
        .slice(0, 3),
    [contractList]
  );

  const hasNoData = !contractListSorted.length

  return (
    <StyledContainer className={className}>
      <StyledSectionHeadingContainer>
        <StyledPrimaryHeading>Active Jobs</StyledPrimaryHeading>

        <StyledLink to={SITE_PATHS.CONTRACTS_PAGE}>
          View all
        </StyledLink>
      </StyledSectionHeadingContainer>

      {hasNoData ? (
        <StyledNoOffers noJobsContent />
      ) : (
        contractListSorted.map((contract, i) => (
          <StyledContractCard
            key={contract.id}
            contract={contract}
            className={i !== 0 ? "mt-3" : ""}
          />
        ))
      )}
    </StyledContainer>
  );
};

export default RecentSection;
