import React, { useMemo } from "react";
import lodashGet from "lodash/get";

import Avatar from "@/components/misc/Avatar";
import Icon from "@/components/misc/Icon";
import { getChatRoomPageUrl } from "@/services/ChatService";
import { formatDate } from "@/utils/date";
import { getUserFullName, useUserProfile } from "@/services/UserService";
import {
  CustomMessageRenderer,
  USER_CHANNEL_SHORT_REGEX,
} from "@/services/ChatServiceNew";
import { getMatches } from "@/utils/string";
import { useAuth } from "@/services/Authentication";

import {
  StyledCard,
  StyledContent,
  StyledLeftSection,
  StyledBottomContainer,
  StyledRightSection,
  StyledTopContainer,
  StyledUsername,
  StyledMessageCount,
  StyledDate,
  StyledLink,
} from "./ChatCard.styles";
import { IChatCardProps } from "./ChatCard.types";
import { checkIsSystemMessage } from "@/services/ChatServiceNew/ChatService.utils";

const ChatCard: React.FC<IChatCardProps> = ({
  hasAttachment,
  unreadMessageCount,
  content,
  lastMessageDate,
  className,
  openInNewTab,
  active = false,
  channel_id: channelId = "",
}) => {
  const { loggedInUserId } = useAuth();

  const userIds: number[] = useMemo(() => {
    if (!channelId) {
      return [];
    }

    const match = channelId.matchAll(USER_CHANNEL_SHORT_REGEX);
    const members = match
      ? (
          lodashGet(getMatches(channelId, USER_CHANNEL_SHORT_REGEX), "[0]") ||
          ""
        )
          .split("_")
          .map(Number)
      : [];

    return members;
  }, [channelId]);

  const otherUserId = useMemo(() => {
    return userIds.find((id) => id !== loggedInUserId) || 0;
  }, [userIds, loggedInUserId]);

  const {
    accountData: otherUserDetails,
    isLoading: isLoadingOtherUserDetails,
  } = useUserProfile(otherUserId);

  const username = otherUserDetails ? getUserFullName(otherUserDetails) : "";
  const avatarImage = otherUserDetails?.picture || "";

  const formattedLastMessageDate = useMemo(
    () =>
      lastMessageDate ? formatDate(lastMessageDate, "H:MM A") : lastMessageDate,
    [lastMessageDate]
  );

  const chatRoomLink = useMemo(() => {
    const match = channelId.matchAll(USER_CHANNEL_SHORT_REGEX);
    const userChannelId = match
      ? lodashGet(getMatches(channelId, USER_CHANNEL_SHORT_REGEX), "[0]")
      : "";

    if (userChannelId) {
      return getChatRoomPageUrl(userChannelId);
    }

    return "";
  }, [channelId]);

  const formattedMessage = useMemo(() => {
    const { isSystemMessage, messageData } = checkIsSystemMessage({
      text: content,
    });

    if (!isSystemMessage || !messageData) {
      return <>{content}</>;
    }

    return (
      <CustomMessageRenderer
        hideActionButtons
        systemMessage={messageData}
        messageText={content}
      />
    );
  }, [content]);

  if (isLoadingOtherUserDetails || !otherUserId) {
    return null;
  }

  return (
    <StyledCard $active={active} className={className}>
      <StyledLink
        to={chatRoomLink}
        target={openInNewTab ? "_blank" : "_self"}
      />

      <StyledTopContainer>
        <StyledLeftSection>
          <Avatar className="avatar" img={avatarImage} firstName={username} />
        </StyledLeftSection>

        <StyledRightSection>
          <StyledUsername>{username}</StyledUsername>

          {!!formattedLastMessageDate && (
            <StyledDate>{formattedLastMessageDate}</StyledDate>
          )}
        </StyledRightSection>
      </StyledTopContainer>

      {(!!hasAttachment || !!content || !!unreadMessageCount) && (
        <StyledBottomContainer>
          <StyledLeftSection>
            {!!hasAttachment && (
              <Icon
                className="icon"
                src="/assets/images/icons/clip.svg"
                alt="attachment"
                size="xs"
              />
            )}
          </StyledLeftSection>

          {(!!formattedMessage || !!unreadMessageCount) && (
            <StyledRightSection>
              {!!formattedMessage && (
                <StyledContent>{formattedMessage}</StyledContent>
              )}

              {!!unreadMessageCount && (
                <StyledMessageCount>{unreadMessageCount}</StyledMessageCount>
              )}
            </StyledRightSection>
          )}
        </StyledBottomContainer>
      )}
    </StyledCard>
  );
};

export default ChatCard;
