import React from "react";
import lodashGet from "lodash/get";

import EditorField from "@/components/input/EditorFieldNew";

import { useContractForm } from "../../OfferUpdateForm.config";

const MilestoneDescriptionField: React.FC<{
  milestoneIndex: number;
  className?: string;
  readOnly?: boolean;
  onClick?: any;
  disabled?: boolean;
}> = ({ milestoneIndex, className, readOnly = false, onClick, disabled }) => {
  const {
    values,
    setFieldValue,
    touched,
    showDecriptionTip,
    alreadyShownDecriptionTip,
  } = useContractForm();

  const fieldKey = `milestones[${milestoneIndex}].description`;
  const isTouched = lodashGet(touched, fieldKey, false);
  const value = lodashGet(values, fieldKey, "");

  const milestonesName = lodashGet(
    values,
    `milestones[${milestoneIndex}].name`,
    ""
  );

  const title =
    milestonesName ||
    (milestoneIndex ? `Milestone ${milestoneIndex + 1}` : "Description");

  const handleClick =
    !alreadyShownDecriptionTip && !readOnly && !disabled
      ? showDecriptionTip
      : undefined;

  return (
    <div onClick={handleClick}>
      <EditorField
        showSaveButton
        focusOnMount={!readOnly && !alreadyShownDecriptionTip}
        hasError={!!isTouched && !value}
        disabled={disabled}
        readOnly={readOnly}
        className={className}
        label="Milestone Description"
        placeholder="Add details about the milestone"
        value={{ markdown: value }}
        onChange={({ markdown }) => {
          setFieldValue(fieldKey, markdown);
        }}
        drawerTitle={title}
        onClick={onClick}
      />
    </div>
  );
};

export default MilestoneDescriptionField;
