"use client";

import styled from "@emotion/styled";

import RadioGroup from "@/components/input/RadioGroup";
import { addHoverHighlight, squareSizing } from "@/utils/css";

export const StyledContainer = styled(RadioGroup.Container)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledIconContainer = styled.div<{ $isSelected: boolean }>`
  ${squareSizing("44px")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-border-ukwn-1, #f5f5f5);
  border-radius: 10rem;
  background: var(--clr-background-primary, #fff);

  ${({ $isSelected }) =>
    $isSelected
      ? `
        border: 1px solid var(--clr-border-ukwn-6, #E5E5E5);
    `
      : ` 
        border: 1px solid var(--clr-border-50, #eceff3);
    `};
`;

export const StyledSelectedIconContainer = styled.div<{
  $isSelected: boolean;
  $disabled: boolean;
}>`
  ${squareSizing("20px")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-border-ukwn-1, #f5f5f5);
  border-radius: 10rem;
  background: var(--clr-background-primary, #fff);

  ${({ $isSelected, $disabled }) =>
    $disabled
      ? `
        border: 1px solid var(--clr-border-ukwn-5, #dfe1e7);
        background: var(--clr-background-ukwn-4, #DFE1E7);
        `
      : $isSelected
      ? `
        border: 1px solid var(--clr-border-ukwn-7, #A19DF1);
        background: var(--clr-background-ukwn-3, #A19DF1);
        `
      : ` 
        border: 1px solid var(--clr-border-50, #eceff3);
        `};
`;

export const StyledContent = styled.div<{ $isSelected: boolean }>`
  flex: 1;
  font-size: 0.875rem;
  font-weight: 600;
`;

export const StyledItem = styled.label<{
  $isSelected: boolean;
  $disabled: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 1.25rem;
  min-height: 4.125rem;
  font-size: 0.875rem;
  font-weight: ${({ $disabled }) => ($disabled ? 600 : 500)};
  line-height: 160%;
  border-radius: 0.75rem;
  transition: background 120ms ease;
  will-change: background;
  cursor: pointer;

  ${({ $isSelected, $disabled }) =>
    $disabled
      ? `
        background: var(--clr-ukwn-3, #A4ACB9);
        color: var(--clr-text-white, #fff); 
        cursor: not-allowed;
        `
      : $isSelected
      ? `
        background: var(--clr-primary-100, #5F57FF);
        color: var(--clr-text-white, #fff); 
    `
      : `
        background: var(--clr-background-ukwn-1, #FAFAFA);
        color: var(--clr-text-ukwn-1, #171717); 
    `};

  input {
    display: none;
  }

  ${addHoverHighlight()}
`;

export const StyledDot = styled.div<{ $dotColor: string }>`
  ${squareSizing("0.75rem")};
  border-radius: 10rem;
  background: ${({ $dotColor }) => $dotColor};
`;
