import React, { useMemo } from "react";

import Avatar from "@/components/misc/Avatar";
import Icon from "@/components/misc/Icon";
import { getChatRoomPageUrl } from "@/services/ChatService";
import Tag from "@/components/misc/Tag";
import { formatDate } from "@/utils/date";
import {
  getContractOtherParticipant,
  isContractAnOffer,
  useContractDetails,
} from "@/services/ContractsService";
import { getUserFullName } from "@/services/UserService";

import {
  StyledCard,
  StyledContent,
  StyledLeftSection,
  StyledBottomContainer,
  StyledRightSection,
  StyledTitle,
  StyledTopContainer,
  StyledUsername,
  StyledMessageCount,
  StyledDate,
  StyledLink,
} from "./ChatCard.styles";
import { IChatCardProps } from "./ChatCard.types";
import { checkIsSystemMessage } from "@/services/ChatServiceNew/ChatService.utils";
import { CustomMessageRenderer } from "@/services/ChatServiceNew";

const ChatCard: React.FC<IChatCardProps> = ({
  contractId,
  // avatarImage,
  // username,
  title,
  hasAttachment,
  unreadMessageCount,
  content,
  lastMessageDate,
  className,
  // isOffer,
  openInNewTab,
  active = false,
}) => {
  const { contractDetails } = useContractDetails({
    contractId,
    skipFetchIfExists: true,
  });

  const contractOtherParticipant = useMemo(
    () =>
      contractDetails ? getContractOtherParticipant(contractDetails) : null,
    [contractDetails]
  );
  const username = contractOtherParticipant?.user
    ? getUserFullName(contractOtherParticipant?.user)
    : "";
  const avatarImage = contractOtherParticipant?.user?.picture || "";

  const isOffer = contractDetails ? isContractAnOffer(contractDetails) : false;

  const formattedLastMessageDate = useMemo(
    () =>
      lastMessageDate ? formatDate(lastMessageDate, "H:MM A") : lastMessageDate,
    [lastMessageDate]
  );

  const formattedMessage = useMemo(() => {
    const { isSystemMessage, messageData } = checkIsSystemMessage({
      text: content,
    });

    if (!isSystemMessage || !messageData) {
      return <>{content}</>;
    }

    return (
      <CustomMessageRenderer
        hideActionButtons
        systemMessage={messageData}
        messageText={content}
      />
    );
  }, [content]);

  return (
    <StyledCard $active={active} className={className}>
      <StyledLink
        to={getChatRoomPageUrl(contractId)}
        target={openInNewTab ? "_blank" : "_self"}
      />

      <StyledTopContainer>
        <StyledLeftSection>
          <Avatar className="avatar" img={avatarImage} firstName={username} />
        </StyledLeftSection>

        <StyledRightSection>
          {isOffer && (
            <Tag variant="filled" colorVariant="primary" className="py-1 mb-2">
              Job offer
            </Tag>
          )}
          <StyledUsername>{username}</StyledUsername>
          <StyledTitle>{title}</StyledTitle>

          {!!formattedLastMessageDate && (
            <StyledDate>{formattedLastMessageDate}</StyledDate>
          )}
        </StyledRightSection>
      </StyledTopContainer>

      {(!!hasAttachment || !!formattedMessage || !!unreadMessageCount) && (
        <StyledBottomContainer>
          <StyledLeftSection>
            {!!hasAttachment && (
              <Icon
                className="icon"
                src="/assets/images/icons/clip.svg"
                alt="attachment"
                size="xs"
              />
            )}
          </StyledLeftSection>

          {(!!formattedMessage || !!unreadMessageCount) && (
            <StyledRightSection>
              {!!formattedMessage && (
                <StyledContent>{formattedMessage}</StyledContent>
              )}

              {!!unreadMessageCount && (
                <StyledMessageCount>{unreadMessageCount}</StyledMessageCount>
              )}
            </StyledRightSection>
          )}
        </StyledBottomContainer>
      )}
    </StyledCard>
  );
};

export default ChatCard;
