import React, { useCallback, useEffect, useMemo, useState } from "react";

import Calendar from "@/components/input/Calendar";
import { useDrawerState } from "@/components/misc/Drawer";
import { formatDate } from "@/utils/date";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import Modal from "@/components/misc/Modal";

import { IDateFieldProps } from "./DateField.types";
import { StyledDatesDisplay, StyledTextField } from "./DateField.styles";
import DateDisplay from "./components/DateDisplay";

const DateField: React.FC<IDateFieldProps> = ({
  drawerProps,
  calendarProps,
  calendarType,
  startDateDisplayProps,
  endDateDisplayProps,
  dateDisplayProps,
  ...restProps
}) => {
  const {
    value: valueFromProps,
    onChange: onChangeFromProps,
    rangeValue: rangeValueFromProps,
    onRangeChange: onRangeChangeFromProps,
  } = calendarProps;
  const drawerState = useDrawerState();

  const [value, setValue] = useState(valueFromProps);
  const [rangeValue, setRangeValue] = useState(rangeValueFromProps);

  const formattedDate = useMemo(() => {
    if (calendarType === "single-date") {
      if (!valueFromProps) {
        return "";
      }

      return formatDate(valueFromProps);
    }

    if (calendarType === "range") {
      if (!rangeValueFromProps) {
        return "";
      }

      const { start, end } = rangeValueFromProps;

      const dateParts: string[] = [];

      if (start) {
        dateParts.push(formatDate(start, "MMM D"));

        if (end) {
          dateParts.push(formatDate(end, "MMM D"));
        }
      }

      return dateParts.join(" - ");
    }

    return "";
  }, [rangeValueFromProps, valueFromProps, calendarType]);

  const formattedStartDate = useMemo(() => {
    if (!rangeValue) {
      return "";
    }

    if (rangeValue.start) {
      return formatDate(rangeValue.start);
    }

    return "";
  }, [rangeValue]);

  const formattedEndDate = useMemo(() => {
    if (!rangeValue) {
      return "";
    }

    if (rangeValue.end) {
      return formatDate(rangeValue.end);
    }

    return "";
  }, [rangeValue]);

  const formattedSingleDate = useMemo(() => {
    if (!value) {
      return "";
    }

    return formatDate(value);
  }, [value]);

  //------------------------

  const clearDate = useCallback((pickerType: "start" | "end" | "both") => {
    if (pickerType === "both") {
      setRangeValue({ start: null, end: null });
    } else if (pickerType === "start") {
      setRangeValue({ start: null, end: null });
    } else if (pickerType === "end") {
      setRangeValue({ start: null, end: null });
      // setRangeValue((value) => ({ ...value, end: null }));
    }

    setValue(null);
  }, []);

  const close = useCallback(() => {
    setTimeout(() => {
      drawerState.close();
    }, 100);
  }, [drawerState]);

  const handleApply = useCallback(() => {
    if (onChangeFromProps) {
      onChangeFromProps(value);
    }

    if (onRangeChangeFromProps) {
      onRangeChangeFromProps(rangeValue);
    }

    close();
  }, [rangeValue, value, onChangeFromProps, onRangeChangeFromProps, close]);

  //------------------------

  const { isOpen } = drawerState;
  useEffect(() => {
    if (isOpen) {
      setValue(valueFromProps);
      setRangeValue(rangeValueFromProps);
    }
  }, [isOpen, valueFromProps, rangeValueFromProps]);

  //------------------------

  return (
    <>
      <StyledTextField
        inputMode="none"
        placeholder={calendarType === "range" ? "Dates" : "Date"}
        prependContent={<Icon isSrcRelative src="calendar.svg" size="xs" />}
        {...restProps}
        onFocus={() => {
          // drawerState.open();a
        }}
        onClick={(e) => {
          drawerState.open();
          if (restProps.onClick) {
            restProps.onClick(e);
          }
        }}
        value={formattedDate}
      />
      <Modal
        state={drawerState}
        //-----------------
        // canDrag={false}
        // headerProps={{ children: "Set deadlines" }}
        // modalProps={{ width: "768px" }}
        // zIndex={120}
        //-----------------
        showCloseButton
        contentContainerProps={{
          style: {
            padding: 0,
            paddingTop: "2rem",
          },
        }}
      >
        <div className="p-3">
          <StyledDatesDisplay>
            {calendarType === "range" && (
              <>
                <DateDisplay
                  label={startDateDisplayProps?.label || "Start Date"}
                  value={formattedStartDate}
                  onClearClick={() => {
                    clearDate("start");
                  }}
                />
                <DateDisplay
                  label={endDateDisplayProps?.label || "End date"}
                  value={formattedEndDate}
                  onClearClick={() => {
                    clearDate("end");
                  }}
                />
              </>
            )}

            {calendarType === "single-date" && (
              <DateDisplay
                label={dateDisplayProps?.label || "Date"}
                value={formattedSingleDate}
                onClearClick={() => {
                  clearDate("start");
                }}
              />
            )}
          </StyledDatesDisplay>

          <Calendar
            className="mt-4"
            {...(calendarType === "single-date"
              ? { type: "single-date", value, onChange: setValue }
              : {
                  type: "range",
                  rangeValue,
                  onRangeChange: setRangeValue,
                  defaultRangeValue: rangeValueFromProps,
                })}
          />

          <Button className="w-100 mt-4" onClick={handleApply}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DateField;
