"use client";

import { Body } from "@/components/Typography";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  padding: 1rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${screenLargerThan.tablet} {
    gap: 2rem;
  }
`;

export const StyledContentContainer = styled.div`
  ${screenSmallerThan.tablet} {
    flex-grow: 1;
  }
`;

export const StyledPrimaryContent = styled.h1`
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--clr-text-900, #0d0d12);
  line-height: 2rem;
  margin: 0;
`;

export const StyledSecondaryContent = styled(Body)`
  color: var(--clr-neutral-solid-700, #272835);
`;

export const StyledSmallContent = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--clr-text-500, #666d80);
  line-height: 1.25rem;
`;
