import { useCallback, useState } from "react";
import styled from "@emotion/styled";

import { contractService } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body } from "@/components/Typography";
import Button from "@/components/input/Button";
import { useToast } from "@/components/misc/Toast";
import { selectContract, useAppSelector } from "@/services/Store";

import { ContractId } from "../ContractsService.types";

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

function useDeleteOffer({ contractId }: { contractId: ContractId }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const confirmationModalState = useModalState();
  const { createToast } = useToast();
  const contractDetails = useAppSelector(selectContract(contractId));

  // ----------------------------------

  const deleteOffer = useCallback(() => {
    setIsDeleting(true);

    const createSuccessToast = () => {
      const contractName = contractDetails?.title;

      createToast({
        title: "Offer deleted!",
        description: contractName
          ? `Deleted the offer: ${contractName}`
          : undefined,
        timeoutInMilliseconds: 5000,
      });
    };

    return contractService
      .deleteOffer({ contractId })
      .then(() => {
        confirmationModalState.close();
        createSuccessToast();
      })
      .catch(() => {
        createToast({
          variant: "error",
          title: "Something went wrong, offer is not deleted",
        });
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [contractId, confirmationModalState, createToast, contractDetails]);

  // ----------------------------------

  const submitButtonText = isDeleting ? "Deleting offer..." : "Delete offer";

  const confirmationJsx = (
    <Modal state={confirmationModalState} width="300px">
      <Body size="lg">Are you sure want to delete this offer?</Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={confirmationModalState.close}
        >
          Cancel
        </Button>
        <Button
          colorVariant="danger"
          disabled={isDeleting}
          onClick={deleteOffer}
        >
          {submitButtonText}
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );

  return {
    isDeleting,
    deleteOffer: confirmationModalState.open,
    jsx: confirmationJsx,
  };
}

export default useDeleteOffer;
