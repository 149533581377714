import React, { useMemo, useRef } from "react";
import styled from "@emotion/styled";

import {
  ContractCreationFlow,
  ContractType,
} from "@/services/ContractsService";
import { formatDate } from "@/utils/date";
import FilesInput from "@/features/input/FilesInput";
import DetailsTable, { Item } from "@/components/data/DetailsTable";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { screenLargerThan } from "@/styles";

import { useContractForm } from "../../../ContractForm.context";
import {
  StyledBox,
  StyledDesktopOnlyStepHeading,
  StyledStep,
  StyledStepFloatingContent,
  StyledStepTopContent,
} from "../../../ContractForm.styles";
import StepContinueButton from "../../inputs/StepContinueButton";
import HeaderLayout from "../../misc/HeaderLayout";
import DescriptionField from "../../inputs/TransactionDescriptionField";
import {
  StyledContractTitle,
  StyledContractValue,
  StyledSectionHeading,
} from "./ReviewStep.styles";
import MilestoneInput from "../../inputs/MilestoneInput";
import StepBackButton from "../../inputs/StepBackButton";
import { Step } from "../../../ContractForm.types";
import TransactionFilesInput from "../../inputs/TransactionFilesInput";

const StyledContainer = styled.div`
  height: 100%;

  ${screenLargerThan.tablet} {
    max-width: 44rem;
    margin-inline: auto;
  }
`;

const StyledEditButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ReviewStep: React.FC = () => {
  const { values, flow, gotToStep } = useContractForm();

  const {
    title,
    milestones,
    client_name,
    contract_type,
    start_date,
    end_date,
    files = [],
  } = values;

  const isMilestoneContract = values.contract_type === ContractType.Milestone;
  const isAiFlow = flow === ContractCreationFlow.AI;
  const showEditButton = isAiFlow;
  const hasAttachments = !!files?.length;
  const showFilesInput = useRef(isAiFlow && !hasAttachments).current;

  const totalValue = useMemo(
    () => milestones.reduce((sum, { value }) => sum + value, 0),
    [milestones]
  );

  const clientDetailFields = useMemo(() => {
    return client_name
      ? [
          {
            label: "Client Name",
            value: client_name,
          },
        ]
      : [];
  }, [client_name]);

  const transactonDetailFields = useMemo(() => {
    let fields: Item[] = [];

    if (contract_type !== ContractType.Milestone) {
      if (start_date) {
        fields.push({
          label: "Start Date",
          value: start_date ? formatDate(start_date) : "-",
        });
      }

      if (end_date) {
        fields.push({
          label: "Due Date",
          value: end_date ? formatDate(end_date) : "-",
        });
      }
    }

    return fields;
  }, [contract_type, start_date, end_date]);

  return (
    <HeaderLayout>
      <StyledDesktopOnlyStepHeading className="mb-3">
        Review
      </StyledDesktopOnlyStepHeading>

      <StyledBox>
        <StyledContainer>
          <StyledStep>
            <StyledStepTopContent>
              <StyledContractTitle>{title}</StyledContractTitle>
              <StyledContractValue className="mt-2">
                ${totalValue}.00
              </StyledContractValue>

              <div className="mt-5">
                <DetailsTable items={clientDetailFields} />

                <StyledSectionHeading className="mt-3">
                  Job Details
                </StyledSectionHeading>

                <DetailsTable className="mt-3" items={transactonDetailFields} />

                <DescriptionField readOnly className="mt-4" />
              </div>

              {showFilesInput ? (
                <TransactionFilesInput className="mt-4" />
              ) : hasAttachments ? (
                <FilesInput
                  disabled
                  className="mt-4"
                  value={values.files}
                  label="Files"
                  displayFileCount={0}
                />
              ) : null}

              {isMilestoneContract && (
                <MilestoneInput readOnly className="mt-4" />
              )}
            </StyledStepTopContent>

            {showEditButton && (
              <StyledEditButtonContainer className="mb-2">
                <Button
                  variant="ghost"
                  colorVariant="black"
                  onClick={() => {
                    gotToStep(Step.ContractDetails);
                  }}
                >
                  <Icon
                    isSrcRelative
                    src="edit.svg"
                    size="xs"
                    colorVariant="default"
                  />
                  Make changes
                </Button>
              </StyledEditButtonContainer>
            )}

            <StyledStepFloatingContent>
              <StepBackButton />
              <StepContinueButton>Create Offer</StepContinueButton>
            </StyledStepFloatingContent>
          </StyledStep>
        </StyledContainer>
      </StyledBox>
    </HeaderLayout>
  );
};

export default ReviewStep;
