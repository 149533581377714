import { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";

import { contractService } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body } from "@/components/Typography";
import Button from "@/components/input/Button";
import { useToast } from "@/components/misc/Toast";

import { ContractId, MilestoneId } from "../ContractsService.types";
import useContractDetails from "./useContractDetails";
import { getContractPayer } from "../utils";

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

function useLaunchMilestone({
  milestoneId,
  contractId,
}: {
  milestoneId: MilestoneId;
  contractId: ContractId;
}) {
  const [isLaunching, setIsLaunching] = useState(false);
  const confirmationModalState = useModalState();
  const { createToast } = useToast();
  const { contractDetails } = useContractDetails({ contractId });

  const clientDisplayName = useMemo(() => {
    const client = contractDetails
      ? getContractPayer(contractDetails)?.user || null
      : null;
    const clientDisplayName = client ? client.given_name : "Client";

    return clientDisplayName;
  }, [contractDetails]);

  // ----------------------------------

  const launchMilestone = useCallback(() => {
    setIsLaunching(true);

    return contractService
      .launchMilestone({ milestoneId, contractId })
      .then(() => {
        createToast({
          title: "Milestone Published",
          description: `${clientDisplayName} has been notified`,
        });
        confirmationModalState.close();
      })
      .catch(() => {
        createToast({
          variant: "error",
          title: "Couldn't publish milestone",
          description: "Something went wrong, milestone is not published.",
        });
      })
      .finally(() => {
        setIsLaunching(false);
      });
  }, [
    milestoneId,
    contractId,
    confirmationModalState,
    createToast,
    clientDisplayName,
  ]);

  // ----------------------------------

  const submitButtonText = isLaunching
    ? "Publishing Milestone..."
    : "Publish Milestone";

  const confirmationJsx = (
    <Modal state={confirmationModalState} width="360px">
      <Body size="lg">
        When you publish the Milestone the other party will be notified and you
        will no longer be able to make changes once published. Are you sure you
        want to publish?
      </Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={confirmationModalState.close}
        >
          Cancel
        </Button>
        <Button disabled={isLaunching} onClick={launchMilestone}>
          {submitButtonText}
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );

  return {
    isLaunching,
    launchMilestone: confirmationModalState.open,
    jsx: confirmationJsx,
  };
}

export default useLaunchMilestone;
