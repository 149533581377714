"use client";

import React from "react";

import Header from "@/features/app/Header";
import Logo from "@/features/app/Logo";
import { Body, Display } from "@/components/Typography";
import GetExpertSupportCard from "@/features/user/GetExpertSupportCard";
import { appFeatures } from "@/config/services";
import Icon from "@/components/misc/Icon";
import { useNavigate } from "@/services/Routing";
import Breadcrumb, { BreadcrumbItem } from "@/components/navigation/Breadcrumb";
import GetPremiumCard from "@/features/user/GetPremiumCard";

import {
  LogoContainer,
  StyledAside,
  StyledButton,
  StyledContainer,
  StyledContentSection,
  StyledMain,
  StyledMainContainer,
  StyledUserDisplay,
} from "./DesktopPrimaryLayout.styles";
import { IDesktopPrimaryLayoutProps } from "./DesktopPrimaryLayout.types";
import UserName from "./components/UserName";
import Nav from "./components/Nav";
import CreateOfferButton from "./components/CreateOfferButton";

const DesktopPrimaryLayout: React.FC<IDesktopPrimaryLayoutProps> = ({
  children,
  headerProps = {},
  showBackButton = true,
  breadcrumbs = [],
  sidebarCompact = false,
  hideHeader = false,
}) => {
  const { back } = useNavigate();

  const backButtonJsx = (
    <StyledButton variant="ghost" onClick={back}>
      <Icon
        src="/assets/images/icons/chevron_left.svg"
        size="xxs"
        colorVariant="black"
      />
    </StyledButton>
  );

  return (
    <StyledMainContainer>
      <StyledContainer $sidebarCompact={sidebarCompact}>
        <StyledAside $sidebarCompact={sidebarCompact}>
          <LogoContainer>
            <Logo variant={sidebarCompact ? "gradient-small" : undefined} />
          </LogoContainer>

          <Nav sidebarCompact={sidebarCompact} />

          {false && !sidebarCompact && <GetPremiumCard className="mb-3" />}

          {false &&
            !sidebarCompact &&
            appFeatures.isSupported("DESKTOP_LAYOUT.CUSTOMER_SERVICE_CARD") && (
              <GetExpertSupportCard className="mb-3" />
            )}

          <StyledUserDisplay
            $sidebarCompact={sidebarCompact}
            variantSmall={sidebarCompact}
          />
        </StyledAside>

        <StyledContentSection $sidebarCompact={sidebarCompact}>
          {!hideHeader && (
            <Header
              {...headerProps}
              logo={{
                component: (
                  <>
                    {headerProps?.logo?.component ? (
                      <Display size="lg">
                        {headerProps?.logo?.component}
                      </Display>
                    ) : (
                      <div>
                        <UserName />
                        <Body
                          size="md"
                          style={{
                            color: "var(--clr-text-500, #666D80)",
                            letterSpacing: "0.25px",
                          }}
                          className="mt-2"
                        >
                          Welcome to Paypipe
                        </Body>
                      </div>
                    )}

                    {!!breadcrumbs.length && (
                      <Breadcrumb className="mt-1" ariaLabel="Curret page path">
                        {breadcrumbs.map((item, i) => (
                          <BreadcrumbItem key={i} {...item} />
                        ))}
                      </Breadcrumb>
                    )}
                  </>
                ),
              }}
              appendContent={{
                children: <CreateOfferButton />,
              }}
              prependContent={{ children: showBackButton && backButtonJsx }}
            />
          )}
          <StyledMain className="fade-in">{children}</StyledMain>
        </StyledContentSection>
      </StyledContainer>
    </StyledMainContainer>
  );
};

export default DesktopPrimaryLayout;
