import React from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import {
  ContractId,
  useAddMilestone,
  useContractDetails,
} from "@/services/ContractsService";
import Modal, { useModalState } from "@/components/misc/Modal";
import MilestoneForm from "@/features/contracts/MilestoneForm";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { appFeatures } from "@/config/services";

const AddMilestoneButton: React.FC<
  IButtonProps & { contractId: ContractId }
> = ({ contractId, children, ...buttonProps }) => {
  const { addMilestone, isAdding } = useAddMilestone({
    contractId,
  });
  const formModalState = useModalState();

  const buttonText = isAdding ? "Adding Milestone..." : "Add Milestone";
  const { contractDetails } = useContractDetails({
    contractId,
    skipFetchIfExists: true,
  });

  if (!appFeatures.isSupported("CONTRACT.ADD_JOB_MILESTONE")) {
    return null;
  }

  const milestoneCount = contractDetails?.milestones?.length;
  const newMilestoneIndex =
    milestoneCount === undefined ? undefined : milestoneCount;

  return (
    <>
      <Modal
        shouldCloseOnInteractOutside={() => !isAdding}
        state={formModalState}
        contentContainerProps={{ style: { padding: 0 } }}
        mobileProps={{
          fullscreen: true,
        }}
      >
        <PageWithHeaderLayout
          headerProps={{
            titleProps: { children: "Add Milestone" },
            backButtonProps: {
              onClick: formModalState.close,
              disabled: isAdding,
            },
          }}
        >
          <div className="p-3">
            <MilestoneForm
              milestoneIndex={newMilestoneIndex}
              onSubmit={(values) => {
                addMilestone(values);
              }}
              submitButtonProps={{
                disabled: isAdding,
                children: buttonText,
              }}
            />
          </div>
        </PageWithHeaderLayout>
      </Modal>
      <Button
        variant="secondary"
        {...buttonProps}
        disabled={isAdding}
        onClick={formModalState.open}
      >
        {children || buttonText}
      </Button>
    </>
  );
};

export default AddMilestoneButton;
