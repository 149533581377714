"use client";

import styled from "@emotion/styled";

import ContractCard, {
  ContractCardLoading,
} from "@/features/contracts/ContractCard";
import NoOffers from "@/features/contracts/NoOffers";
import { screenSmallerThan } from "@/styles";

export const StyledPage = styled.div`
  position: relative;
  padding: 0.25rem 1rem 2rem 1rem;
`;

export const StyledNoOffers = styled(NoOffers)`
  margin-top: 4rem;
`;

export const StyledContractCard = styled(ContractCard)`
  padding-inline: 0;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);

  ${screenSmallerThan.tablet} {
    border-radius: 0 !important;
  }
`;

export const StyledContractCardLoading = styled(ContractCardLoading)`
  padding-inline: 0;
  border-bottom: 1px solid var(--clr-border-ukwn-4, #e9e9e9);

  ${screenSmallerThan.tablet} {
    border-radius: 0 !important;
  }
`;
