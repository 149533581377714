import { useCallback, useRef, useState } from "react";
import styled from "@emotion/styled";
import { OverlayTriggerState } from "react-stately";

import Button from "@/components/input/Button";
import { Body, Heading } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import { screenLargerThan, useResponsive } from "@/styles";
import Modal from "@/components/misc/Modal";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
`;

const StyledFileSection = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8fafb;
`;

const StyledIllustration = styled.img`
  display: block;
  margin-inline: auto;
  max-width: 100%;
  max-height: 14rem;
  padding-inline: 1rem;
`;

const StyledActionContentContainer = styled.div`
  padding-inline: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid rgba(236, 239, 243, 1);
  padding-top: 1.5rem;

  ${screenLargerThan.tablet} {
    padding-top: 2rem;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const StyledPennyLogo = styled.img`
  display: block;
  margin-inline: 0;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledModalActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  & > * {
    border-radius: 10rem !important;
  }

  & > *:first-child {
    flex-grow: 1;
  }

  & > *:last-child {
    flex-grow: 3;
  }
`;

const DocumentInputScreen: React.FC<{
  file: File | null;
  setFile: (file: File | null) => void;
  handleGenerate: () => void;
  onCreateManually: () => void;
  invalidDocumentModalState: OverlayTriggerState;
  showRegenerateButton?: boolean;
}> = ({
  file,
  setFile,
  handleGenerate,
  showRegenerateButton,
  onCreateManually,
  invalidDocumentModalState,
}) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const inputRef = useRef<HTMLInputElement>(null);
  const [disableGenerateButton, setDisableGenerateButton] =
    useState(showRegenerateButton);
  const [showRegenerateText, setShowRegenerateText] = useState(false);

  const showChooseButton = !file;
  const showReselectButton = !!file;
  const showGenerateButton = !!file;

  const openFileSelectDialog = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  return (
    <>
      <Modal
        state={invalidDocumentModalState}
        contentContainerProps={{ style: { textAlign: "center" } }}
        width="410px"
      >
        <StyledLogoContainer>
          <StyledPennyLogo
            alt="Logo"
            src="/assets/images/branding/penny/logo_round.svg"
          />
        </StyledLogoContainer>
        <Heading size="lg" className="mt-4">
          Uh oh, I couldn't read that
        </Heading>
        <Body size="lg" className="mt-2" style={{ color: "#818898" }}>
          Sometimes this happens if the PDF is image-based, or
          scanned/photocopied.
        </Body>
        <StyledModalActionsContainer className="mt-3">
          <Button
            variant="secondary"
            colorVariant="gray"
            onClick={() => {
              invalidDocumentModalState.close();
              openFileSelectDialog();
            }}
          >
            Upload another doc
          </Button>
          <Button
            onClick={() => {
              invalidDocumentModalState.close();
              onCreateManually();
            }}
          >
            Create manually
          </Button>
        </StyledModalActionsContainer>
      </Modal>

      <StyledContainer>
        <StyledFileSection>
          <StyledInput
            accept="application/pdf"
            type="file"
            ref={inputRef}
            onChange={(e: any) => {
              const file: File = e.target?.files[0];
              if (file && file.name.toLocaleLowerCase().endsWith("pdf")) {
                setFile(file);
                setDisableGenerateButton(false);

                if (showRegenerateButton) {
                  setShowRegenerateText(true);
                }
              }
            }}
          />

          <div>
            <StyledIllustration
              alt="Illustration"
              src="/assets/images/branding/penny/pdf.png"
            />

            {!!file ? (
              <Heading size="lg" className="mt-3">
                {file.name}
              </Heading>
            ) : (
              <>
                <Heading size="lg" className="mt-3">
                  {!isScreenSmallerThanTablet && <>Drag & Drop or&nbsp;</>}
                  Upload a PDF file
                </Heading>
                <Body
                  size="lg"
                  className="mt-3"
                  style={{
                    maxWidth: "56ch",
                    marginInline: "auto",
                  }}
                >
                  More formats will be supported soon
                </Body>
              </>
            )}
          </div>
        </StyledFileSection>

        <StyledActionContentContainer>
          {showReselectButton && (
            <Button
              variant="ghost"
              colorVariant="black"
              className="w-100"
              onClick={openFileSelectDialog}
            >
              Delete and upload another one
            </Button>
          )}

          {showChooseButton && (
            <Button
              className="w-100"
              onClick={openFileSelectDialog}
              style={{
                display: "flex",
                maxWidth: "28rem",
                marginInline: "auto",
              }}
            >
              Choose file
            </Button>
          )}

          {showGenerateButton && (
            <Button
              colorVariant={
                !file || disableGenerateButton ? "disabled" : "gradient"
              }
              className="py-3 w-100"
              onClick={handleGenerate}
              disabled={!file || disableGenerateButton}
              style={{
                display: "flex",
                maxWidth: "28rem",
                marginInline: "auto",
              }}
            >
              {showRegenerateText ? "Regenerate Offer" : "Generate Offer"}{" "}
              <Icon
                size="sm"
                alt="Illustration"
                src="/assets/images/branding/penny/star.svg"
                colorVariant={!file || disableGenerateButton ? "gray" : "white"}
              />
            </Button>
          )}
        </StyledActionContentContainer>
      </StyledContainer>
    </>
  );
};

export default DocumentInputScreen;
