"use client";

import { useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { INITIAL_VALUES } from "@/features/contracts/ContractForm";
import {
  AiGeneratedContractDetails,
  aiGeneratedContractToContractDetails,
  ContractCreationFlow,
  ContractCreatorType,
  ContractType,
  useCanCreateOffer,
  useContractList,
} from "@/services/ContractsService";
import { useSearchParams } from "@/services/Routing";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { useOnboardStripe } from "@/services/UserService";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import PayoutOnboardingUnderReviewPage from "@/features/pages/user/PayoutOnboardingUnderReviewPage";
import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";

import {
  StyledContractForm,
  StyledDesktopFormContainer,
  StyledInfoContainer,
} from "./CreateContractPage.styles";

const CreateContractPage: React.FC<{ formJsx: React.ReactNode }> = ({
  formJsx,
}) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { isReady } = useOnboardStripe();
  const {
    onboardingUnderReview,
    onboardingUnderReviewInitial,
    canCreate,
    waitTimeJsx,
  } = useCanCreateOffer();
  const { isLoading: isLoadingContractList, hasNoData } = useContractList();
  const [canShowCreationWaitPrompt, setCanShowCreationWaitPrompt] = useState<
    null | boolean
  >(null);

  //-------------------

  useEffect(() => {
    if (canShowCreationWaitPrompt !== null) {
      return;
    }

    if (isLoadingContractList) {
      return;
    }

    setCanShowCreationWaitPrompt(!hasNoData);
  }, [hasNoData, canShowCreationWaitPrompt, isLoadingContractList, canCreate]);

  //-------------------

  if (!isReady || isLoadingContractList) {
    return <SplashScreen />;
  }

  if (onboardingUnderReview) {
    return (
      <PayoutOnboardingUnderReviewPage
        showFirstTimeContent={onboardingUnderReviewInitial}
      />
    );
  }

  if (!canCreate && !!waitTimeJsx && canShowCreationWaitPrompt) {
    if (isScreenSmallerThanTablet) {
      return (
        <MobilePrimaryLayout showBackButton={false}>
          <div className="px-3">
            <StyledInfoContainer>{waitTimeJsx}</StyledInfoContainer>
          </div>
        </MobilePrimaryLayout>
      );
    }

    return (
      <DesktopPrimaryLayout showBackButton={false}>
        <div className="px-3">
          <StyledInfoContainer>{waitTimeJsx}</StyledInfoContainer>
        </div>
      </DesktopPrimaryLayout>
    );
  }

  if (isScreenSmallerThanTablet) {
    return formJsx;
  }

  return (
    <DesktopPrimaryLayout hideHeader showBackButton={false}>
      <StyledDesktopFormContainer>{formJsx}</StyledDesktopFormContainer>
    </DesktopPrimaryLayout>
  );
};

const CreateContractPageWrapper: React.FC = () => {
  const { searchParams } = useSearchParams();
  const flowFormUrl = searchParams.get("flow");
  const flow = flowFormUrl ? (flowFormUrl as ContractCreationFlow) : undefined;
  const contractTypeFormUrl = searchParams.get("type");
  const contractCreatorTypeFormUrl = searchParams.get("creator");
  const { state } = useLocation();
  const aiGeneratedcontractDetails: AiGeneratedContractDetails | null =
    state?.aiGeneratedcontractDetails || null;

  const formInitialValues = useMemo(() => {
    let initialFormValue = {
      ...INITIAL_VALUES,
    };

    if (contractCreatorTypeFormUrl) {
      const CreatorType = contractCreatorTypeFormUrl as ContractCreatorType;
      if (
        [ContractCreatorType.Payer, ContractCreatorType.Payee].includes(
          CreatorType
        )
      ) {
        initialFormValue.created_as = CreatorType;
      }
    }

    if (flow || contractTypeFormUrl) {
      const contractType = (contractTypeFormUrl ||
        flow) as unknown as ContractType;
      if (
        [ContractType.OneTime, ContractType.Milestone].includes(contractType)
      ) {
        initialFormValue.contract_type = contractType;
      }
    }

    if (aiGeneratedcontractDetails) {
      const { contractDetails } = aiGeneratedContractToContractDetails({
        details: aiGeneratedcontractDetails,
      });
      initialFormValue = { ...initialFormValue, ...contractDetails };
    }

    if (flow === ContractCreationFlow.AI) {
      initialFormValue = {
        ...initialFormValue,
        created_as: ContractCreatorType.Payee,
      };
    }

    return initialFormValue;
  }, [
    flow,
    contractTypeFormUrl,
    contractCreatorTypeFormUrl,
    aiGeneratedcontractDetails,
  ]);

  // const [formValues, setFormValues] = useState(formInitialValues);

  const formJsx = useRef(
    <StyledContractForm initialValues={formInitialValues} />
  ).current;

  return <CreateContractPage formJsx={formJsx} />;
};

export default CreateContractPageWrapper;
