import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Body, Display, Heading } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import Button from "@/components/input/Button";
import {
  ContractCreationFlow,
  ContractCreatorType,
  getContractCreateUrl,
} from "@/services/ContractsService";
import { appFeatures } from "@/config/services";
import { useAuth } from "@/services/Authentication";
import { screenLargerThan, screenSmallerThan, useResponsive } from "@/styles";

const StyledContainer = styled.div`
  position: relative;
  padding: 2rem 1.25rem;
  background: linear-gradient(
    240.96deg,
    #0ba0bb -7.5%,
    #323aab 41.02%,
    #3c2d91 92.72%
  );
  color: #fff;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;

  ${screenSmallerThan.tablet} {
    background-image: url("assets/images/branding/penny/banner_bg_mobile.jpeg");
    background-position: top;
    border-radius: 1.5rem;
  }

  ${screenLargerThan.tablet} {
    padding: 3rem;
    background-image: url("assets/images/branding/penny/banner_bg_desktop.jpeg");
    background-position: center;
    border-radius: 2.375rem;
  }
`;

const StyledHeading = styled(Display)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const StyledButton = styled(Button)`
  border-radius: 10rem;
  padding-block: 0.75rem;
  max-width: 28rem;
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const StyledPill = styled.span`
  display: inline-block;
  padding: 0.125rem 1rem;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;

  ${screenLargerThan.tablet} {
    border-width: 2px;
    font-weight: 600;
  }
`;

const URL = getContractCreateUrl({
  flow: ContractCreationFlow.AI,
  creatorType: ContractCreatorType.Payee,
});

const AiOfferCardBanner: React.FC<{ className?: string }> = ({ className }) => {
  const { userData } = useAuth();
  const { isScreenSmallerThanTablet } = useResponsive();

  const username = userData?.given_name;

  if (!appFeatures.isSupported("CONTRACT.CREATE_WITH_AI")) {
    return null;
  }

  const heading = `${username || "Hi"}, meet Penny AI!`;
  const pillJsx = <StyledPill>beta</StyledPill>;

  return (
    <StyledContainer className={className}>
      <StyledLink to={URL} />

      {isScreenSmallerThanTablet ? (
        <Heading
          size="lg"
          style={{
            fontSize: "1.5rem",
            lineHeight: "2.5rem",
          }}
        >
          {heading} <div className="mt-3">{pillJsx}</div>
        </Heading>
      ) : (
        <StyledHeading size="xl">
          {heading} {pillJsx}
        </StyledHeading>
      )}

      {isScreenSmallerThanTablet ? (
        <Body
          size="lg"
          className="mt-3"
          style={{ maxWidth: "50ch", marginInline: "auto" }}
        >
          Transform chats or agreements into shareable transactions. Describe
          your deliverables, and let Penny craft the perfect offer for you.
        </Body>
      ) : (
        <Heading
          size="lg"
          className="mt-3"
          style={{ maxWidth: "50ch", marginInline: "auto" }}
        >
          Transform chats or agreements into shareable transactions. Describe
          your deliverables, and let Penny craft the perfect offer for you.
        </Heading>
      )}

      <StyledButton colorVariant="gradient" className="mt-5 w-100" link={URL}>
        <Icon
          size="sm"
          alt="Illustration"
          src="/assets/images/branding/penny/star.svg"
          colorVariant="white"
        />
        Try it now for free
      </StyledButton>
    </StyledContainer>
  );
};

export default AiOfferCardBanner;
