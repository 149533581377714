import { useCallback, useMemo, useState } from "react";
import lodashGet from "lodash/get";

import { analytics, userService } from "@/config/services";
import {
  selectIsStripeOnboarded,
  selectLoadingIsStripeOnboarded,
  useAppSelector,
} from "@/services/Store";
import { amIContractPayee, useContractList } from "@/services/ContractsService";

import { OnboardStripeApiParams } from "../UserService.types";
import useUpdateProfile from "./useUpdateProfile";
import usePayoutOnboardStatus from "./usePayoutOnboardStatus";
import useManageOnboardingCountry from "./useManageOnboardingCountry";

function useOnboardStripe() {
  const { profileData, update, isProfileDataLoading } = useUpdateProfile();
  const { loadedStateOnce } = usePayoutOnboardStatus();
  const { data: contractList, isLoading: isContractListLoading } =
    useContractList();

  const isOnboarded = useAppSelector(selectIsStripeOnboarded);
  const isLoadingOnboardedState = useAppSelector(
    selectLoadingIsStripeOnboarded
  );
  const {
    onboardingCountry,
    isStrictOnboardCountry,
    isLoading: isLoadingOnboardCountry,
  } = useManageOnboardingCountry();

  const [updatingCanOnboardState, setUpdatingCanOnboardState] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);

  const isReady =
    !!profileData &&
    loadedStateOnce &&
    !isContractListLoading &&
    !isLoadingOnboardCountry;

  const canOnboard = useMemo(() => {
    if (onboardingCountry && isStrictOnboardCountry) {
      return true;
    }

    const hasContractsAsPayer = !!contractList.filter((contract) =>
      amIContractPayee(contract)
    ).length;

    return hasContractsAsPayer && !isOnboarded;
  }, [contractList, isOnboarded, onboardingCountry, isStrictOnboardCountry]);

  //------------------------

  const onboard = useCallback(
    (params: OnboardStripeApiParams) => {
      if (isOnboarding) {
        return;
      }

      analytics.triggerStripeOnboardingEvent({
        countryCode: params.countryCode,
      });

      setIsOnboarding(true);

      return userService
        .onboardStripe(params)
        .then((res) => {
          const stripeOnboardUrl = lodashGet(res, "data.data.url", "");
          if (stripeOnboardUrl) {
            window.location.href = stripeOnboardUrl;
            setTimeout(() => {
              setIsOnboarding(false);
            }, 2000);
          } else {
            setIsOnboarding(false);
          }
        })
        .catch(() => {
          setIsOnboarding(false);
        });
    },
    [isOnboarding]
  );

  const updateCanOnboardState = useCallback(
    (state: boolean) => {
      if (updatingCanOnboardState || isProfileDataLoading) {
        return;
      }

      const currentState = lodashGet(profileData, "misc.canOnboard");

      if (currentState === state) {
        return Promise.resolve();
      }

      const updatedProfileData = JSON.parse(JSON.stringify(profileData));
      updatedProfileData.misc.canOnboard = state;

      setUpdatingCanOnboardState(true);
      return update(updatedProfileData, { notify: false }).finally(() => {
        setUpdatingCanOnboardState(false);
      });
    },
    [profileData, update, updatingCanOnboardState, isProfileDataLoading]
  );

  return {
    isOnboarded,
    isLoadingOnboardedState,
    isReady,
    onboard,
    isOnboarding,
    updateCanOnboardState,
    canOnboard,
  };
}

export default useOnboardStripe;
