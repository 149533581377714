"use client";

import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import Modal, { useModalState } from "@/components/misc/Modal";
import Icon from "@/components/misc/Icon";
import Wysiwyg from "@/components/input/LexicalWysiwyg";
import TextField from "@/components/input/TextField";
import Button from "@/components/input/Button";

import {
  StyledContainer,
  StyledInnerContainer,
  StyledLabel,
  StyledMiddleSection,
  StyledPageWithHeaderLayout,
  StyledPlaceholder,
  StyledPlaceholderWysiwyg,
  StyledValueContainer,
} from "./EditorField.styles";
import { EditorFieldRef, IEditorField } from "./EditorField.types";

const EditorField = forwardRef<EditorFieldRef, IEditorField>(
  (props, forwardedRef) => {
    const {
      className,
      prependContent = <Icon isSrcRelative src="content.svg" size="xs" />,
      appendContent,
      label = "Add description",
      value: valueFromProps,
      placeholder,
      drawerTitle,
      readOnly: readOnlyFromProps,
      disabled,
      onClick,
      hasError = false,
      onChange,
      customPlaceholder,
      placeholderVariant = "default",
      layoutProps = {},
      modalProps = {},
      onEditorOpenChange,
      onEditorValueChange,
      urlHash,
      showSaveButton = false,
    } = props;

    const [value, setValue] = useState(valueFromProps);

    const modalState = useModalState({
      urlHash,
      onOpenChange: (isOpen) => {
        if (isOpen) {
          setValue(valueFromProps);
        } else {
          if (onChange) onChange(value || { markdown: "" });
        }

        if (onEditorOpenChange) {
          onEditorOpenChange(isOpen);
        }
      },
    });

    const hasValue = !!valueFromProps?.markdown;

    //---------------------------

    const renderDisplayValue = useCallback(() => {
      let displayValueJsx: any = (
        <StyledValueContainer>
          {!!label && <StyledLabel>{label}</StyledLabel>}
          {!hasValue && (
            <StyledPlaceholder className="mt-1">
              {placeholder}
            </StyledPlaceholder>
          )}
        </StyledValueContainer>
      );

      if (!hasValue && customPlaceholder) {
        displayValueJsx = customPlaceholder;
      }

      if (!hasValue && placeholderVariant === "bg-input") {
        displayValueJsx = (
          <TextField
            id="about_me"
            useFloatingLabel={false}
            className="mt-3 placeholder"
            variant="background"
            placeholder={placeholder}
            style={{ pointerEvents: "none" }}
          />
        );
      }

      return displayValueJsx;
    }, [customPlaceholder, hasValue, label, placeholder, placeholderVariant]);

    //---------------------------

    useImperativeHandle(
      forwardedRef,
      () => {
        return {
          openEditor: modalState.open,
          closeEditor: modalState.close,
        };
      },
      [modalState.open, modalState.close]
    );

    //---------------------------

    return (
      <>
        <StyledContainer
          $hasError={hasError}
          className={className}
          onClick={onClick ? onClick : modalState.open}
        >
          <StyledInnerContainer>
            {prependContent}
            <StyledMiddleSection className="middle">
              {renderDisplayValue()}
            </StyledMiddleSection>
            {appendContent}
          </StyledInnerContainer>

          {!!hasValue && (
            <StyledPlaceholderWysiwyg
              {...props}
              value={valueFromProps}
              className="mt-3 placeholder"
              readOnly
              focusOnMount={false}
              $readOnly={readOnlyFromProps}
            />
          )}
        </StyledContainer>

        {!disabled && (
          <Modal
            fullscreen
            mobileProps={{ fullscreen: true }}
            desktopProps={{ width: "768px" }}
            className="p-0"
            state={modalState}
            {...modalProps}
          >
            <StyledPageWithHeaderLayout
              {...layoutProps}
              headerProps={{
                backButtonProps: { onClick: modalState.close },
                titleProps: {
                  children: drawerTitle,
                },
                appendContent:
                  showSaveButton && !readOnlyFromProps ? (
                    <Button variant="ghost" onClick={modalState.close}>
                      Save
                    </Button>
                  ) : undefined,
                ...(layoutProps.headerProps || {}),
              }}
            >
              {/* TO-CHECK : classname reset to prevent margin in milestone form */}
              <Wysiwyg
                focusOnMount
                {...props}
                onChange={(value) => {
                  setValue(value);
                  if (onEditorValueChange) {
                    onEditorValueChange(value);
                  }
                }}
                className=""
              />
            </StyledPageWithHeaderLayout>
          </Modal>
        )}
      </>
    );
  }
);

export default EditorField;
