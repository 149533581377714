import React, { useCallback, useMemo } from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import { joinClassNames } from "@/utils/classNames";
import {
  useManageOnboardingCountry,
  useOnboardingAction,
  usePayoutOnboardStatus,
} from "@/services/UserService";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body, Heading } from "@/components/Typography";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { appFeatures } from "@/config/services";

import { StyledButton } from "../../ContractForm.styles";
import { useContractForm } from "../../ContractForm.context";
import { STEPS_CONFIG } from "../../ContractForm.config";

const StepContinueButton: React.FC<IButtonProps> = (props) => {
  const { onboardingCountry, promptIdentityVerification } =
    usePayoutOnboardStatus();
  const onboardAction = useOnboardingAction();
  const onboardModalState = useModalState();
  const { isStrictOnboardCountry } = useManageOnboardingCountry();

  const {
    currentStep,
    checkIsStepCompleted,
    nextStep,
    isLastStep,
    submitForm,
    creatingContract,
    setFieldTouched,
    values,
  } = useContractForm();

  const isCurrentStepCompleted = useMemo(
    () => checkIsStepCompleted(currentStep),
    [currentStep, checkIsStepCompleted]
  );

  const handleClick = useCallback(() => {
    const validateStep = STEPS_CONFIG[currentStep]?.validateStep;

    if (validateStep) {
      validateStep({ setFieldTouched, values });
    }

    if (!isCurrentStepCompleted) {
      return;
    }

    if (isLastStep) {
      submitForm();
    } else {
      nextStep();
    }
  }, [
    nextStep,
    isLastStep,
    submitForm,
    isCurrentStepCompleted,
    setFieldTouched,
    values,
    currentStep,
  ]);

  if (
    promptIdentityVerification &&
    onboardingCountry &&
    onboardAction?.priority === "urgent" &&
    (isStrictOnboardCountry
      ? true
      : !appFeatures.isSupported("CONTRACT.ALLOW_BEFORE_PAYEE_ONBOARDING"))
  ) {
    return (
      <>
        <Modal
          showCloseButton
          state={onboardModalState}
          contentContainerProps={{ style: { paddingBottom: "1rem" } }}
          closeButtonProps={{
            variant: "ghost",
            style: { background: "transparent" },
          }}
          width="28rem"
        >
          {!!onboardAction?.isOnboarding && <SplashScreen />}
          <Heading size="lg" className="mt-3">
            Complete New Verification Requirements
          </Heading>
          <Body size="md" className="mt-3">
            To ensure your account remains active and you can continue to
            receive payouts, we need you to complete some additional
            verification steps. This is a requirement to comply with regulatory
            standards and enhance the security of our platform.
          </Body>
          <Button
            {...onboardAction.ctaProps}
            style={{
              marginTop: "1.25rem",
              width: "100%",
              paddingBlock: "0.75rem",
            }}
          >
            Complete verification
          </Button>
        </Modal>
        <StyledButton
          {...props}
          className={joinClassNames("w-100", props.className)}
          onClick={onboardModalState.open}
        >
          {props.children || "Continue"}
        </StyledButton>
      </>
    );
  }

  return (
    <StyledButton
      colorVariant={isCurrentStepCompleted ? "primary" : "disabled"}
      {...props}
      className={joinClassNames("w-100", props.className)}
      onClick={handleClick}
      disabled={creatingContract}
    >
      {creatingContract ? "Creating" : props.children || "Continue"}
    </StyledButton>
  );
};

export default StepContinueButton;
