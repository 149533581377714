import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import PopoverButton from "@/components/misc/PopoverButton";
import { isMobileClient, isPwaClient, usePwa } from "@/services/Pwa";
import DetailsTable from "@/components/data/DetailsTable";
import { SITE_PATHS } from "@/config/routing";
import { IS_STAGING_ENV } from "@/config/app";

import packageJson from "@/../package.json";
import { screenLargerThan, screenSmallerThan } from "@/styles";

const StyledPopoverButton = styled(PopoverButton)`
  position: fixed;
  bottom: 0;
  font-size: 12px;
  padding: 0.5rem;
  z-index: 99999;
  letter-spacing: 2px;
  font-weight: 600;
  max-width: fit-content;
  border-radius: 0 !important;
  max-width: unset;

  ${screenSmallerThan.tablet} {
    width: 100%;
    left: 0;
    bottom: 3.75rem;
  }

  ${screenLargerThan.tablet} {
    right: 0;
  }
`;

const StyledDetailsTable = styled(DetailsTable)`
  width: 210px;
  max-width: calc(100vw - 6rem);
`;

const AppVersion: React.FC = () => {
  const { isPwaInstalled } = usePwa();

  if (IS_STAGING_ENV) {
    return (
      <StyledPopoverButton
        buttonProps={{
          colorVariant: "gradient-primary",
          children: (
            <div id="version">
              V {packageJson.version}{" "}
              <small style={{ display: "block" }}>click for more info</small>
            </div>
          ),
        }}
        popoverProps={{
          children: (
            <div className="p-3">
              <StyledDetailsTable
                items={[
                  {
                    label: "Dev test page",
                    value: <Link to={SITE_PATHS.DEV_PAGE}>Open</Link>,
                  },
                  {
                    label: "Is PWA installed?",
                    value: isPwaInstalled ? "Yes" : "No",
                  },
                  {
                    label: "Is this PWA?",
                    value: isPwaClient() ? "Yes" : "No",
                  },
                  {
                    label: "Is mobile?",
                    value: isMobileClient() ? "Yes" : "No",
                  },
                ]}
              />
            </div>
          ),
        }}
      />
    );
  }

  return null;
};

export default AppVersion;
