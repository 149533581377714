import posthog, { PostHog } from "posthog-js";

import { ApiResponse, IApiService } from "@/services/Api";

import { POSTHOG_API_HOST, POSTHOG_KEY } from "./Feedback.config";
import { FeedbackFeature } from "./Feedback.types";

class Feedback {
  _apiService: IApiService;
  _posthogClient: PostHog | null = null;

  constructor(_apiService: IApiService) {
    this._apiService = _apiService;
    this.init();
  }

  //----------------------------

  init = () => {
    this.initPostHog();
  };

  initPostHog = () => {
    if (!POSTHOG_API_HOST || !POSTHOG_KEY) {
      return;
    }

    this._posthogClient =
      posthog.init(POSTHOG_KEY, {
        api_host: POSTHOG_API_HOST,
        person_profiles: "always",
      }) || null;
  };

  //----------------------------

  getFeedbackDetails = ({ feature }: { feature: FeedbackFeature }) => {
    return this._apiService.get<
      ApiResponse<null | {
        feature: FeedbackFeature;
        details: any;
      }>
    >(`v1/users/feedbacks?feature=${feature}`);
  };
}

export default Feedback;
