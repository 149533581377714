import { screenLargerThan } from "@/styles";
import lodashGet from "lodash/get";

export const squareSizing = (size: string = "48px") =>
  ` 
    --size: ${size};
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  `;

const PX_REGEX = /^(-?\d+(\.\d{1,2})?)px$/;
export const pxToRem = (s: string) => {
  const match = s.match(PX_REGEX);
  const number = lodashGet(match, "[1]", "");

  if (!number) {
    return "0rem";
  }

  return `${+number / 16}rem`;
};

export const getViewportBottom = () =>
  ` 
    env(safe-area-inset-bottom)
  `;

export const addHoverHighlight = ({
  highlightColor = "rgba(0, 0, 0, 0.05)",
}: {
  highlightColor?: string;
} = {}) =>
` 
  // ${screenLargerThan.largeMobile} {
  //   position: relative;
  //   cursor: pointer;

  //   &:hover:after {
  //     pointer-events: none;
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     border-radius: inherit;
  //     z-index: 2;
  //     background: ${highlightColor};
  //   }
  // }
`;
