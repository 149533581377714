import lodashGet from "lodash/get";

import { ApiResponse, IApiService } from "@/services/Api";
import { store, contractActions } from "@/services/Store";
import { appFeatures } from "@/config/services";
import { getUrl } from "@/utils/string";

import {
  AddContractMilestoneAttributes,
  AiGeneratedContractResponse,
  AiGeneratedContractResult,
  ContractCompleteDetails,
  ContractCreatePayload,
  ContractId,
  ContractListFilters,
  CreatedContractDetails,
  MilestoneCompleteDetails,
  MilestoneId,
  MilestoneWork,
  PaymentResponse,
  PaymentStatus,
  ShareOfferViaEmailParams,
  UpdateContractAttributes,
  UpdateContractMilestoneAttributes,
} from "./ContractsService.types";
import {
  contractModelToCompleteDetails,
  getContractPageUrl,
  getOfferPageUrl,
  isContractAnOffer,
  sanitizeContractCompleteDetails,
} from "./utils";
import { USE_DUMMY_AI_GENERATION_REPONSE } from "./ContractsService.config";

class ContractsService {
  _apiService: IApiService;

  constructor(_apiService: IApiService) {
    this._apiService = _apiService;
  }

  //------------------

  fetchContractOverview = () => {
    return this._apiService.get<
      ApiResponse<{
        data: null;
      }>
    >(`/contract`);
  };

  createContract = async (data: ContractCreatePayload) => {
    const createApiRes = await this._apiService.post<
      ApiResponse<CreatedContractDetails>
    >("/contract", data);

    const contractId = createApiRes.data.data.id;

    const contractApiRes = await this._apiService.get<
      ApiResponse<ContractCompleteDetails>
    >(`/contract/detail/${contractId}`);
    const contractDetails = sanitizeContractCompleteDetails(
      contractApiRes.data.data
    );

    store.dispatch(
      contractActions.addContracts({
        data: [contractDetails],
      })
    );

    return createApiRes;
  };

  getContractByID = async (id: string, useAuthToken = true) => {
    const fetchContract = (id: string, anon = false) => {
      const f = anon ? this._apiService.anonGet : this._apiService.get;
      return f<ApiResponse<ContractCompleteDetails>>(
        `/contract/detail/${id}`
      ).then((res) => {
        const contractDetails = res.data.data;
        if (!contractDetails) {
          store.dispatch(
            contractActions.removeContracts({
              idList: [id],
            })
          );
          return res;
        }

        const contractDetailsSanitized = sanitizeContractCompleteDetails(
          res.data.data
        );
        if (contractDetailsSanitized) {
          store.dispatch(
            contractActions.addContracts({
              data: [contractDetailsSanitized],
              replaceData: true,
            })
          );
        } else {
          store.dispatch(
            contractActions.removeContracts({
              idList: [id],
            })
          );
        }

        return res;
      });
    };

    const authToken = this._apiService.getAuthToken();
    if (useAuthToken && authToken) {
      return fetchContract(id);
    }

    if (
      appFeatures.isSupported("CONTRACT.AUTHENTICATE_BEFORE_OFFER_ACCEPTANCE")
    ) {
      return fetchContract(id, true);
    }

    const otpApiRes = await this._apiService.anonGet<
      ApiResponse<{ vCode: number }>
    >(`/contract/detail/${id}`);

    const verifyApiRes = await this._apiService.anonPost<
      ApiResponse<{ token: string }>
    >(`/contract/detail/verify/${id}`, {
      v_code: otpApiRes.data.data.vCode,
    });

    const token = verifyApiRes.data.data.token;

    this._apiService.setAuthToken(token);

    return fetchContract(id);
  };

  //------------------------

  getContractListEndpoint = (params: ContractListFilters = {}) => {
    params = { ...params };

    if (!params.limit) {
      params.limit = 1000;
    }

    if (!params.offset) {
      params.offset = 0;
    }

    (params as any).limit = `${params.limit}`;
    (params as any).offset = `${params.offset}`;

    Object.keys(params).forEach((key) => {
      if (!params[key as keyof ContractListFilters]) {
        delete params[key as keyof ContractListFilters];
      }
    });

    const serializedParams = new URLSearchParams(params as any).toString();

    return `/contract?${serializedParams}`;
  };

  fetchContracts = (params: ContractListFilters = {}) => {
    const endpoint = this.getContractListEndpoint(params);

    return this._apiService
      .get<
        ApiResponse<
          | CreatedContractDetails[]
          | {
              count: number;
              rows: CreatedContractDetails[];
            }
        >
      >(endpoint)
      .then((res) => {
        const contractListFromApi = (lodashGet(res.data, "data.rows") ||
          lodashGet(res.data, "data") ||
          []) as CreatedContractDetails[];
        const contractList = contractListFromApi.map(
          contractModelToCompleteDetails
        );

        store.dispatch(
          contractActions.addContracts({
            data: contractList,
          })
        );

        return res;
      });
  };

  rejectContract = (
    id: String,
    data: { reason: string; description: string }
  ) => {
    return this._apiService
      .post<
        ApiResponse<{
          data: null;
        }>
      >(`/contract/reject/${id}`, {
        reject_header: data.reason,
        reject_reason: data.description,
      })
      .then((res) => {
        return res.data.code === 200;
      });
  };

  acceptContractOLD = async (data: ContractCompleteDetails) => {
    const { id: contractId, milestones } = data;

    const promise = await Promise.all(
      milestones.map(({ id: milestoneId }) =>
        this._apiService.post(
          `/contract/approve/${contractId}/milestone/${milestoneId}`
        )
      )
    );

    const rejected =
      !promise.length ||
      promise.some((p) => lodashGet(p, "response.status") !== 200);

    if (rejected) {
      return Promise.reject({});
    }

    return promise;
  };

  acceptContract = (
    data: ContractCompleteDetails,
    { email = "" }: { email?: string } = {}
  ) => {
    const { id: contractId, milestones } = data;
    const milestoneId = milestones[0].id;

    const isOffer = isContractAnOffer(data);
    const urlFunction = isOffer ? getOfferPageUrl : getContractPageUrl;

    const success_url =
      window.location.origin + urlFunction(contractId) + "&success=true&";
    const cancel_url =
      window.location.origin + urlFunction(contractId) + "&success=false&";

    const payload: any = {
      success_url,
      cancel_url,
    };

    if (appFeatures.isSupported("CONTRACT.PREFILL_PAYMENT_PLATFORM_EMAIL")) {
      payload.email = email;
    }

    return this._apiService
      .post<ApiResponse<PaymentResponse>>(
        `/contract/approve/${contractId}/milestone/${milestoneId}`,
        payload
      )
      .then((res) => {
        const stripePaymentUrl = res.data.data.url;
        if (stripePaymentUrl) {
          window.location.href = stripePaymentUrl;
        } else {
          throw new Error();
        }
      });
  };

  //---------------------------

  fundContractMilestone = (
    contractId: ContractId,
    milestoneId: MilestoneId,
    { email = "" }: { email?: string } = {}
  ) => {
    const success_url =
      window.location.origin +
      getContractPageUrl(contractId) +
      "&success=true&";
    const cancel_url =
      window.location.origin +
      getContractPageUrl(contractId) +
      "&success=false&";

    const payload: any = {
      success_url,
      cancel_url,
    };

    if (appFeatures.isSupported("CONTRACT.PREFILL_PAYMENT_PLATFORM_EMAIL")) {
      payload.email = email;
    }

    return this._apiService
      .post<ApiResponse<PaymentResponse>>(
        `/contract/approve/${contractId}/milestone/${milestoneId}`,
        payload
      )
      .then((res) => {
        const stripePaymentUrl = res.data.data.url;
        window.location.href = stripePaymentUrl;
      });
  };

  //---------------------------

  pauseContract = (contractId: string, milestoneId: MilestoneId) => {
    return this._apiService.post<
      ApiResponse<{
        data: null;
      }>
    >(`/contract/pause/${contractId}/milestone/${milestoneId}`);
  };

  resumeContract = (contractId: string, milestoneId: MilestoneId) => {
    return this._apiService.post<
      ApiResponse<{
        data: null;
      }>
    >(`/contract/unpause/${contractId}/milestone/${milestoneId}`);
  };

  //---------------------------

  submitContractMilestone = (data: {
    contractId: string;
    milestoneId: number;
    description: string;
    attachments: number[];
  }) => {
    const { contractId, milestoneId, attachments, description } = data;

    return this._apiService
      .post<ApiResponse<PaymentResponse>>(
        `/contract/${contractId}/milestone/${milestoneId}/submit`,
        { attachments, description }
      )
      .catch((error) => {
        return error;
      });
  };

  approveContractMilestone = (data: {
    contractId: string;
    milestoneId: number;
  }) => {
    const { contractId, milestoneId } = data;

    return this._apiService.post<ApiResponse<PaymentResponse>>(
      `/contract/${contractId}/milestone/${milestoneId}/complete`
    );
  };

  getContractMilestone = (data: { milestoneId: number }) => {
    const { milestoneId } = data;

    return this._apiService.get<
      ApiResponse<MilestoneCompleteDetails & { work: MilestoneWork[] }>
    >(`/contract/milestone/${milestoneId}`);
  };

  startContractMilestone = (data: {
    contractId: string;
    milestoneId: number;
  }) => {
    const { contractId, milestoneId } = data;

    return this._apiService.post<ApiResponse<PaymentResponse>>(
      `/contract/start/${contractId}/milestone/${milestoneId}`
    );
  };

  //---------------------------

  getFileSignedUrl = (fileKey: string) => {
    return this._apiService.anonPost<ApiResponse<{ url: string }>>(
      `/media/getPresignedUrl`,
      { key: fileKey }
    );
  };

  //---------------------------

  addOfferView = (contractId: string, milestoneId: MilestoneId) => {
    return this._apiService.anonPost<
      ApiResponse<{
        data: null;
      }>
    >(`/contract/${contractId}/milestone/${milestoneId}/view`);
  };

  //---------------------------

  cleanup = () => {
    store.dispatch(
      contractActions.addContracts({
        data: [],
        replaceList: true,
      })
    );
  };

  //---------------------------

  shareOfferViaEmail = (params: ShareOfferViaEmailParams) => {
    return this._apiService.post(`/contract/send`, params);
  };

  //---------------------------

  preApproveContract = (
    contractId: string,
    milestoneId: MilestoneId,
    userDetails: {
      userId?: number;
      email?: string;
      firstName?: string;
      lastName?: string;
    }
  ) => {
    return this._apiService.anonPost<
      ApiResponse<{
        data: null;
      }>
    >(
      `/contract/pre-approve/${contractId}/milestone/${milestoneId}`,
      userDetails
    );
  };
  //---------------------------

  getPaymentReleaseStatus = (contractId: string, milestoneId: MilestoneId) => {
    return this._apiService
      .get<
        ApiResponse<{
          fundingDate: string;
          paymentReleasableDate: string;
          wait: { days: number };
        }>
      >(`/contract/${contractId}/milestone/${milestoneId}/release-fund-status`)
      .then((res) => res.data.data);
  };

  //---------------------------

  getPaymentStatus = (params: {
    stripeCheckoutSessionId?: string;
    milestoneId?: number;
  }) => {
    const { stripeCheckoutSessionId, milestoneId } = params;
    const { url: endpoint } = getUrl({
      endpoint: "/contract/funding-status",
      queryParams: {
        session_id: stripeCheckoutSessionId,
        milestone_id: milestoneId,
      },
    });

    return this._apiService
      .get<ApiResponse<PaymentStatus>>(endpoint)
      .then((res) => {
        // if(res.data.data.payment.isBankAccountPayment){
        //   res.data.data.payment.paymentCompleted = false;
        // }
        return res.data.data;
      });
  };

  //---------------------------

  canCreateOffer = () => {
    return this._apiService
      .get<
        ApiResponse<{
          canCreate: boolean;
          nextAllowedCreationTime: string | null;
        }>
      >("/contract/can-create")
      .then((res) => res.data.data);
  };

  //------------------------

  updateOffer = async ({
    contractId,
    contractDetails,
  }: {
    contractId: ContractId;
    contractDetails: UpdateContractAttributes;
  }) => {
    const res = await this._apiService.put<
      ApiResponse<ContractCompleteDetails>
    >(`v1/contracts/${contractId}`, {
      details: contractDetails,
    });

    await this.getContractByID(contractId);

    return res;
  };

  deleteOffer = ({ contractId }: { contractId: ContractId }) => {
    return this._apiService
      .delete<ApiResponse<any>>(`/contract/${contractId}`)
      .then((res) => {
        store.dispatch(
          contractActions.removeContracts({
            idList: [contractId],
          })
        );

        return res.data.data;
      });
  };

  closeJob = ({ contractId }: { contractId: ContractId }) => {
    return this._apiService
      .post<ApiResponse<ContractCompleteDetails>>(
        `v1/contracts/${contractId}/close`
      )
      .then((res) => {
        store.dispatch(
          contractActions.addContracts({
            data: [res.data.data],
          })
        );

        return res.data.data;
      });
  };

  addMilestoneToContract = async ({
    contractId,
    milestoneDetails,
  }: {
    contractId: ContractId;
    milestoneDetails: AddContractMilestoneAttributes;
  }) => {
    const res = await this._apiService
      .post<ApiResponse<any>>(`v1/contracts/${contractId}/milestones`, {
        details: milestoneDetails,
      })
      .then((res) => res.data.data);

    await this.getContractByID(contractId);

    return res;
  };

  deleteMilestone = async ({
    milestoneId,
    contractId,
  }: {
    milestoneId: MilestoneId;
    contractId: ContractId;
  }) => {
    const res = await this._apiService
      .delete<ApiResponse<any>>(`v1/contracts/milestones/${milestoneId}`)
      .then((res) => {
        return res.data.data;
      });

    await this.getContractByID(contractId);

    return res;
  };

  updateMilestone = async ({
    contractId,
    milestoneId,
    milestoneDetails,
  }: {
    milestoneId: MilestoneId;
    contractId: ContractId;
    milestoneDetails: UpdateContractMilestoneAttributes;
  }) => {
    const res = await this._apiService
      .put<ApiResponse<any>>(`v1/contracts/milestones/${milestoneId}`, {
        details: milestoneDetails,
      })
      .then((res) => {
        this.getContractByID(contractId);

        return res.data.data;
      });

    await this.getContractByID(contractId);

    return res;
  };

  launchMilestone = async ({
    milestoneId,
    contractId,
  }: {
    milestoneId: MilestoneId;
    contractId: ContractId;
  }) => {
    const res = await this._apiService
      .post<ApiResponse<boolean>>(
        `v1/contracts/milestones/${milestoneId}/launch`
      )
      .then((res) => {
        return res.data.data;
      });

    await this.getContractByID(contractId);

    return res;
  };

  //---------------------------

  generateContractFromTranscript = async ({
    content,
  }: {
    content: string;
  }): Promise<AiGeneratedContractResult> => {
    if (USE_DUMMY_AI_GENERATION_REPONSE) {
      return Promise.resolve({
        details: {
          "Engagement Type": "Milestones",
          Title: "Website Redesign Project",
          "Project/Milestone details": {
            "Project overview":
              "The project involves redesigning the website homepage with inspiration from multiple reference websites to ensure a unique and high-quality outcome. The project will be executed in milestones, each with specific deliverables and timelines.",
            Deliverables: [
              {
                Deliverable: "AIT Main Homepage Design",
                Description:
                  "Designing the main homepage for Astha IT, incorporating elements from Lunchbox, Bairesdev, Runway, and Musemind to create a unique and appealing visual experience. The design will not strictly follow the references but will aim to exceed them in quality and innovation.",
                Currency: "USD",
                Value: 750,
                "Start date": "2024-11-04T00:00:00.000Z",
                "End date": "2024-11-30T00:00:00.000Z",
              },
              {
                Deliverable: "Additional Pages Design",
                Description:
                  "Designing additional pages for the website, including a Portfolio Listing Page & Individual Portfolio Page, Services Listing Page & Individual Service Details Page, About Page, and a Generic Content Page. These pages will have simpler designs compared to the homepage.",
                Currency: "USD",
                Value: 800,
                "Start date": "2025-01-13T00:00:00.000Z",
                "End date": "2025-02-10T00:00:00.000Z",
              },
            ],
          },
          "Additional Terms": "",
        },
      } as AiGeneratedContractResult);
    }

    const res = await this._apiService
      .post<ApiResponse<AiGeneratedContractResponse>>(
        "/llm/transcriptToOffer",
        {
          content,
        }
      )
      .then((res) => {
        return res.data.data;
      });

    return res;
  };

  generateContractFromDocument = async ({ file }: { file: File }) => {
    if (USE_DUMMY_AI_GENERATION_REPONSE) {
      return Promise.resolve({
        details: {
          "Engagement Type": "",
          Title: "Website Redesign Project",
          "Project/Milestone details": {
            "Project overview": "",
            Deliverables: [],
          },
          "Additional Terms": "",
        },
      } as AiGeneratedContractResponse);
    }

    const data = new FormData();
    data.append("file", file);

    const res = await this._apiService
      .post<ApiResponse<AiGeneratedContractResponse>>(
        "/llm/agreementToOffer",
        data
      )
      .then((res) => {
        return res.data.data;
      });

    return res;
  };
}

export default ContractsService;
