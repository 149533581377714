import useSWR from "swr";

import { feedbackService } from "@/config/services";

import { FeedbackFeature } from "../Feedback.types";

function useFeedbackDetails({ feature }: { feature: FeedbackFeature }) {
  const key = `v1/users/feedbacks?feature=${feature}`;

  const {
    data = null,
    error,
    isLoading,
  } = useSWR(key, () =>
    feedbackService.getFeedbackDetails({ feature }).then((res) => res.data.data)
  );

  const isUpdating = data !== undefined && isLoading;

  //-----------------------

  return {
    data,
    error,
    isLoading,
    isUpdating,
  };
}

export default useFeedbackDetails;
