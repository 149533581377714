import React, { useMemo } from "react";
import lodashGet from "lodash/get";

import Button, { IButtonProps } from "@/components/input/Button";
import {
  ContractId,
  MilestoneId,
  UpdateContractMilestoneAttributes,
  useContractDetails,
  useUpdateMilestone,
} from "@/services/ContractsService";
import Modal, { useModalState } from "@/components/misc/Modal";
import MilestoneForm from "@/features/contracts/MilestoneForm";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { appFeatures } from "@/config/services";

const EditMilestoneButton: React.FC<
  IButtonProps & {
    contractId: ContractId;
    milestoneId: MilestoneId;
    milestoneIndex?: number;
  }
> = ({ contractId, milestoneId, milestoneIndex, children, ...buttonProps }) => {
  const { updateMilestone, isUpdating } = useUpdateMilestone({
    contractId,
    milestoneId,
  });
  const formModalState = useModalState();

  const { contractDetails } = useContractDetails({
    contractId,
  });
  const milestoneDetails = useMemo(() => {
    const milestone = contractDetails?.milestones.find(
      (milestone) => milestone.id === milestoneId
    );

    if (!milestone) {
      return undefined;
    }

    return {
      id: milestone.id,
      name: milestone.name,
      description: milestone.name,
      start_date: milestone.start_date,
      end_date: milestone.end_date,
      value: milestone.value,
      currency: milestone.currency,
    } satisfies UpdateContractMilestoneAttributes;
  }, [contractDetails, milestoneId]);

  const milestonesName = lodashGet(
    contractDetails,
    `milestones[${milestoneIndex}].name`,
    ""
  );

  const modalTitle =
    milestonesName ||
    (milestoneIndex ? `Milestone ${milestoneIndex + 1}` : "Edit Milestone");

  const buttonText = isUpdating ? "Updating Milestone..." : "Update Milestone";

  if (!milestoneDetails) {
    return null;
  }

  if (!appFeatures.isSupported("CONTRACT.UPDATE_JOB_MILESTONE")) {
    return null;
  }

  return (
    <>
      <Modal
        shouldCloseOnInteractOutside={() => !isUpdating}
        state={formModalState}
        contentContainerProps={{ style: { padding: 0 } }}
        mobileProps={{
          fullscreen: true,
        }}
      >
        <PageWithHeaderLayout
          headerProps={{
            titleProps: {
              children: modalTitle,
            },
            backButtonProps: {
              onClick: formModalState.close,
              disabled: isUpdating,
            },
          }}
        >
          <div className="p-3">
            <MilestoneForm
              initialValues={milestoneDetails}
              onSubmit={(values) => {
                updateMilestone(values);
              }}
              submitButtonProps={{
                disabled: isUpdating,
                children: buttonText,
              }}
            />
          </div>
        </PageWithHeaderLayout>
      </Modal>
      <Button
        variant="secondary"
        {...buttonProps}
        disabled={isUpdating}
        onClick={formModalState.open}
      >
        {children || buttonText}
      </Button>
    </>
  );
};

export default EditMilestoneButton;
