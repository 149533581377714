import React from "react";

import TextField from "@/components/input/TextField";

import {
  StyledBox,
  StyledDesktopOnlyStepHeading,
  StyledStep,
  StyledStepFloatingContent,
  StyledStepTopContent,
} from "../../ContractForm.styles";
import StepContinueButton from "../inputs/StepContinueButton";
import HeaderLayout from "../misc/HeaderLayout";
import TransactionNameInput from "../inputs/TransactionNameInput";
import TransactionPriceInput from "../inputs/TransactionPriceInput";
import ClientNameInput from "../inputs/ClientNameInput";
import StepBackButton from "../inputs/StepBackButton";

const HourlyContractDetailsStep: React.FC = () => {
  return (
    <HeaderLayout>
      <StyledDesktopOnlyStepHeading className="mb-3">
        Create an offer
      </StyledDesktopOnlyStepHeading>

      <StyledBox>
        <StyledStep>
          <StyledStepTopContent>
            <TransactionNameInput />

            <ClientNameInput className="mt-2" />

            <TextField className="mt-2" placeholder="Set weekly limit" />
            <TextField
              className="mt-2"
              placeholder="Set number of hours"
              type="number"
            />
            <TransactionPriceInput className="mt-2" />
          </StyledStepTopContent>

          <StyledStepFloatingContent>
            <StepBackButton />
            <StepContinueButton>Review</StepContinueButton>
          </StyledStepFloatingContent>
        </StyledStep>
      </StyledBox>
    </HeaderLayout>
  );
};

export default HourlyContractDetailsStep;
