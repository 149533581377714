import useSWR from "swr";
import { useCallback, useMemo, useState } from "react";

import {
  authActions,
  selectUserData,
  store,
  useAppSelector,
} from "@/services/Store";
import { userService } from "@/config/services";

import usePayoutOnboardStatus from "./usePayoutOnboardStatus";
import { STRICT_ONBOARD_COUNTRIES } from "../UserService.config";

function useManageOnboardingCountry() {
  const {
    onboardingCountry: onboardingCountryFromHook,
    isReady: isOnboardStatusReady,
  } = usePayoutOnboardStatus();

  const onboardingCountryFromStore =
    useAppSelector(selectUserData)?.stripe_onboarding_country;

  const { isLoading: isLoadingOnboardingCountry, mutate } = useSWR(
    "v1/users/onboarding/country",
    () =>
      userService.getOnboardingCountry().then((res) => {
        const country = res.data.data?.country;

        store.dispatch(
          authActions.setUserDataPartial({
            stripe_onboarding_country: country,
          })
        );

        return res.data.data;
      }),
    {}
  );

  const onboardingCountry =
    onboardingCountryFromHook || onboardingCountryFromStore || null;
  const isLoading =
    !onboardingCountry && (isLoadingOnboardingCountry || !isOnboardStatusReady);
  const isStrictOnboardCountry = useMemo(
    () =>
      STRICT_ONBOARD_COUNTRIES.includes(
        (onboardingCountry || "")?.toLocaleUpperCase()
      ),
    [onboardingCountry]
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const update = useCallback(
    ({ country }: { country: string }) => {
      if (isUpdating) {
        return;
      }

      if (country) {
        setIsUpdating(true);

        return userService
          .setOnboardingCountry({ country })
          .then(() => {
            store.dispatch(
              authActions.setUserDataPartial({
                stripe_onboarding_country: country,
              })
            );

            mutate();
          })
          .finally(() => {
            setIsUpdating(false);
          });
      }
    },
    [isUpdating, mutate]
  );

  return {
    onboardingCountry,
    isStrictOnboardCountry,
    isLoading,
    isUpdating,
    update,
  };
}

export default useManageOnboardingCountry;
