import styled from "@emotion/styled";
import { useCallback, useRef, useState } from "react";
import { OverlayTriggerState } from "react-stately";

import { useNavigate, useParamState } from "@/services/Routing";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { Body, Heading, Label } from "@/components/Typography";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import { SITE_PATHS } from "@/config/routing";
import Modal, { useModalState } from "@/components/misc/Modal";
import {
  AiGeneratedContractError,
  AiGeneratedContractResponse,
  aiGeneratedContractToContractDetails,
  ContractCreatePayload,
  useGenerateContractFromTranscript,
} from "@/services/ContractsService";
import Button from "@/components/input/Button";
import LocalStorage from "@/services/LocalStorage";
import Icon from "@/components/misc/Icon";
import {
  useManageOnboardingCountry,
  useOnboardStripe,
} from "@/services/UserService";

import InputTypeSelectionScreen from "./components/InputTypeSelectionScreen";
import TextInputScreen from "./components/TextInputScreen";
import DocumentInputScreen from "./components/DocumentInputScreen";
import LoaderScreen from "./components/LoaderScreen";

export type OfferInputType = "text" | "document" | "description";

const StyledLayout = styled(PageWithHeaderLayout)<{
  $gradientBackground?: boolean;
}>`
  transition: none;
  ${({ $gradientBackground }) =>
    $gradientBackground
      ? `
          background: var(--clr-primary-100, #5f57ff);
          background: linear-gradient(240.96deg, #5C22CE -7.5%, #323AAB 51.55%, #1681B5 94.1%);
        `
      : "background: #fff;"}

  ${screenSmallerThan.tablet} {
    main > div > div {
      padding-bottom: 2rem;
    }
  }

  ${screenLargerThan.tablet} {
    border-radius: 0.5rem;

    header {
      padding-inline: 0 !important;
    }

    main > div > div {
      min-height: 520px;
    }
  }
`;

const StyledPennyLogo = styled.img`
  display: block;
  margin-inline: 0;
`;

const StyledPennyLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledModalActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  & > * {
    border-radius: 10rem !important;
  }

  & > *:first-child {
    flex-grow: 1;
  }

  & > *:last-child {
    flex-grow: 3;
  }
`;

const StyledPill = styled.span`
  display: inline-block;
  padding: 0.125rem 0.5rem;
  background-color: #fff;
  color: var(--clr-primary-100, #5f57ff);
  border: 1px solid var(--clr-primary-100, #5f57ff);
  border-radius: 10rem;
  font-size: 0.875rem;
  font-weight: 600;
`;

const AiOfferForm: React.FC<{
  onSuccess: ({
    contractDetails,
  }: {
    contractDetails: ContractCreatePayload;
  }) => void;
  text?: string;
  setText?: (value: string) => void;
  file?: File | null;
  setFile?: (file: File | null) => void;
  appendHeaderContent?: React.ReactNode;
  showRegenerateButton?: boolean;
  className?: string;
  onGenerateTrigger?: ({ promise }: { promise?: Promise<any> }) => void;
  onError?: ({ error }: { error: AiGeneratedContractError }) => void;
  customOnboardingJsx?: React.ReactNode;
  onCreateManually: () => void;
  invalidDocumentModalState: OverlayTriggerState;
}> = ({
  onSuccess,
  file: fileFromProps,
  setFile: setFileFromProps,
  text: textFromProps,
  setText: setTextFromProps,
  appendHeaderContent,
  showRegenerateButton,
  className,
  onGenerateTrigger,
  onError,
  customOnboardingJsx,
  onCreateManually,
  invalidDocumentModalState,
}) => {
  const localStorage = useRef(
    new LocalStorage<{
      text_prompt: string;
    }>("PAYPIPE.PENNY")
  ).current;

  const { isReady: isOnboardStatusReady, isOnboarded } = useOnboardStripe();
  const { isStrictOnboardCountry, onboardingCountry } =
    useManageOnboardingCountry();

  const [inputType, setInputType] = useParamState<OfferInputType | "">(
    "ai_offer_input_type",
    "",
    {
      parseJson: true,
    }
  );
  const [fileFromState, setFileFromState] = useState<File | null>(null);
  const [textFromState, setTextFromState] = useState(
    localStorage.get("text_prompt") || ""
  );
  const { generate, inProgress } = useGenerateContractFromTranscript();
  const { navigate } = useNavigate();

  const isFileStateControlled =
    fileFromProps !== undefined && !!setFileFromProps;
  const file = isFileStateControlled ? fileFromProps : fileFromState;
  const setFile = (
    isFileStateControlled ? setFileFromProps : setFileFromState
  ) as (file: File | null) => void;

  const isTextStateControlled =
    fileFromProps !== undefined && !!setFileFromProps;
  const text = (isTextStateControlled ? textFromProps : textFromState) || "";
  const setText = (
    isTextStateControlled ? (setTextFromProps as Function) : setTextFromState
  ) as (value: string) => void;

  const promptOnboard =
    !isOnboarded && (!!onboardingCountry ? isStrictOnboardCountry : true);

  const showSelectionScreen = promptOnboard || !inputType;
  const showTextInputScreen =
    !promptOnboard && ["text", "description"].includes(inputType);
  const showDocumentInputScreen = !promptOnboard && inputType === "document";

  const confirmationModalState = useModalState();

  const handleBackClick = useCallback(() => {
    if (!inputType) {
      navigate(SITE_PATHS.HOME_PAGE);
    }

    if (showTextInputScreen || showDocumentInputScreen) {
      setInputType("");
    }
  }, [
    inputType,
    showTextInputScreen,
    showDocumentInputScreen,
    setInputType,
    navigate,
  ]);

  const openInputTypeScreen = useCallback(
    (value: OfferInputType) => {
      setInputType(value);
    },
    [setInputType]
  );

  const handleGenerate = useCallback(() => {
    confirmationModalState.close();

    function postSuccess(
      aiGeneratedcontractDetails: AiGeneratedContractResponse
    ) {
      if (aiGeneratedcontractDetails.details) {
        const { contractDetails } = aiGeneratedContractToContractDetails({
          details: aiGeneratedcontractDetails.details,
        });

        if (contractDetails) {
          onSuccess({
            contractDetails,
          });
          localStorage.remove("text_prompt");
        }
      } else if (aiGeneratedcontractDetails?.error) {
        if (onError) {
          onError({
            error: aiGeneratedcontractDetails?.error,
          });
        }
      } else {
        const { contractDetails } = aiGeneratedContractToContractDetails({
          details: {} as any,
        });

        if (contractDetails) {
          onSuccess({
            contractDetails,
          });
          localStorage.remove("text_prompt");
        }
      }
    }

    if (["text", "description"].includes(inputType) && !!text) {
      const promise = generate({
        content: text,
      })?.then(postSuccess);

      if (onGenerateTrigger) {
        onGenerateTrigger({ promise });
      }
    } else if (inputType === "document" && !!file) {
      const promise = generate({
        file,
      })?.then(postSuccess);

      if (onGenerateTrigger) {
        onGenerateTrigger({ promise });
      }
    }
  }, [
    inputType,
    file,
    text,
    generate,
    onSuccess,
    confirmationModalState,
    onGenerateTrigger,
    localStorage,
    onError,
  ]);

  const handleSetText = useCallback(
    (text: string) => {
      setText(text);
      localStorage.set("text_prompt", text);
    },
    [localStorage, setText]
  );

  if (inProgress || !isOnboardStatusReady) {
    return <LoaderScreen />;
  }

  return (
    <>
      <Modal
        state={confirmationModalState}
        contentContainerProps={{ style: { textAlign: "center" } }}
        width="320px"
      >
        <StyledLogoContainer>
          <StyledPennyLogo
            alt="Logo"
            src="/assets/images/branding/penny/logo_round.svg"
          />
        </StyledLogoContainer>
        <Heading size="lg" className="mt-4">
          Ready to go?
        </Heading>
        <Body size="lg" className="mt-2" style={{ color: "#818898" }}>
          I don't want to be hasty - are you ready for me to get generating?
        </Body>
        <StyledModalActionsContainer className="mt-3">
          <Button
            variant="secondary"
            colorVariant="gray"
            onClick={confirmationModalState.close}
          >
            Not yet
          </Button>
          <Button onClick={handleGenerate}>Let's go!</Button>
        </StyledModalActionsContainer>
      </Modal>

      <StyledLayout
        $gradientBackground={showSelectionScreen}
        className={className}
        headerProps={{
          titleProps: {
            children: (
              <StyledPennyLogoContainer>
                {showSelectionScreen ? (
                  <Label size="lg" style={{ color: "#fff" }}>
                    Generate offer
                  </Label>
                ) : (
                  <>
                    <StyledPennyLogo
                      alt="Penny AI"
                      src="/assets/images/branding/penny/logo_gradient.svg"
                    />
                    &nbsp;&nbsp;<StyledPill>beta</StyledPill>
                  </>
                )}{" "}
              </StyledPennyLogoContainer>
            ),
            style: {
              padding: 0,
            },
          },
          backButtonProps: {
            onClick: handleBackClick,
            style: {
              filter: showSelectionScreen
                ? "brightness(0) invert(1)"
                : undefined,
            },
            children: (
              <Icon isSrcRelative src="chevron_left.svg" customSize="1rem" />
            ),
          },
          appendContent: appendHeaderContent,
        }}
        contentContainerProps={{ style: { flexGrow: 1 } }}
        style={{
          color: showSelectionScreen ? "#fff" : undefined,
        }}
      >
        {showSelectionScreen && (
          <InputTypeSelectionScreen
            openInputTypeScreen={openInputTypeScreen}
            promptOnboard={promptOnboard}
            customOnboardingJsx={customOnboardingJsx}
          />
        )}
        {showTextInputScreen && (
          <TextInputScreen
            text={text}
            setText={handleSetText}
            handleGenerate={confirmationModalState.open}
            showRegenerateButton={showRegenerateButton}
            inputType={inputType}
          />
        )}
        {showDocumentInputScreen && (
          <DocumentInputScreen
            file={file}
            setFile={setFile}
            handleGenerate={confirmationModalState.open}
            showRegenerateButton={showRegenerateButton}
            onCreateManually={onCreateManually}
            invalidDocumentModalState={invalidDocumentModalState}
          />
        )}
      </StyledLayout>
    </>
  );
};

export default AiOfferForm;
