import React from "react";

import MobilePrimaryLayout from "@/features/layouts/MobilePrimaryLayout";
import GetPremiumCard from "@/features/user/GetPremiumCard";
import AiOfferCardBanner from "@/features/contracts/AiOfferBanner";
import { appFeatures } from "@/config/services";

import { StyledPage, TopContainer } from "./HomePage.styles";
import PrimaryActionsSection from "./components/PrimaryActionsSection";
import BalanceSection from "./components/BalanceSection";
import ContractsOverviewCard from "./components/ContractsOverviewCard";
import RequiredActionsSection from "./components/RequiredActionsSection";
import RecentSection from "./components/RecentSection";
import ContractActionCard from "./components/ContractActionCard";

const HomePage: React.FC = () => {
  return (
    <MobilePrimaryLayout>
      <StyledPage className="no-scrollbar">
        <AiOfferCardBanner />

        <TopContainer>
          <BalanceSection />
          <ContractsOverviewCard />
        </TopContainer>

        <PrimaryActionsSection className="mt-3" />

        <GetPremiumCard className="mt-3" />

        <RequiredActionsSection className="mt-3" />

        {appFeatures.isSupported("DASHBOARD.CONTRACT_MANAGEMENT_CARD") && (
          <ContractActionCard />
        )}

        <RecentSection className="mt-3" />
      </StyledPage>
    </MobilePrimaryLayout>
  );
};

export default HomePage;
