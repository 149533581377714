import React from "react";

import { ContractType } from "@/services/ContractsService";

import { useContractForm } from "../../ContractForm.context";
import OneTimeContractDetailsStep from "./OneTimeContractDetailsStep";
import MilestoneContractDetailsStep from "./MilestoneContractDetailsStep";
import HourlyContractDetailsStep from "./HourlyContractDetailsStep";

const ContractDetailsStep: React.FC = () => {
  const { values } = useContractForm();

  const isOneTimeContract = values.contract_type === ContractType.OneTime;
  const isMilestoneContract = values.contract_type === ContractType.Milestone;
  const isHourlyContract = values.contract_type === ContractType.Hourly;

  if (isOneTimeContract) {
    return <OneTimeContractDetailsStep />;
  }

  if (isMilestoneContract) {
    return <MilestoneContractDetailsStep />;
  }

  if (isHourlyContract) {
    return <HourlyContractDetailsStep />;
  }
};

export default ContractDetailsStep;
