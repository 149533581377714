import { useCallback, useMemo, useState } from "react";
import lodashGet from "lodash/get";

import { contractService, mediaService } from "@/config/services";
import { FileObject } from "@/features/input/FilesInput";
import { useToast } from "@/components/misc/Toast";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body, Heading } from "@/components/Typography";
import styled from "@emotion/styled";
import Button from "@/components/input/Button";
import { usePayoutOnboardStatus } from "@/services/UserService";

import useContractDetails from "./useContractDetails";
import useReleasePaymentStatus from "./useReleasePaymentStatus";
import { getContractPayer } from "../utils";
import { useContractsService } from "../ContractsService.context";

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

type Work = {
  content: string;
  attachments: FileObject[];
};

function useSubmitContractMilestone(params: {
  contractId: string;
  milestoneId: number;
  showConfirmation?: boolean;
}) {
  const { contractId, milestoneId, showConfirmation = true } = params;
  const { createToast } = useToast();
  const { reload: reloadContractDetails, contractDetails } = useContractDetails(
    { contractId }
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const modalState = useModalState();

  const { showContractorOnboardModal } = useContractsService();

  const { isOnboarded } = usePayoutOnboardStatus();

  const clientName = useMemo(() => {
    if (!contractDetails) {
      return "";
    }

    return getContractPayer(contractDetails)?.user?.given_name || "";
  }, [contractDetails]);

  const [work, setWork] = useState<Work>({
    attachments: [],
    content: "",
  });

  const {
    data: paymentReleaseStatus,
    isLoading: isLoadingPaymentReleaseStatus,
    canReleaseFund,
    remainingTimeFormatted,
  } = useReleasePaymentStatus({ contractId, milestoneId });

  const canApprove = canReleaseFund;
  const showWaitPrompt = !canApprove;

  //-----------------------------

  const submit = useCallback(
    (workFromParams?: Work) => {
      const { content, attachments } = workFromParams || work;
      const hasWork = !!content || !!attachments?.length;

      if (showWaitPrompt && !hasWork) {
        modalState.open();
        return Promise.reject();
      }

      if (isSubmitting) {
        return Promise.reject();
      }

      setIsSubmitting(true);
      setIsSubmitted(false);

      const { contractId, milestoneId } = params;

      return (async function () {
        try {
          const attatchmentsRes = await Promise.all(
            attachments
              .filter(({ file }) => !!file)
              .map(({ file }) => mediaService.uploadFile(file as File))
          );

          const attatchmentIds = attatchmentsRes.map((res) => res.data.data.id);

          const payload = {
            attachments: attatchmentIds,
            description: content,
            contractId,
            milestoneId,
          };

          await contractService.submitContractMilestone(payload);

          createToast({
            title: hasWork
              ? "Work submitted successfully."
              : "Payment request sent successfully.",
            variant: "success",
            timeoutInMilliseconds: 5000,
          });

          setIsSubmitted(true);

          if (!isOnboarded && canReleaseFund) {
            showContractorOnboardModal({ clientName });
          }
        } catch (err) {
          const errorMessage = lodashGet(err, "response.data.message") || "";
          setIsSubmitted(false);

          createToast({
            title: hasWork
              ? "Something went wrong, Work not submitted."
              : "Something went wrong, request not sent.",
            variant: "error",
            description: errorMessage,
          });
        } finally {
          setIsSubmitting(false);
          reloadContractDetails();
        }
      })();
    },
    [
      params,
      isSubmitting,
      reloadContractDetails,
      createToast,
      showWaitPrompt,
      modalState,
      work,
      isOnboarded,
      showContractorOnboardModal,
      clientName,
      canReleaseFund,
    ]
  );

  const markAsDone = useCallback(() => {
    return submit({
      attachments: [],
      content: "",
    });
  }, [submit]);

  //-----------------------------

  const waitDays = paymentReleaseStatus?.wait?.days || 3;
  const paymentWaitJsx = (
    <div>
      <Heading size="lg">
        Please check back in {remainingTimeFormatted}.
      </Heading>

      <Body size="lg" className="mt-3">
        To keep your transactions safe and secure, we have a {waitDays} business
        days waiting period before releasing payments. This helps us protect you
        from potential risks.
      </Body>

      <Body size="lg" className="mt-3">
        We understand that waiting can be inconvenient, but rest assured your
        security is our top priority. Please check back in{" "}
        {remainingTimeFormatted}.
      </Body>

      <Body size="lg" className="mt-3">
        Thank you for your patience and understanding.
      </Body>

      <Button className="mt-4 w-100" onClick={modalState.close}>
        Close
      </Button>
    </div>
  );

  const submitButtonText = isSubmitting
    ? "Requesting Payment..."
    : "Request Payment";
  const confirmationJsx = (
    <>
      <Body size="lg">Are you sure want to request payment?</Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={modalState.close}
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          onClick={() => {
            submit();
          }}
        >
          {submitButtonText}
        </Button>
      </StyledButtonsContainer>
    </>
  );

  const { content, attachments } = work;
  const hasWork = !!content || !!attachments?.length;

  const submitWorkButtonText = isSubmitting
    ? "Submitting Work..."
    : "Submit Work";
  const submitWorkConfirmationJsx = (
    <>
      <Body size="lg">Are you sure want to submit work?</Body>
      <StyledButtonsContainer className="mt-4">
        <Button
          variant="secondary"
          colorVariant="gray"
          onClick={modalState.close}
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          onClick={() => {
            submit();
          }}
        >
          {submitWorkButtonText}
        </Button>
      </StyledButtonsContainer>
    </>
  );

  const jsx = (
    <Modal
      state={modalState}
      width={showWaitPrompt ? (hasWork ? "360px" : "560px") : "360px"}
    >
      {showWaitPrompt
        ? hasWork
          ? submitWorkConfirmationJsx
          : paymentWaitJsx
        : confirmationJsx}
    </Modal>
  );

  //-----------------------------

  return {
    submit: showConfirmation ? modalState.open : submit,
    markAsDone: showConfirmation ? modalState.open : markAsDone,
    isSubmitted,
    isSubmitting,
    jsx,
    loading: isLoadingPaymentReleaseStatus,
    work,
    setWork,
  };
}

export default useSubmitContractMilestone;
