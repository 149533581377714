import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";

import { useForm } from "@/components/input/Form";
import {
  ContractCompleteDetails,
  ContractCreationFlow,
} from "@/services/ContractsService";
import { useNavigate, usePathname, useSearchParams } from "@/services/Routing";
import {
  useManageOnboardingCountry,
  useOnboardStripe,
} from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { SITE_PATHS } from "@/config/routing";
import { analytics, appFeatures } from "@/config/services";
import { OfferFormEvents } from "@/services/Analytics";
import Modal, { useModalState } from "@/components/misc/Modal";
import Button from "@/components/input/Button";
import { Body, Heading } from "@/components/Typography";
import LocalStorage from "@/services/LocalStorage";

import {
  ClientInputType,
  ContractFormRef,
  ContractFormValues,
  IContractFormContext,
  IContractFormProps,
  Step,
} from "./ContractForm.types";
import {
  STEPS_CONFIG,
  STEPS_LIST,
  STEP_URL_PARAM_NAME,
} from "./ContractForm.config";
import SuccessScreen from "./components/screens/SuccessScreen";
import DescripitonTip from "./components/misc/DescripitonTip";

const INITIAL_VALUES = {} as IContractFormContext;

const ContractFormContext = createContext(INITIAL_VALUES);

export const useContractForm = () => useContext(ContractFormContext);

const StyledPennyLogo = styled.img`
  display: block;
  margin-inline: 0;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContractFormProvider: React.FC<
  IContractFormProps & {
    creatingContract: boolean;
    contractCreated: boolean;
    createdContractDetails: null | ContractCompleteDetails;
    milestoneMaxValue: number;
    flow: ContractCreationFlow;
    setAiGeneratedOfferDetails: (value: ContractFormValues | null) => void;
  }
> = ({
  getFormRef,
  onStepChange,
  contractCreated,
  createdContractDetails,
  initialValues,
  creatingContract,
  className,
  milestoneMaxValue,
  flow,
  setAiGeneratedOfferDetails,
}) => {
  const { navigate, back } = useNavigate();
  const { pathname, url: currentUrl } = usePathname();
  const { searchParams } = useSearchParams();
  const {
    onboardingCountry,
    isLoading: isLoadingOnboardingCountry,
    isStrictOnboardCountry,
  } = useManageOnboardingCountry();

  const ref = useRef({} as ContractFormRef);
  const stepContainerElRef = useRef<HTMLDivElement | null>(null);

  const formContextValue = useForm<ContractFormValues>();
  const { values, errors, setAllErrorFieldsTouched } = formContextValue;

  const [clientInputType, setClientInputType] = useState<ClientInputType>(
    !values.client_paypipe_id && !!values.client_email_number
      ? ClientInputType.Email
      : ClientInputType.PaypipeId
  );

  const aiLocalStorage = useRef(
    new LocalStorage<{
      text_prompt: string;
    }>("PAYPIPE.PENNY")
  ).current;
  const [aiFlowFileInput, setAiFlowFileInput] = useState<File | null>(null);
  const [aiFlowTextInput, setAiFlowTextInput] = useState(
    aiLocalStorage.get("text_prompt") || ""
  );

  const descripitonTipModalState = useModalState();
  const [alreadyShownDecriptionTip, setAlreadyShownDecriptionTip] =
    useState(false);
  const [didOfferGenerated, setDidOfferGenerated] = useState(false);

  const isAiFlow = flow === ContractCreationFlow.AI;

  const aiGeneratedMissingValuesModelState = useModalState();

  const { canOnboard, isReady, updateCanOnboardState, isOnboarded } =
    useOnboardStripe();
  const stepsList = useMemo(() => {
    let steps = STEPS_LIST;
    const showOnboardingStep =
      !isOnboarded &&
      (onboardingCountry ? canOnboard && isStrictOnboardCountry : true);

    if (showOnboardingStep) {
      steps = STEPS_LIST.filter(
        ({ id }) =>
          ![Step.ContractType, Step.ContractDetails, Step.Review].includes(
            id as Step
          )
      );

      if (!isAiFlow || !appFeatures.isSupported("CONTRACT.CREATE_WITH_AI")) {
        steps = steps.filter(
          ({ id }) => ![Step.AiGeneration].includes(id as Step)
        );
      }
    } else if (isAiFlow && appFeatures.isSupported("CONTRACT.CREATE_WITH_AI")) {
      steps = STEPS_LIST.filter(
        ({ id }) =>
          ![
            Step.ContractType,
            Step.ContractCreator,
            Step.PayoutOnboarding,
          ].includes(id as Step)
      );
    } else {
      steps = STEPS_LIST.filter(
        ({ id }) =>
          ![Step.PayoutOnboarding, Step.AiGeneration].includes(id as Step)
      );
    }

    return steps;
  }, [
    canOnboard,
    isAiFlow,
    onboardingCountry,
    isStrictOnboardCountry,
    isOnboarded,
  ]);

  const setStepUrlParam = useCallback(
    (step: Step, replace = false) => {
      searchParams.delete(STEP_URL_PARAM_NAME);
      searchParams.set(STEP_URL_PARAM_NAME, STEPS_CONFIG[step]?.urlId || "");
      const path = `${pathname}?${searchParams.toString()}`;

      navigate(path, replace);
    },
    [pathname, searchParams, navigate]
  );

  const gotToStep = useCallback(
    (step: Step) => {
      setCurrentStep(step);

      if (onStepChange) {
        onStepChange(step);
      }

      if (stepContainerElRef.current) stepContainerElRef.current.scrollTop = 0;

      setStepUrlParam(step);
    },
    [onStepChange, setStepUrlParam]
  );

  const [currentStep, setCurrentStep] = useState<Step>(() => {
    let currentStep = Step.ContractDetails;

    if (
      flow === ContractCreationFlow.AI &&
      (appFeatures.isSupported("CONTRACT.ALLOW_BEFORE_PAYEE_ONBOARDING")
        ? true
        : !canOnboard)
    ) {
      currentStep = Step.AiGeneration;
      setStepUrlParam(Step.AiGeneration, true);
    } else if (!initialValues?.created_as) {
      currentStep = Step.ContractCreator;
      setStepUrlParam(Step.ContractCreator, true);
    } else if (
      canOnboard &&
      !appFeatures.isSupported("CONTRACT.ALLOW_BEFORE_PAYEE_ONBOARDING")
    ) {
      currentStep = Step.PayoutOnboarding;
      setStepUrlParam(Step.PayoutOnboarding, true);
    } else if (!initialValues?.contract_type) {
      currentStep = Step.ContractType;
      setStepUrlParam(Step.ContractType, true);
    } else {
      currentStep = Step.ContractDetails;
      setStepUrlParam(Step.ContractDetails, true);
    }

    if (onStepChange) {
      onStepChange(currentStep);
    }

    return currentStep;
  });

  const isLastStep = currentStep === stepsList[stepsList.length - 1].id;

  // ---------------------

  const renderStep = useCallback(
    () => STEPS_CONFIG[currentStep]?.component,
    [currentStep]
  );

  const getStepIndex = useCallback(
    (step: Step) => {
      const index = stepsList.findIndex(({ id }) => id === step);

      return index;
    },
    [stepsList]
  );

  const previousStep = useCallback(() => {
    const currentStepIndex = getStepIndex(currentStep);

    if (currentStepIndex === 0) {
      if (currentUrl.includes(SITE_PATHS.CREATE_CONTRACT_PAGE)) {
        navigate(SITE_PATHS.HOME_PAGE);
        return;
      }

      back();
      return;
    }

    const nextStepIndex = Math.max(0, currentStepIndex - 1);
    gotToStep(stepsList[nextStepIndex].id as Step);
  }, [
    currentStep,
    gotToStep,
    getStepIndex,
    stepsList,
    back,
    currentUrl,
    navigate,
  ]);

  const nextStep = useCallback(() => {
    const currentStepIndex = getStepIndex(currentStep);

    if (currentStepIndex === stepsList.length - 1) {
      return;
    }

    const nextStepIndex = Math.min(stepsList.length - 1, currentStepIndex + 1);
    gotToStep(stepsList[nextStepIndex].id as Step);
  }, [currentStep, gotToStep, getStepIndex, stepsList]);

  const checkIsStepCompleted = useCallback(
    (step: Step) => {
      const validityFunction = STEPS_CONFIG[step]?.isStepCompleted;
      const isCompleted = validityFunction
        ? validityFunction({ values, errors, milestoneMaxValue })
        : true;

      return isCompleted;
    },
    [values, errors, milestoneMaxValue]
  );

  const showDecriptionTip = useCallback(() => {
    if (alreadyShownDecriptionTip) {
      return;
    }

    setAlreadyShownDecriptionTip(true);
    descripitonTipModalState.open();
  }, [alreadyShownDecriptionTip, descripitonTipModalState]);

  // ---------------------

  useEffect(() => {
    analytics.triggerOfferFormEvent("offer_form_started");
  }, []);

  useEffect(() => {
    ref.current = {
      nextStep,
      previousStep,
    };

    if (getFormRef) getFormRef(ref.current);
  }, [nextStep, previousStep, getFormRef]);

  const stepUrlId =
    searchParams.get(STEP_URL_PARAM_NAME) ||
    (isAiFlow
      ? STEPS_CONFIG.AiGeneration?.urlId
      : STEPS_CONFIG.ContractCreator?.urlId);
  const currentStepRef = useRef(currentStep);
  currentStepRef.current = currentStep;
  const gotToStepRef = useRef(gotToStep);
  gotToStepRef.current = gotToStep;
  useEffect(() => {
    const step: any = STEPS_LIST.find(({ urlId }) => urlId === stepUrlId);

    if (step && step.id !== currentStepRef.current) {
      gotToStepRef.current(step.id);
    }
  }, [stepUrlId]);

  const updateCanOnboardStateRef = useRef(updateCanOnboardState);
  useEffect(() => {
    if (isReady) {
      updateCanOnboardStateRef.current(true);
    }
  }, [isReady]);

  useEffect(() => {
    let eventName: OfferFormEvents | null = null;

    if (currentStep) {
      eventName = STEPS_CONFIG[currentStep]?.analyticsEventName || null;
    }

    if (eventName) {
      analytics.triggerOfferFormEvent(eventName);
    }
  }, [currentStep]);

  // ---------------------

  const aiGeneratedMissingValuesModelJsx = (
    <Modal
      state={aiGeneratedMissingValuesModelState}
      contentContainerProps={{ style: { textAlign: "center" } }}
      width="340px"
    >
      <StyledLogoContainer>
        <StyledPennyLogo
          alt="Logo"
          src="/assets/images/branding/penny/logo_round.svg"
        />
      </StyledLogoContainer>
      <Heading size="lg" className="mt-4">
        Some fields are missing!
      </Heading>
      <Body size="lg" className="mt-2" style={{ color: "#818898" }}>
        There seems to be some fields missing. Those will be highlighted in red
        for you to fill manually.
      </Body>

      <Button
        className="mt-4 w-100"
        onClick={() => {
          aiGeneratedMissingValuesModelState.close();
          setAllErrorFieldsTouched();
        }}
      >
        Fill manually
      </Button>
    </Modal>
  );

  if (!isReady || isLoadingOnboardingCountry) {
    return <SplashScreen />;
  }

  return (
    <ContractFormContext.Provider
      value={{
        ...formContextValue,
        currentStep,
        clientInputType,
        setClientInputType,
        createdContractDetails,
        checkIsStepCompleted,
        gotToStep,
        previousStep,
        nextStep,
        isLastStep,
        creatingContract,
        showDecriptionTip,
        alreadyShownDecriptionTip,
        aiFlowFileInput,
        setAiFlowFileInput,
        aiFlowTextInput,
        setAiFlowTextInput,
        flow,
        didOfferGenerated,
        setDidOfferGenerated,
        aiGeneratedMissingValuesModelState,
        milestoneMaxValue,
        setAiGeneratedOfferDetails,
      }}
    >
      {contractCreated ? (
        <SuccessScreen />
      ) : (
        <form className={className}>
          {aiGeneratedMissingValuesModelJsx}
          {renderStep()}
          {descripitonTipModalState.isOpen && (
            <Modal
              showCloseButton
              state={descripitonTipModalState}
              contentContainerProps={{
                style: {
                  padding: "1rem",
                },
              }}
            >
              <DescripitonTip />
            </Modal>
          )}
        </form>
      )}
    </ContractFormContext.Provider>
  );
};
