import { DefaultGenerics, StreamChat } from "stream-chat";

export type StreamClient = StreamChat<DefaultGenerics> | null;

export type ChatServiceContext = {
  isActive: boolean;
  streamClient: StreamClient;
  channelListJsx: any;
  connectUser: () => Promise<boolean>;
  disconnectUser: () => Promise<boolean>;
  isConnecting: boolean;
};

export type Notification = any;

export type Message = any;

export enum ChannelType {
  ChatRequest = "ChatRequest",
  PersonalChat = "PersonalChat",
  Contract = "Contract",
}

export enum SystemMessageType {
  ReleasePayment = "RELEASE_PAYMENT_ACTION",
  RequestPayment = "REQUEST_PAYMENT_ACTION",
  FundMilestone = "FUND_MILESTONE_ACTION",
  StartMilestone = "START_MILESTONE_ACTION",
}

export type SystemMessage = {
  type: string;
  data: Record<string, any>;
};
