import { useCallback, useState } from "react";

import { useToast } from "@/components/misc/Toast";
import { contractService } from "@/config/services";

import {
  AddContractMilestoneAttributes,
  ContractId,
} from "../ContractsService.types";

function useAddMilestone({ contractId }: { contractId: ContractId }) {
  const [isAdding, setIsAdding] = useState(false);
  const { createToast } = useToast();

  // ----------------------------------

  const addMilestone = useCallback(
    (milestoneDetails: AddContractMilestoneAttributes) => {
      setIsAdding(true);

      return contractService
        .addMilestoneToContract({ contractId, milestoneDetails })
        .then(() => {
          createToast({
            title: "Milestone added",
          });
        })
        .catch(() => {
          createToast({
            variant: "error",
            title: "Couldn't added milestone",
            description: "Something went wrong, milestone is not added.",
          });
        })
        .finally(() => {
          setIsAdding(false);
        });
    },
    [contractId, createToast]
  );

  // ----------------------------------

  return {
    isAdding,
    addMilestone,
  };
}

export default useAddMilestone;
