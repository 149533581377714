"use client";
import React, { useCallback, useMemo, useState } from "react";
import lodashGet from "lodash/get";

import Form from "@/components/input/Form";
import {
  analytics,
  contractService,
  mediaService,
  errorReporting,
} from "@/config/services";
import { dateToEpoch } from "@/utils/date";
import {
  ContractCompleteDetails,
  ContractCreationFlow,
  ContractCreatorType,
  ContractCurrency,
  ContractType,
  MAX_MILESTONE_VALUE,
  sanitizeContractCompleteDetails,
} from "@/services/ContractsService";
import { useToast } from "@/components/misc/Toast";
import { useSearchParams } from "@/services/Routing";
import { useAuth } from "@/services/Authentication";

import { INITIAL_VALUES } from "./ContractForm.config";
import { ContractFormValues, IContractFormProps } from "./ContractForm.types";
import { ContractFormProvider } from "./ContractForm.context";
import { getYupValidationSchema } from "./ContractForm.utils";

const ContractForm: React.FC<IContractFormProps> = (props) => {
  const { initialValues: initialValuesFromProps = INITIAL_VALUES, onChange } =
    props;

  const { createToast } = useToast();
  const { userData } = useAuth();

  const { searchParams } = useSearchParams();
  const flow = searchParams.get("flow") as ContractCreationFlow;

  const [creatingContract, setCreatingContract] = useState(false);
  const [contractCreated, setContractCreated] = useState(false);
  const [createdContractDetails, setCreatedContractDetails] =
    useState<null | ContractCompleteDetails>(null);
  const [aiGeneratedOfferDetails, setAiGeneratedOfferDetails] =
    useState<ContractFormValues | null>(null);

  //----------------------------

  const initialValues = useMemo(() => {
    return initialValuesFromProps;
  }, [initialValuesFromProps]);

  //----------------------------

  const isAiFlow = flow === ContractCreationFlow.AI;

  const handleSubmit = useCallback(
    (values: ContractFormValues) => {
      if (creatingContract) return;

      setCreatingContract(true);

      (async function () {
        try {
          const { files = [] } = values;
          const payload: any = { ...values } as ContractFormValues;

          if (isAiFlow) {
            const isAiGeneratedOfferModified =
              aiGeneratedOfferDetails?.title !== payload?.title ||
              aiGeneratedOfferDetails?.description !== payload?.description ||
              (aiGeneratedOfferDetails?.milestones || []).some(
                (milestone, i) => {
                  return (
                    milestone?.name !==
                      lodashGet(payload, `milestones[${i}].name`) ||
                    milestone?.description !==
                      lodashGet(payload, `milestones[${i}].description`) ||
                    milestone?.value !==
                      lodashGet(payload, `milestones[${i}].value`)
                  );
                }
              );

            payload.is_ai_generated = true;
            payload.is_ai_generated_modified = isAiGeneratedOfferModified;
          }

          const attatchmentsRes = await Promise.all(
            (files || [])
              .filter(({ file }) => !!file)
              .map(({ file }) => mediaService.uploadFile(file as File))
          );

          const attatchmentIds = attatchmentsRes.map((res) => res.data.data.id);
          payload.attachments = attatchmentIds;

          payload.start_date = payload.start_date
            ? dateToEpoch(payload.start_date)
            : payload.start_date;
          payload.end_date = payload.end_date
            ? dateToEpoch(payload.end_date)
            : payload.end_date;

          payload.milestones = values.milestones.map((m) => ({
            ...m,
            start_date: m.start_date
              ? dateToEpoch(new Date(m.start_date))
              : m.start_date,
            end_date: m.end_date
              ? dateToEpoch(new Date(m.end_date))
              : m.end_date,
            currency: ContractCurrency.USD,
          }));

          if (values.contract_type === ContractType.OneTime) {
            payload.milestones = [payload.milestones[0]];
          }

          // TO-CHECK
          payload.created_as = ContractCreatorType.Payee;

          const contractDetailsRes = await contractService.createContract(
            payload
          );
          const contractDetails = sanitizeContractCompleteDetails(
            contractDetailsRes.data.data
          );
          setCreatedContractDetails(contractDetails);

          setContractCreated(true);

          analytics.triggerOfferFormEvent("offer_form_submit_success");
        } catch (err) {
          errorReporting.report(err);
          analytics.triggerOfferFormEvent("offer_form_submit_failed");
          setContractCreated(false);
          createToast({
            title: "Offer not created!",
            variant: "error",
            timeoutInMilliseconds: 15000,
          });
        } finally {
          setCreatingContract(false);
        }
      })();
    },
    [creatingContract, createToast, aiGeneratedOfferDetails, isAiFlow]
  );

  const milestoneMaxValue =
    userData?.milestone_max_value || MAX_MILESTONE_VALUE;
  const validationSchema = useMemo(() => {
    return getYupValidationSchema({ milestoneMaxValue });
  }, [milestoneMaxValue]);

  //----------------------------

  if (!userData) {
    return null;
  }

  return (
    <>
      <Form<ContractFormValues>
        onSubmit={handleSubmit}
        yupValidationSchema={validationSchema}
        initialValues={initialValues}
        onChange={onChange}
      >
        <ContractFormProvider
          {...props}
          creatingContract={creatingContract}
          contractCreated={contractCreated}
          createdContractDetails={createdContractDetails}
          milestoneMaxValue={milestoneMaxValue}
          flow={flow}
          setAiGeneratedOfferDetails={setAiGeneratedOfferDetails}
        />
      </Form>
    </>
  );
};

export default ContractForm;
