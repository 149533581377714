import React from "react";
import lodashGet from "lodash/get";

import PriceCurrenyField from "@/features/input/PriceCurrenyField";
import {
  ContractCurrency,
  MIN_MILESTONE_VALUE,
} from "@/services/ContractsService";
import { Body } from "@/components/Typography";

import { useContractForm } from "../../ContractForm.context";
import { ERRORS } from "../../ContractForm.config";

const TransactionPriceInput: React.FC<{ className?: string }> = ({
  className,
}) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    touchedAndHasError,
    errors,
    milestoneMaxValue,
  } = useContractForm();

  const priceFieldKey = `milestones[0].value`;
  const showError = touchedAndHasError(priceFieldKey);
  const priceError = lodashGet(errors, priceFieldKey, "");
  const value = lodashGet(values, priceFieldKey, "");

  return (
    <>
      <PriceCurrenyField
        className={className}
        // TO-CHECK: Remove hardcode USD currency
        currency={ContractCurrency.USD}
        onCurrencyChange={() => {}}
        price={value || 0}
        onPriceChange={(value) => {
          setFieldValue(priceFieldKey, +value);
        }}
        priceFieldProps={{
          onBlur: () => {
            setFieldTouched(priceFieldKey);
          },
          hasError: showError,
          min: MIN_MILESTONE_VALUE,
          max: milestoneMaxValue,
        }}
      />

      {!!priceError && (
        <>
          {priceError === ERRORS.MIN_PRICE_ERROR && (
            <Body
              className="mt-2 color-error"
              size="md"
              style={{ paddingLeft: "2rem" }}
            >
              Price cannot be less than {MIN_MILESTONE_VALUE} USD
            </Body>
          )}

          {priceError === ERRORS.MAX_PRICE_ERROR && (
            <Body
              className="mt-2 color-error"
              size="md"
              style={{ paddingLeft: "2rem" }}
            >
              Price cannot be more than {milestoneMaxValue} USD
            </Body>
          )}
        </>
      )}
    </>
  );
};

export default TransactionPriceInput;
