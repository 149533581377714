import {
  ContractCreatorType,
  ContractMilestoneStatus,
  ContractStatus,
  ContractType,
} from "./ContractsService.types";

export const CONTRACT_TYPE_CONFIG: Record<
  ContractType,
  { info: { title: string; description: string } }
> = {
  [ContractType.OneTime]: {
    info: {
      title: "One-time",
      description:
        "A single, comprehensive job or task with a fixed scope and payment.",
    },
  },
  [ContractType.Milestone]: {
    info: {
      title: "Milestones",
      description:
        "A job with multiple milestones or tasks. Payments will be taken and released at each milestone. ",
    },
  },
  [ContractType.Hourly]: {
    info: {
      title: "Hourly-based",
      description:
        "A job that is funded and charged an an hourly rate as agreed between  parties.",
    },
  },
};
export const CONTRACT_CREATOR_TYPE_CONFIG: Record<
  ContractCreatorType,
  { displayName: string }
> = {
  [ContractCreatorType.Payee]: {
    displayName: "Service Provider",
  },
  [ContractCreatorType.Payer]: {
    displayName: "Client",
  },
};

//--------------| STATUS |--------------

export const CONTRACT_STATUS_ORDER: { status: ContractStatus }[] = [
  {
    status: ContractStatus.Active,
  },
  {
    status: ContractStatus.Accepted,
  },
];

export const CONTRACT_STATUS_CONFIG: Partial<
  Record<ContractStatus, { flowOrder: number }>
> = {};

CONTRACT_STATUS_ORDER.forEach(({ status }, i) => {
  const config = CONTRACT_STATUS_CONFIG[status];

  if (config) {
    config.flowOrder = i;
  } else {
    CONTRACT_STATUS_CONFIG[status] = { flowOrder: i };
  }
});

export const MILESTONE_STATUS_CONFIG: Record<
  ContractMilestoneStatus,
  { order: number }
> = {
  [ContractMilestoneStatus.Published]: {
    order: 0,
  },
  [ContractMilestoneStatus.Accepted]: {
    order: 1,
  },
  [ContractMilestoneStatus.Funded]: {
    order: 1.5,
  },
  [ContractMilestoneStatus.FundRequested]: {
    order: 2,
  },
  [ContractMilestoneStatus.ReadyForWork]: {
    order: 2,
  },
  [ContractMilestoneStatus.Review]: {
    order: 3,
  },
  [ContractMilestoneStatus.FundReleased]: {
    order: 3.5,
  },
  [ContractMilestoneStatus.Completed]: {
    order: 4,
  },
};

//------------------

export const MIN_MILESTONE_VALUE = 10; // In USD
export const MAX_MILESTONE_VALUE = 4000; // In USD

export const OFFER_FEEDBACK_KEY = "OFFER_FORM_AND_SHARE_FLOW";

export const USE_DUMMY_AI_GENERATION_REPONSE = false;

export const AI_OFFER_PROMPT_MIN_CHAR_COUNT = 50;
export const AI_OFFER_PROMPT_MAX_CHAR_COUNT = 25000;
