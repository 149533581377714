import React, { useEffect } from "react";

import { SITE_PATHS } from "@/config/routing";
import { useNavigate, useSearchParams } from "@/services/Routing";

import { IRedirectProps } from "./Redirect.types";

const Redirect: React.FC<IRedirectProps> = ({
  path = SITE_PATHS.HOME_PAGE,
  replace,
  keepSearch,
}) => {
  const { navigate } = useNavigate();
  const { searchParams } = useSearchParams();

  useEffect(() => {
    if (path) {
      setTimeout(() => {
        let pathname = path;
        if (keepSearch) {
          const search = searchParams
            .toString()
            .replaceAll("%3F", "?")
            .replaceAll("&?", "&");

          pathname += `?${search}`;
        }

        navigate(pathname, replace);
      }, 100);
    }
  }, [path, replace, keepSearch, searchParams, navigate]);

  return null;
};

export default Redirect;
