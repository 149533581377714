import React, { useMemo } from "react";

import Icon from "@/components/misc/Icon";
import PopoverButton, {
  usePopoverState,
} from "@/components/misc/PopoverButton";
import { formatBytes } from "@/utils/files";
import { usePreviewFile } from "@/services/MediaService";
import Button from "@/components/input/Button";

import {
  StyledButton,
  StyledCard,
  StyledCompactCard,
  StyledDangerButton,
  StyledIconContainer,
  StyledMenu,
  StyledMiddleContainer,
  StyledPrimaryText,
  StyledSecondaryText,
} from "./FileCard.styles";
import { IFileCardProps } from "./FileCard.types";
import { getFileConfig } from "./FileCard.utils";
import { DEFAULT_CONFIG } from "./FileCard.config";

const FileCard: React.FC<IFileCardProps> = ({
  name,
  sizeInBytes,
  fileUrl,
  className,
  deleteButtonProps,
  file,
  variantCompact,
  allowDownload,
}) => {
  const config = useMemo(() => getFileConfig(name) || DEFAULT_CONFIG, [name]);
  const menuState = usePopoverState();

  const fileSize = useMemo(() => formatBytes(sizeInBytes), [sizeInBytes]);

  const {
    preview,
    filePreviewJsx,
    fileUrl: previewFileUrl,
    fileExtention,
  } = usePreviewFile({ fileUrl, file, allowDownload });

  const {
    styles: { bgColor, iconBgColor, iconUrl },
  } = config;
  const isDocFile = ["pdf", "docx"].includes(fileExtention.toLowerCase());

  if (variantCompact) {
    return (
      <>
        {filePreviewJsx}

        <StyledCompactCard className={className}>
          <div className="image-container" onClick={preview}>
            <img
              className={isDocFile ? "icon" : "image"}
              src={isDocFile ? iconUrl : previewFileUrl}
              alt=""
            />
          </div>

          <div className="content" onClick={preview}>
            {name}
          </div>
          {!!deleteButtonProps && (
            <Button {...deleteButtonProps} className="button" variant="ghost">
              <Icon isSrcRelative src="cross.svg" size="xxs" />
            </Button>
          )}
        </StyledCompactCard>
      </>
    );
  }

  return (
    <>
      {filePreviewJsx}

      <StyledCard className={className} style={{ background: bgColor }}>
        <StyledIconContainer
          style={{ background: iconBgColor }}
          onClick={preview}
        >
          <img src={iconUrl} alt="" width={16} height={16} />
        </StyledIconContainer>

        <StyledMiddleContainer onClick={preview}>
          <StyledPrimaryText>{name}</StyledPrimaryText>
          <StyledSecondaryText className="mt-2">{fileSize}</StyledSecondaryText>
        </StyledMiddleContainer>

        <PopoverButton
          state={menuState}
          buttonProps={{
            variant: "ghost",
            children: <Icon isSrcRelative src="menu.svg" size="sm" />,
          }}
          popoverProps={{
            placement: "bottom right",
            crossOffset: 16,
            children: (
              <StyledMenu>
                <StyledButton
                  className="w-100"
                  variant="ghost"
                  onClick={() => {
                    menuState.close();
                    preview();
                  }}
                >
                  <Icon isSrcRelative src="eyes.svg" size="xs" />
                  <span>Preview</span>
                </StyledButton>
                {!!deleteButtonProps && (
                  <StyledDangerButton
                    className="w-100"
                    variant="ghost"
                    {...deleteButtonProps}
                    onClick={(e) => {
                      if (deleteButtonProps && deleteButtonProps.onClick) {
                        deleteButtonProps.onClick(e);
                      }

                      menuState.close();
                    }}
                  >
                    <Icon isSrcRelative src="dustbin.svg" size="xs" />
                    <span>Delete</span>
                  </StyledDangerButton>
                )}
              </StyledMenu>
            ),
          }}
        />
      </StyledCard>
    </>
  );
};

export default FileCard;
