import React from "react";

import { useResponsive } from "@/styles";
import { usePwaInstall } from "@/services/Pwa";
import { PendingContractorOnboardingModal } from "@/features/contracts/PendingContractorOnboardingPrompt";

import HomePageMobile from "./HomePage.mobile";
import HomePageDesktop from "./HomePage.desktop";

const HomePage: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { jsx } = usePwaInstall();

  return (
    <>
      {jsx}
      {isScreenSmallerThanTablet ? <HomePageMobile /> : <HomePageDesktop />}
      <PendingContractorOnboardingModal />
    </>
  );
};

export default HomePage;
