import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getUserFullName, UserDetails } from "@/services/UserService";
import { ContractCreatorType } from "@/services/ContractsService";

import { AppState } from "../index";
import { analytics, errorReporting } from "@/config/services";

export interface AuthState {
  userData: UserDetails | null;
  creatorType: ContractCreatorType;
}

const initialState: AuthState = {
  userData: null,
  creatorType: ContractCreatorType.Payee,
};

export const authSlice = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserDetails | null>) => {
      state.userData = action.payload;

      if (action.payload) {
        const { email, id } = action.payload;
        const username = getUserFullName(action.payload);

        errorReporting.setUser({
          email,
          id,
          username,
        });

        analytics.setUser({
          email,
          id,
          username,
        });
      }
    },

    setUserDataPartial: (
      state,
      action: PayloadAction<Partial<UserDetails>>
    ) => {
      state.userData = {
        ...(state.userData || {}),
        ...action.payload,
      } as UserDetails;
    },

    setCreatorType: (state, action: PayloadAction<ContractCreatorType>) => {
      state.creatorType = action.payload;
    },
  },
});

export const selectUserData = (state: AppState) => state.auth.userData;
export const selectCreatorType = (state: AppState) => state.auth.creatorType;

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
