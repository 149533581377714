import React, { useEffect } from "react";

import SplashScreen from "@/features/pages/app/SplashScreen";
import { useAuth } from "@/services/Authentication";
import Redirect from "@/components/navigation/Redirect";
import { SITE_PATHS } from "@/config/routing";
import { useNavigate } from "@/services/Routing";

const AuthRedirectPage: React.FC = () => {
  const { isAuthenticated, ...rest } = useAuth();
  const { navigate } = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      return navigate(SITE_PATHS.HOME_PAGE);
    }
  }, [isAuthenticated, navigate, rest]);

  if (isAuthenticated) {
    return <Redirect path={SITE_PATHS.HOME_PAGE} />;
  }

  return <SplashScreen />;
};

export default AuthRedirectPage;
