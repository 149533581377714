import { useCallback } from "react";
import {
  useLocation,
  useNavigate as useReactRouterNavigate,
} from "react-router-dom";

import { SITE_PATHS } from "@/config/routing";

function useNavigate() {
  const reactRouterNavigate = useReactRouterNavigate();
  const location = useLocation();

  const currentUrl = `${location.pathname}${location.search}`;

  const navigate = useCallback(
    (pathname: string, replace = false) => {
      const currentOrigin = window.location.origin;
      const isRelativePath = pathname.startsWith("/");

      // Avoiding pushing same url in history stack
      if (currentUrl === pathname) {
        return;
      }

      if (isRelativePath) {
        reactRouterNavigate(pathname, { replace });
      }

      try {
        let newUrl = new URL(pathname);
        const isExternalSite = currentOrigin !== newUrl.origin;

        if (isExternalSite) {
          window.location.replace(pathname);
        } else {
          const url = `${newUrl.pathname}${newUrl.search}${newUrl.hash}`;
          reactRouterNavigate(url, { replace });
        }
      } catch {}
    },
    [reactRouterNavigate, currentUrl]
  );

  const doesAnyHistoryEntryExist = location.key !== "default";
  const back = useCallback(() => {
    if (doesAnyHistoryEntryExist) {
      reactRouterNavigate(-1);
    } else {
      navigate(SITE_PATHS.HOME_PAGE);
    }
  }, [reactRouterNavigate, navigate, doesAnyHistoryEntryExist]);

  const forward = useCallback(() => {
    reactRouterNavigate(1);
  }, [reactRouterNavigate]);

  return {
    navigate,
    back,
    forward,
  };
}

export default useNavigate;
