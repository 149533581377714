import lodashGet from "lodash/get";

import { SITE_PATHS } from "@/config/routing";
import Share, { ShareContent } from "@/services/Share";
import { SystemMessage } from "./ChatService.types";

export function getChatRoomPageUrl(contractId: string) {
  return `${SITE_PATHS.CHAT_PAGE}?id=${contractId}`;
}

//--------------------

export function getOfferShareContent(): ShareContent {
  return {
    title: "Come chat with me on Paypipe.",
    url: Share.getShareUrl(""),
    text: `Chat with me on Paypipe to discuss this job and track my progress.\n\n Paypipe provides secure and safe transactions globally between freelancers and their clients`,
  };
}

export const USER_CHANNEL_REGEX = /messaging:([0-9]{3}_[0-9]{3})/g;
export const USER_CHANNEL_SHORT_REGEX = /([0-9]{3}_[0-9]{3})/g;
export function isUserChannel({ channelId = "" }: { channelId?: string }) {
  return (
    !!channelId.match(USER_CHANNEL_SHORT_REGEX) ||
    !!channelId.match(USER_CHANNEL_REGEX) ||
    false
  );
}

export function checkIsSystemMessage({ text }: { text: string }): {
  isSystemMessage: boolean;
  messageData: SystemMessage | null;
} {
  try {
    const regexRes = /SYSTEM_(.*)_SYSTEM/.exec(text);
    const stringifiedJson = lodashGet(regexRes, "[1]");

    if (!stringifiedJson) {
      return {
        isSystemMessage: false,
        messageData: null,
      };
    }
    
    const messageData = JSON.parse(stringifiedJson) as SystemMessage;

    if (!messageData.type) {
      return {
        isSystemMessage: false,
        messageData: null,
      };
    }

    return {
      isSystemMessage: true,
      messageData,
    };
  } catch {
    return {
      isSystemMessage: false,
      messageData: null,
    };
  }
}