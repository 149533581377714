"use client";

import React from "react";
import { Provider as StoreProvider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { PostHogProvider } from "posthog-js/react";

import { AuthProvider } from "@/services/Authentication";
import { store } from "@/services/Store";
import { ResponsiveProvider } from "@/styles";
import { ChatServiceProvider } from "@/services/ChatService";
import { ChatServiceProvider as ChatServiceProvideNew } from "@/services/ChatServiceNew";
import { ContractsServiceProvider } from "@/services/ContractsService";
import { ThemeManagerProvider } from "@/styles/ThemeManager";
import { PwaProvider } from "@/services/Pwa";
import { ToastProvider } from "@/components/misc/Toast";
import { errorReporting } from "@/config/services";

const Providers: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <PostHogProvider client={errorReporting.posthogClient || undefined}>
      <HelmetProvider>
        <PwaProvider>
          <ResponsiveProvider>
            <ThemeManagerProvider>
              <StoreProvider store={store}>
                <ToastProvider>
                  <AuthProvider>
                    <ChatServiceProvider>
                      <ChatServiceProvideNew>
                        <ContractsServiceProvider>
                          {children}
                        </ContractsServiceProvider>
                      </ChatServiceProvideNew>
                    </ChatServiceProvider>
                  </AuthProvider>
                </ToastProvider>
              </StoreProvider>
            </ThemeManagerProvider>
          </ResponsiveProvider>
        </PwaProvider>
      </HelmetProvider>
    </PostHogProvider>
  );
};

export default Providers;
