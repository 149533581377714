"use client";

import React, { useCallback, useMemo, useState } from "react";

import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { SITE_PATHS } from "@/config/routing";
import {
  PayoutStatus,
  TranscationType,
  useTransactions,
} from "@/services/UserService";
import { useResponsive } from "@/styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import Tabs from "@/components/navigation/Tabs";
import { Heading } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import SplashScreen from "@/features/pages/app/SplashScreen";
import PendingContractorOnboardingPrompt from "@/features/contracts/PendingContractorOnboardingPrompt";

import {
  StyledCardsContainer,
  StyledContentSection,
  StyledHeader,
  StyledNoData,
  StyledPage,
  SwitchSection,
} from "./TransactionsPage.styles";
import TransactionCard from "./components/TransactionCard";
import BalanceCard from "./components/BalanceCard";

export const TABS = [
  {
    id: TranscationType.Payment,
    title: "Payouts",
  },
  {
    id: TranscationType.Spending,
    title: "Spending",
  },
];

const TransactionsPage: React.FC = () => {
  const { data: transactionFromHook, isLoading: isLoadingTransactions } =
    useTransactions({});
  const { isScreenSmallerThanTablet } = useResponsive();

  const [activeSection, setActiveSection] = useState(TranscationType.Payment);
  const [activePayoutSection, setActivePayoutSection] = useState(
    PayoutStatus.DEFAULT
  );

  const transactions = useMemo(() => {
    return transactionFromHook
      .filter((t) => t.type === activeSection)
      .filter((t) => {
        if (activeSection !== TranscationType.Payment) {
          return true;
        }

        if (activePayoutSection === PayoutStatus.DEPOSITED) {
          return t.status === PayoutStatus.DEPOSITED;
        }

        return t.status !== PayoutStatus.DEPOSITED;
      });
  }, [transactionFromHook, activeSection, activePayoutSection]);

  const togglePayoutSection = useCallback(() => {
    setActivePayoutSection((current) =>
      current === PayoutStatus.DEFAULT
        ? PayoutStatus.DEPOSITED
        : PayoutStatus.DEFAULT
    );
  }, []);

  const { sectionTitle, balanceCardProps } = useMemo(() => {
    let sectionTitle = "";
    let title = "";
    let toggleButtonProps: any;

    if (activeSection === TranscationType.Payment) {
      sectionTitle = "Payouts";

      title =
        activePayoutSection === PayoutStatus.DEFAULT
          ? "Pending Payments"
          : "Completed Payouts";

      toggleButtonProps = {
        onClick: togglePayoutSection,
        children:
          activePayoutSection === PayoutStatus.DEFAULT ? (
            <>
              <Icon isSrcRelative src="switch.svg" size="xs" /> Switch to
              completed payments
            </>
          ) : (
            <>
              <Icon isSrcRelative src="switch.svg" size="xs" /> Switch to
              pending payments
            </>
          ),
      };
    } else if (activeSection === TranscationType.Spending) {
      sectionTitle = "Spending";
      title = "Spending till date";
    }

    const total = transactions.reduce((sum, t) => sum + t.amount, 0);
    return {
      sectionTitle,
      balanceCardProps: {
        title,
        value: total ? `$${total / 100}` : "0",
        toggleButtonProps,
      },
    };
  }, [activeSection, activePayoutSection, transactions, togglePayoutSection]);

  const noData = !isLoadingTransactions && !transactions.length;

  const pageJsx = (
    <StyledPage>
      <SwitchSection>
        <Tabs
          activeTabId={activeSection}
          onChange={({ id }) => setActiveSection(id as TranscationType)}
          tabs={TABS}
        />

        {!isScreenSmallerThanTablet && (
          <BalanceCard className="mt-3" {...balanceCardProps} />
        )}
      </SwitchSection>

      <StyledContentSection>
        <div className="container">
          {isScreenSmallerThanTablet && <BalanceCard {...balanceCardProps} />}

          <StyledHeader>
            <Heading size="md">{sectionTitle}</Heading>
          </StyledHeader>

          {noData ? (
            <StyledNoData>
              <Icon isSrcRelative src="info_3.svg" size="sm" />
              {activeSection === TranscationType.Payment && (
                <>
                  You have no payouts in process, as soon as you complete your
                  first offer on Paypipe your payouts will start showing here.
                </>
              )}
              {activeSection === TranscationType.Spending && (
                <>
                  You have no spending show here, your spending history will
                  start showing here when you spend for the first time.
                </>
              )}
            </StyledNoData>
          ) : (
            <StyledCardsContainer>
              <PendingContractorOnboardingPrompt className="mb-4" />
              {transactions.map((t) => (
                <TransactionCard key={t.id} transactionId={t.id} />
              ))}
            </StyledCardsContainer>
          )}
        </div>
      </StyledContentSection>
    </StyledPage>
  );

  if (isLoadingTransactions) {
    return <SplashScreen />;
  }

  if (isScreenSmallerThanTablet) {
    return (
      <PageWithHeaderLayout
        stickyHeader
        headerProps={{
          backButtonProps: { link: SITE_PATHS.SETTINGS_PAGE },
          titleProps: { children: "Transactions" },
        }}
      >
        {pageJsx}
      </PageWithHeaderLayout>
    );
  }

  return (
    <DesktopPrimaryLayout headerProps={{ logo: { component: "Wallet" } }}>
      {pageJsx}
    </DesktopPrimaryLayout>
  );
};

export default TransactionsPage;
