import React, { createContext, useCallback, useContext, useState } from "react";

import Modal, { useModalState } from "@/components/misc/Modal";

import { IContractsServiceContext } from "./ContractsService.types";
import styled from "@emotion/styled";
import { Body, Heading } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import Button from "@/components/input/Button";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import OnboardStripeButton from "@/features/user/OnboardStripeButton";

const ContractsServiceContext = createContext({} as IContractsServiceContext);

export const StyledContainer = styled.div`
  position: relative;
  text-align: center;
  padding-top: 2.5rem;

  .icon {
    position: absolute;
    top: 0;
    left: -0.25rem;
    overflow: hidden;
  }

  .close-button {
    position: absolute;
    top: -0.15rem;
    right: -0.5rem;
  }
`;

export const StyledHeading = styled(Heading)`
  color: #4e5876;
`;

export const StyledContent = styled(Body)`
  color: #4e5876;
`;

export const StyledContentSmall = styled(StyledContent)`
  color: #4e5876;
`;

export const useContractsService = () => useContext(ContractsServiceContext);

export const ContractsServiceProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [offerRecipientEmail, setOfferRecipientEmail] = useState("");
  const contractCreateSuccessModalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setOfferRecipientEmail("");
      }
    },
  });
  const onboardModalState = useModalState();
  const [clientName, setClientName] = useState("");

  //--------------------------

  const showOfferShareEmailModal = useCallback(
    ({ recipientEmail }: { recipientEmail: string }) => {
      setOfferRecipientEmail(recipientEmail);
      contractCreateSuccessModalState.open();
    },
    [contractCreateSuccessModalState]
  );

  const showContractorOnboardModal = useCallback(
    ({ clientName }: { clientName: string }) => {
      setClientName(clientName);
      onboardModalState.open();
    },
    [onboardModalState]
  );

  //--------------------------

  const contractCreateSuccessModalJsx = (
    <Modal
      state={contractCreateSuccessModalState}
      width="410px"
      className="p-3"
    >
      <StyledContainer>
        <Icon
          isSrcRelative
          customSize="2.5rem"
          className="icon"
          src="toast_success.svg"
        />
        <Button
          className="close-button"
          variant="ghost"
          onClick={contractCreateSuccessModalState.close}
        >
          <Icon isSrcRelative size="xs" src="cross.svg" />
        </Button>

        <Heading size="lg">Offer sent to</Heading>
        <StyledContent size="lg" className="mt-2">
          {offerRecipientEmail}
        </StyledContent>
        <StyledContentSmall size="md" className="mt-2">
          Please remind your clients to check their spam or promotions folder if
          they don't see our email in their inbox.
        </StyledContentSmall>
      </StyledContainer>
    </Modal>
  );

  const onboardPromptModalJsx = (
    <Modal state={onboardModalState} width="360px">
      <ImageTitleContent
        image="/assets/images/pages/contracts/onboard.svg"
        title="Payment release requested"
        content={`Payment release request was sent to ${
          clientName || "client"
        }. However, the released funds will only be made accessible to you after verifying your account.`}
      />

      <OnboardStripeButton
        className="w-100 mt-4"
        onClick={() => {
          setTimeout(() => {
            onboardModalState.close();
          }, 4000);
        }}
      >
        Verify my account
      </OnboardStripeButton>
    </Modal>
  );

  return (
    <ContractsServiceContext.Provider
      value={{ showOfferShareEmailModal, showContractorOnboardModal }}
    >
      {children}
      {contractCreateSuccessModalJsx}
      {onboardPromptModalJsx}
    </ContractsServiceContext.Provider>
  );
};
