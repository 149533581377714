import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { Body, Display } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import { useAuth } from "@/services/Authentication";
import { appFeatures } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import PayoutOnboarding from "@/features/user/PayoutOnboarding";
import { useResponsive } from "@/styles";

import { OfferInputType } from "../AiOfferForm";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  padding-inline: 1rem;
`;

const StyledMainContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledActionContentContainer = styled.div`
  flex-grow: 1;
  max-width: 15rem;
  margin-inline: auto;
`;

const StyledButton = styled(Button)`
  color: #fff;
  text-align: left;

  & > *:nth-child(2) {
    flex-grow: 1;
  }
`;

const StyledIllustration = styled.img`
  display: block;
  margin-inline: auto;
  max-width: 100%;
  max-height: 14rem;
`;

const InputTypeSelectionScreen: React.FC<{
  openInputTypeScreen: (value: OfferInputType) => void;
  promptOnboard?: boolean;
  customOnboardingJsx?: React.ReactNode;
}> = ({ openInputTypeScreen, promptOnboard, customOnboardingJsx }) => {
  const { userData } = useAuth();
  const { isScreenSmallerThanTablet } = useResponsive();
  const username = userData?.given_name;

  const onboardModalState = useModalState();

  const heading = `Hi${username ? ` ${username}` : ""}, I'm Penny`;

  return (
    <>
      <Modal
        showCloseButton
        state={onboardModalState}
        width="720px"
        mobileProps={{ fullscreen: true }}
        innerContainerProps={{
          style: {
            height: "100%",
          },
        }}
        contentContainerProps={{
          style: {
            height: isScreenSmallerThanTablet ? "100%" : "",
            padding: isScreenSmallerThanTablet ? "0" : undefined,
            paddingTop: "1.75rem",
          },
        }}
      >
        {customOnboardingJsx || <PayoutOnboarding />}
      </Modal>

      <StyledContainer>
        <StyledMainContentContainer>
          <div>
            <StyledIllustration
              alt="Illustration"
              src="/assets/images/branding/penny/illustration.svg"
            />
            <Display size="lg">{heading}</Display>
            <Body
              size="md"
              className="mt-3"
              style={{
                maxWidth: "56ch",
                marginInline: "auto",
              }}
            >
              I'm here to help you create offers faster. Share a conversation
              you had with another party, upload an agreement or simply describe
              the job to me and I'll turn it into an offer ready to send.
            </Body>
          </div>
        </StyledMainContentContainer>

        <StyledActionContentContainer className="mt-5">
          <Body size="lg" style={{ fontWeight: 600 }}>
            Choose how to share with me
          </Body>

          <StyledButton
            className="w-100 mt-3"
            variant="ghost"
            onClick={() => {
              if (promptOnboard) {
                onboardModalState.open();
              } else {
                openInputTypeScreen("text");
              }
            }}
          >
            <Icon src="/assets/images/branding/penny/copy.svg" size="xs" />
            <span>Paste conversation</span>{" "}
            <Icon isSrcRelative src="arrow_right.svg" customSize="0.875rem" />
          </StyledButton>

          {appFeatures.isSupported("CONTRACT.CREATE_WITH_AI_WITH_DOCUMENT") && (
            <StyledButton
              className="w-100 mt-1"
              variant="ghost"
              onClick={() => {
                if (promptOnboard) {
                  onboardModalState.open();
                } else {
                  openInputTypeScreen("document");
                }
              }}
            >
              <Icon src="/assets/images/branding/penny/upload.svg" size="xs" />
              <span>Upload document</span>
              <Icon isSrcRelative src="arrow_right.svg" customSize="0.875rem" />
            </StyledButton>
          )}

          <StyledButton
            className="w-100 mt-1"
            variant="ghost"
            onClick={() => {
              if (promptOnboard) {
                onboardModalState.open();
              } else {
                openInputTypeScreen("description");
              }
            }}
          >
            <Icon
              src="/assets/images/branding/penny/chat_bubble.svg"
              size="xs"
            />
            <span>Tell me about the job</span>
            <Icon isSrcRelative src="arrow_right.svg" customSize="0.875rem" />
          </StyledButton>
        </StyledActionContentContainer>
      </StyledContainer>
    </>
  );
};

export default InputTypeSelectionScreen;
