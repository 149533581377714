import { ExperienceLevel, SocialLinkConfig } from "./UserService.types";

export const EXPERIENCE_LEVEL_CONFIG: Record<
  ExperienceLevel,
  { info: { name: string } }
> = {
  [ExperienceLevel.EntryLevel]: {
    info: {
      name: "Entry Level",
    },
  },
  [ExperienceLevel.Intermediate]: {
    info: {
      name: "Intermediate",
    },
  },
  [ExperienceLevel.Expert]: {
    info: {
      name: "Expert",
    },
  },
};

export const USE_LOCAL_STORAGE = true;

export const PREDEFNED_PREFIX = "predefined_";

export const SOCIAL_LINKS: SocialLinkConfig[] = [
  {
    platformName: `${PREDEFNED_PREFIX}site`,
    displayName: "Personal website",
    icon: "browser.svg",
    showInPlaceholder: true,
    placeholderOrder: 1,
  },
  {
    platformName: `${PREDEFNED_PREFIX}X`,
    displayName: "X [Formly twitter]",
    icon: "social/twitter_x.svg",
    showInPlaceholder: true,
    placeholderOrder: 6,
    prefix: "https://twitter.com/",
  },
  {
    platformName: `${PREDEFNED_PREFIX}Behance`,
    icon: "social/behance.svg",
    showInPlaceholder: true,
    placeholderOrder: 2,
    prefix: "https://www.behance.net/",
  },
  {
    platformName: `${PREDEFNED_PREFIX}Dribbble`,
    icon: "social/dribble.svg",
    showInPlaceholder: true,
    placeholderOrder: 5,
    prefix: "https://dribbble.com/",
  },
  {
    platformName: `${PREDEFNED_PREFIX}Linkedin`,
    icon: "social/linkedin.svg",
    showInPlaceholder: true,
    placeholderOrder: 3,
    prefix: "https://www.linkedin.com/in/",
  },
  {
    platformName: `${PREDEFNED_PREFIX}Instagram`,
    icon: "social/instagram.svg",
    showInPlaceholder: true,
    placeholderOrder: 4,
    prefix: "https://www.instagram.com/",
  },
  {
    platformName: `${PREDEFNED_PREFIX}Facebook`,
    icon: "social/facebook.svg",
    prefix: "https://www.facebook.com/",
  },
  {
    platformName: `${PREDEFNED_PREFIX}TikTok`,
    icon: "social/tiktok.svg",
    prefix: "https://www.tiktok.com/",
  },
  {
    platformName: `${PREDEFNED_PREFIX}YouTube`,
    icon: "social/youtube.svg",
    prefix: "https://www.youtube.com/",
  },
].map((item) => ({
  ...item,
  displayName: item.displayName || item.platformName.split(PREDEFNED_PREFIX)[1],
}));

export const SOCIAL_LINKS_MAP: Record<string, SocialLinkConfig> =
  SOCIAL_LINKS.reduce(
    (map, item) => ({ ...map, [item.platformName]: item }),
    {}
  );

export const PLACEHOLDER_ICONS = SOCIAL_LINKS.filter(
  (item) => item.showInPlaceholder
)
  .sort((a, b) => (a.placeholderOrder || 0) - (b.placeholderOrder || 0))
  .map((item) => ({
    label: item.platformName.split(PREDEFNED_PREFIX)[1],
    icon: item.icon,
  }));

export const MAX_NON_PREMIUM_USER_PACKAGE_COUNT = 2;
export const MAX_NON_PREMIUM_USER_PORTFOLIO_COUNT = 4;

export const STRICT_ONBOARD_COUNTRIES = ["PK", "NG"];
