import React, { useMemo } from "react";
import min from "lodash/min";
import Stripe from "stripe";

import ShowMore from "@/components/input/ShowMore";
import { formatDate } from "@/utils/date";
import {
  isMilestoneFunded,
  useContractDetails,
} from "@/services/ContractsService";
import Wysiwyg from "@/components/input/LexicalWysiwyg";
import { useAuth } from "@/services/Authentication";
import DeleteMilestoneButton from "@/features/contracts/DeleteMilestoneButton";
import EditMilestoneButton from "@/features/contracts/EditMilestoneButton";
import LaunchMilestoneButton from "@/features/contracts/LaunchMilestoneButton";
import Icon from "@/components/misc/Icon";

import {
  StyledInputsContainer,
  StyledLabel,
  StyledFieldContainer,
  StyledValue,
  StyledValueBold,
  StyledValueLight,
  StyledTopContainer,
  StyledFieldBGContainer,
  StyledFlexContainer,
  StyledActionsContainer,
} from "../MilestonesDisplay.styles";
import { Milestone } from "../MilestonesDisplay.types";

const MilestoneCard: React.FC<{
  milestone: Milestone;
  className?: string;
}> = ({ milestone, className }) => {
  const {
    name,
    description,
    value,
    end_date,
    contract_id: contractId,
    created_at: milestoneCreationDate,
    owner_id,
    is_draft,
  } = milestone;

  const { loggedInUserId } = useAuth();

  const amIOwner = loggedInUserId === owner_id;

  const { contractDetails } = useContractDetails({
    contractId,
    skipFetchIfExists: true,
  });

  const dueDateFormatted = useMemo(
    () => (end_date ? formatDate(end_date) : ""),
    [end_date]
  );

  const publishDate = useMemo(() => {
    if (!contractDetails?.history?.length) {
      return null;
    }

    const publishLog = contractDetails.history.find(
      (log) =>
        log.action === "ContractMilestonePublished" &&
        log.contract_milestone_id === milestone.id
    );

    if (!publishLog) {
      return null;
    }

    return publishLog.created_at;
  }, [milestone.id, contractDetails?.history]);

  const isFunded = useMemo(() => isMilestoneFunded(milestone), [milestone]);

  const isLaterAddedMilestone = useMemo(() => {
    if (publishDate) {
      return true;
    }

    if (!contractDetails?.history?.length) {
      return false;
    }

    // This is temp logic to determine that milestone is created from managment screen.
    const successfulfundingDates = contractDetails.history
      .filter((log) => log.action === "StripeAction")
      .map((log): { checkoutSession: Stripe.Checkout.Session } => {
        try {
          const checkoutSession: Stripe.Checkout.Session = JSON.parse(log.data);

          return {
            checkoutSession,
          };
        } catch {
          return null as any;
        }
      })
      .filter(Boolean)
      .filter(({ checkoutSession }) => checkoutSession.status === "complete")
      .map(({ checkoutSession }) => new Date(checkoutSession.created * 1000));
    const oldestFundingDate = min(successfulfundingDates);

    if (!oldestFundingDate) {
      return false;
    }

    const isAddedAfterFunding =
      +new Date(oldestFundingDate) < +new Date(milestoneCreationDate);

    return isAddedAfterFunding;
  }, [milestoneCreationDate, contractDetails?.history, publishDate]);

  const creationDateFormatted = useMemo(
    () => (milestoneCreationDate ? formatDate(milestoneCreationDate) : ""),
    [milestoneCreationDate]
  );

  const publishDateFormatted = useMemo(
    () => (publishDate ? formatDate(publishDate) : ""),
    [publishDate]
  );

  const showDeleteButton = !isFunded && amIOwner;
  const showEditButton = is_draft && !isFunded && amIOwner;
  const showPublishButton = is_draft && !isFunded && amIOwner;
  const showActionSection =
    showDeleteButton || showEditButton || showPublishButton;

  const displayDateLabel = isLaterAddedMilestone ? "Published" : "Added";
  const displayDateFormatted =
    (isLaterAddedMilestone ? publishDateFormatted : creationDateFormatted) ||
    creationDateFormatted;

  //----------------------

  return (
    <StyledInputsContainer $showCounter className={className}>
      <StyledTopContainer>
        <StyledFlexContainer>
          <StyledLabel style={{ flexGrow: "1" }}>Milestone Name</StyledLabel>
          {!!dueDateFormatted && (
            <StyledFlexContainer
              style={{
                gap: "0.5rem",
              }}
            >
              <StyledLabel>Due :</StyledLabel>
              <StyledValueLight>{dueDateFormatted}</StyledValueLight>
            </StyledFlexContainer>
          )}
        </StyledFlexContainer>

        <StyledFlexContainer className="mt-1">
          <StyledValue>{name}</StyledValue>
          <StyledValueBold>${value}</StyledValueBold>
        </StyledFlexContainer>
      </StyledTopContainer>

      <StyledFieldBGContainer className="mt-2">
        <StyledLabel>Description</StyledLabel>
        <StyledValueLight className="mt-2">
          <ShowMore showMoreOnContentClick maxHeight="2.5rem">
            <Wysiwyg
              unstyled
              readOnly
              showFullContent
              value={{ markdown: description }}
              style={{ minHeight: "20rem" }}
            />
          </ShowMore>
        </StyledValueLight>
      </StyledFieldBGContainer>

      {(!!displayDateFormatted || showActionSection) && (
        <StyledFlexContainer className="mt-2">
          {!!displayDateFormatted && (
            <StyledFieldContainer
              style={{
                gap: "0.5rem",
              }}
            >
              <StyledLabel>{displayDateLabel} :</StyledLabel>
              <StyledValueLight>{displayDateFormatted}</StyledValueLight>
            </StyledFieldContainer>
          )}

          {showActionSection && (
            <StyledActionsContainer>
              {showEditButton && (
                <EditMilestoneButton
                  contractId={contractId}
                  milestoneId={milestone.id}
                  variant="ghost"
                  style={{
                    padding: "0.125rem 0.5rem",
                  }}
                >
                  <Icon
                    isSrcRelative
                    src="edit_2.svg"
                    colorVariant="black"
                    size="xxs"
                  />
                </EditMilestoneButton>
              )}

              {showDeleteButton && (
                <DeleteMilestoneButton
                  contractId={contractId}
                  milestoneId={milestone.id}
                  variant="ghost"
                  style={{
                    marginRight: "0.45rem",
                    padding: "0.125rem 0.5rem",
                  }}
                >
                  <Icon
                    isSrcRelative
                    src="delete.svg"
                    colorVariant="black"
                    size="xs"
                  />
                </DeleteMilestoneButton>
              )}

              {showPublishButton && (
                <LaunchMilestoneButton
                  contractId={contractId}
                  milestoneId={milestone.id}
                  style={{
                    padding: "0.125rem 0.35rem",
                    borderRadius: "0.25rem",
                    fontSize: "0.875rem",
                  }}
                >
                  Publish
                </LaunchMilestoneButton>
              )}
            </StyledActionsContainer>
          )}
        </StyledFlexContainer>
      )}
    </StyledInputsContainer>
  );
};

export default MilestoneCard;
