import { IS_STAGING_ENV } from "@/config/app";

import { FeatureName, FeatureSetting } from "./AppFeatures.types";

export const FEATURE_FLAGS: Partial<Record<FeatureName, FeatureSetting>> = {
  "DESKTOP_LAYOUT.CUSTOMER_SERVICE_CARD": { supported: true },
  //-------------------
  "DASHBOARD.CONTRACTS_TABLE": { supportedOnDesktop: true },
  "DASHBOARD.CONTRACT_MANAGEMENT_CARD": { supported: true },
  //-------------------
  "ACCOUNT.EDIT_LANGUAGES": { supported: true },
  "ACCOUNT.USE_CLIENT_TIMEZONE_AS_DEFAULT": { supported: true },
  "ACCOUNT.PORTFOLIOS": { supported: IS_STAGING_ENV },
  "ACCOUNT.REVIEWS": { supported: false },
  "ACCOUNT.PROFESSIONS": { supported: false },
  "ACCOUNT.EXPERIENCE": { supported: false },
  "ACCOUNT.PACKAGES": { supported: IS_STAGING_ENV },
  "ACCOUNT.SHOW_NEW_PAYPIPE_ID_EDIT_PAGE": { supported: IS_STAGING_ENV },
  //-------------------
  "CUSTOMIZATION.DARK_MODE": { supported: false },
  //-------------------
  "OFFER.FORM.CLIENT": {
    supported: false,
  },
  //-------------------
  "NOTIFICATION.PUSH_NOTIFICATIONS": {
    supported: true,
  },
  //-------------------
  "CONTRACT.REJECT": {
    supported: false,
  },
  "CONTRACT.HIRE_SP_AGAIN": {
    supported: false,
  },
  "CONTRACT.AUTHENTICATE_BEFORE_OFFER_ACCEPTANCE": {
    supported: true,
  },
  "CONTRACT.PREFILL_PAYMENT_PLATFORM_EMAIL": {
    supported: true,
  },
  "CONTRACT.IMPLICIT_START_MILESTONE": {
    supported: true,
  },
  "CONTRACT.UPDATE_OFFER": {
    supported: true,
  },
  "CONTRACT.DELETE_OFFER": {
    supported: true,
  },
  "CONTRACT.SHOW_404_PAGE": {
    supported: true,
  },
  "CONTRACT.DELETE_JOB_MILESTONE": {
    supported: true,
  },
  "CONTRACT.UPDATE_JOB_MILESTONE": {
    supported: true,
  },
  "CONTRACT.ADD_JOB_MILESTONE": {
    supported: true,
  },
  "CONTRACT.WAIT_TIME_FOR_SECOND_CONTRACT": {
    supported: true,
  },
  "CONTRACT.CREATE_WITH_AI": {
    supported: true,
  },
  "CONTRACT.SHOW_CREATE_DRAWER": {
    supported: false,
  },
  "CONTRACT.CREATE_WITH_AI_WITH_DOCUMENT": {
    supported: true,
  },
  "CONTRACT.ALLOW_BEFORE_PAYEE_ONBOARDING": {
    supported: true,
  },
  "CONTRACT.SHOW_CREATE_CONTRACT_DRAWER": {
    supported: false,
  },
  "CONTRACT.ALLOW_RELEASE_PAYMENT_WITHOUT_WAIT": {
    supported: IS_STAGING_ENV,
  },
  //-------------------
  "CHAT.USE_STREAMIO": {
    supported: true,
  },
  "CHAT.CONTACT_USER": {
    supported: IS_STAGING_ENV,
  },
  //-------------------
  SUBSCRIPTION: {
    supported: IS_STAGING_ENV,
  },
};
