import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";

const StyledContainer = styled.div`
  ${squareSizing("10rem")};
  position: relative;
  border-radius: 10rem;
`;

const StyledInnerCircle = styled.div`
  ${squareSizing("12rem")};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  border-radius: 10rem;
  background-color: #f0f0ff;
  box-shadow: 1.46px 5.83px 5.83px 0px rgba(69, 211, 243, 0.25) inset,
    0px 5.83px 85.77px 0px rgba(0, 178, 255, 0.2);
  z-index: 1;
  animation: spin 1s linear forwards infinite;

  @keyframes spin {
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

const StyledLogo = styled.img`
  ${squareSizing("7rem")};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  margin-inline: auto;
  z-index: 2;
  border-radius: 10rem;
`;

const StyledLoaderGif = styled.img`
  ${squareSizing("15rem")};
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  margin-inline: auto;
  z-index: -1;
  transform: translate(calc(-50% - 1px), calc(-50% - 4px)) rotate(309deg);
`;

const StyledPill = styled.span`
  position: absolute;
  bottom: -0.875rem;
  right: -0.5rem;
  z-index: 3;
  display: inline-block;
  padding: 0.25rem 1.5rem;
  background-color: #fff;
  color: var(--clr-primary-100, #5f57ff);
  border: 2px solid var(--clr-primary-100, #5f57ff);
  border-radius: 10rem;
  font-size: 1rem;
  font-weight: 600;
`;

const AnimatedPennyLogo: React.FC = () => {
  return (
    <StyledContainer>
      <StyledLogo
        alt="Illustration"
        src="/assets/images/branding/penny/logo.svg"
      />
      <StyledInnerCircle />
      <StyledLoaderGif
        alt="Loader spinner"
        src="/assets/images/branding/penny/loader.gif"
      />
      <StyledPill>beta</StyledPill>
    </StyledContainer>
  );
};

export default AnimatedPennyLogo;
