import React, { useCallback, useMemo } from "react";

import PayoutOnboarding, {
  CountryInput,
} from "@/features/user/PayoutOnboarding";
import { useResponsive } from "@/styles";
import { Body } from "@/components/Typography";
import SplashScreen from "@/features/pages/app/SplashScreen";
import {
  checkIsStrictOnboardCountry,
  useManageOnboardingCountry,
} from "@/services/UserService";
import { getContractCreateUrl } from "@/services/ContractsService";

import HeaderLayout from "../misc/HeaderLayout";
import {
  StyledBox,
  StyledStep,
  StyledStepFloatingContent,
  StyledStepHeading,
  StyledStepTopContent,
} from "../../ContractForm.styles";
import StepBackButton from "../inputs/StepBackButton";
import StepContinueButton from "../inputs/StepContinueButton";
import { useContractForm } from "../../ContractForm.context";
import { Step } from "../../ContractForm.types";

const PayoutOnboardingStep: React.FC<{
  onCountrySelect?: ({
    selectedCountry,
  }: {
    selectedCountry: string | null;
  }) => Promise<{
    closeCountrySelect?: boolean;
  }> | void;
  showBackButton?: boolean;
  simpleLayout?: boolean;
}> = ({ onCountrySelect, showBackButton = true, simpleLayout = false }) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { gotToStep, values } = useContractForm();
  const {
    onboardingCountry,
    isLoading,
    isUpdating,
    update,
    isStrictOnboardCountry,
  } = useManageOnboardingCountry();

  const disableContinue = !onboardingCountry;
  const onSelect = useCallback(
    ({
      selectedCountry,
    }: {
      selectedCountry: string | null;
    }): Promise<{ closeCountrySelect?: boolean }> | void => {
      if (onCountrySelect) {
        return onCountrySelect({ selectedCountry });
      }

      if (checkIsStrictOnboardCountry({ countryCode: selectedCountry || "" })) {
        return Promise.resolve({ closeCountrySelect: false });
      }

      if (selectedCountry) {
        update({ country: selectedCountry })?.then(() => {
          gotToStep(Step.ContractType);
        });
      }
    },
    [update, gotToStep, onCountrySelect]
  );

  const returnUrl = useMemo(
    () =>
      `${window.location.origin}${getContractCreateUrl({
        contractType: values.contract_type,
        creatorType: values.created_as,
      })}&onboarding=submitted`,
    [values]
  );

  if (isLoading || isUpdating) {
    return <SplashScreen />;
  }

  const countrySelectionJsx = (
    <StyledStep>
      <StyledStepTopContent>
        <StyledStepHeading>
          Where will you receive your payouts?
        </StyledStepHeading>
        <Body
          size="md"
          className="mt-2"
          style={
            isScreenSmallerThanTablet
              ? {
                  color: "var(--clr-icon-text-200, #818898)",
                  maxWidth: "34ch",
                  marginBottom: "3.5rem",
                }
              : {
                  color: "var(--clr-icon-text-200, #818898)",
                  marginBottom: "2rem",
                }
          }
        >
          Before you create your offer, please select the country where you'll
          receive payouts.
          <br />
          This helps us ensure a smooth payment experience and apply any
          necessary compliance steps.
        </Body>

        <StyledBox
          style={
            isScreenSmallerThanTablet
              ? {}
              : {
                  color: "var(--clr-icon-text-200, #818898)",
                  paddingTop: "3.5rem",
                  flexGrow: 1,
                }
          }
        >
          <CountryInput
            onboardOnCta={false}
            placeholder="Select your payout country"
            onSelect={onSelect}
          />

          {!isScreenSmallerThanTablet && (
            <StyledStepFloatingContent
              style={{ padding: 0, marginTop: "8rem" }}
            >
              {showBackButton && <StepBackButton />}
              <StepContinueButton
                style={showBackButton ? {} : { marginInline: "auto" }}
                disabled={disableContinue}
                colorVariant={disableContinue ? "disabled" : "primary"}
              />
            </StyledStepFloatingContent>
          )}
        </StyledBox>
      </StyledStepTopContent>

      {isScreenSmallerThanTablet && (
        <StyledStepFloatingContent>
          <StepContinueButton
            disabled={disableContinue}
            colorVariant={disableContinue ? "disabled" : "primary"}
          />
        </StyledStepFloatingContent>
      )}
    </StyledStep>
  );

  const completeOnboardingJsx = (
    <PayoutOnboarding
      stripeOnboardParams={{
        returnUrl,
        refreshUrl: returnUrl,
      }}
    />
  );

  if (simpleLayout) {
    if (onboardingCountry && isStrictOnboardCountry) {
      return completeOnboardingJsx;
    }

    return countrySelectionJsx;
  }

  if (onboardingCountry && isStrictOnboardCountry) {
    return (
      <StyledBox className="h-100">
        <HeaderLayout>{completeOnboardingJsx}</HeaderLayout>
      </StyledBox>
    );
  }

  return <HeaderLayout>{countrySelectionJsx}</HeaderLayout>;
};

export default PayoutOnboardingStep;
