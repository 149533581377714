import ImageTitleContent from "@/components/data/ImageTitleContent";

const NoOffers: React.FC<{ className?: string; noJobsContent?: boolean }> = ({
  className,
  noJobsContent,
}) => (
  <ImageTitleContent
    className={className}
    image="/assets/images/pages/contracts/no_contracts.svg"
    title={
      noJobsContent ? (
        <>You don&apos;t have any active Jobs</>
      ) : (
        <>You don&apos;t have any offers</>
      )
    }
    content="Select the Plus (+) icon below to create an offer"
  />
);

export default NoOffers;
