import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { screenSmallerThan, transientOptions } from "@/styles";
import { addHoverHighlight } from "@/utils/css";

import {
  BUTTON_BORDER_RADIUS,
  BUTTON_VARAINT_CONFIG,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BUTTON_VARIANT,
  DEFAULT_COLOR_VARIANT,
} from "./Button.config";
import { IStyledButtonProps } from "./Button.types";

export const StyledButton = styled.button<IStyledButtonProps>`
  --color: ${({
    $variant = DEFAULT_BUTTON_VARIANT,
    $colorVariant = DEFAULT_COLOR_VARIANT,
  }) => {
    return BUTTON_VARAINT_CONFIG[$colorVariant][$variant].color;
  }};
  --border-color: ${({
    $variant = DEFAULT_BUTTON_VARIANT,
    $colorVariant = DEFAULT_COLOR_VARIANT,
  }) => BUTTON_VARAINT_CONFIG[$colorVariant][$variant].borderColor};
  --bg-color: ${({
    $variant = DEFAULT_BUTTON_VARIANT,
    $colorVariant = DEFAULT_COLOR_VARIANT,
  }) => BUTTON_VARAINT_CONFIG[$colorVariant][$variant].bgColor};

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0.625rem 0.75rem;
  border: 1px solid var(--border-color);
  outline: none;

  color: var(--color);
  background: var(--bg-color);
  min-width: fit-content;
  cursor: pointer;
  text-decoration: none;
  transition: width, filter, 240ms ease-in-out;

  font-size: 0.875rem;
  line-height: 1.5em;
  font-weight: 600;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;

    ${({
      $variant = DEFAULT_BUTTON_VARIANT,
      $colorVariant = DEFAULT_COLOR_VARIANT,
    }) => {
      const { disabledColor, disabledBgColor, disabledBorderColor } =
        BUTTON_VARAINT_CONFIG[$colorVariant][$variant];
      return `
                ${disabledColor ? `--color: ${disabledColor};` : ""}
                ${disabledBgColor ? `--bg-color: ${disabledBgColor};` : ""}
                ${
                  disabledBorderColor
                    ? `--border-color: ${disabledBorderColor};`
                    : ""
                }
            `;
    }};
  }

  border-radius: 0.75rem;

  ${screenSmallerThan.tablet} {
    ${({ $borderRadius = DEFAULT_BORDER_RADIUS }) =>
      $borderRadius !== "default"
        ? `border-radius: var(${BUTTON_BORDER_RADIUS[$borderRadius]?.cssVariable})`
        : ""};
  }

  ${addHoverHighlight()}
`;

export const StyledLink = styled(Link, transientOptions)``;
