import styled from "@emotion/styled";

import SplashScreen from "@/features/pages/app/SplashScreen";
import AnimatedPennyLogo from "@/features/app/AnimatedPennyLogo";

const StyledLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
`;

const LoaderScreen: React.FC = () => {
  return (
    <SplashScreen>
      <StyledLoaderContainer>
        <AnimatedPennyLogo />{" "}
        <img
          alt="Generating"
          src="/assets/images/branding/penny/generating_text.svg"
        />
      </StyledLoaderContainer>
    </SplashScreen>
  );
};

export default LoaderScreen;
