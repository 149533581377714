import React, { useMemo } from "react";

import { IButtonProps } from "@/components/input/Button";
import ShareButton, { OnClick } from "@/features/media/ShareButton";
import {
  ContractCompleteDetails,
  getJobShareContent,
  getOfferShareContent,
  isContractAJob,
} from "@/services/ContractsService";

const ContractShareButton: React.FC<
  {
    contractId: string;
    contractDetails?: ContractCompleteDetails;
    onClick?: OnClick;
  } & Omit<IButtonProps, "onClick">
> = ({
  contractId,
  contractDetails: contractDetailsFromProps,
  onClick,
  ...buttonProps
}) => {
  const content = useMemo(() => {
    if (!contractDetailsFromProps) {
      return getOfferShareContent({ contractId });
    }

    const isJob = contractDetailsFromProps
      ? isContractAJob(contractDetailsFromProps)
      : false;

    return isJob
      ? getJobShareContent({
          contractId,
          contractDetails: contractDetailsFromProps,
        })
      : getOfferShareContent({ contractId });
  }, [contractId, contractDetailsFromProps]);

  return (
    <ShareButton
      buttonProps={buttonProps}
      content={content}
      onClick={onClick}
    />
  );
};

export default ContractShareButton;
