import React, { useMemo } from "react";
import styled from "@emotion/styled";

import {
  amIContractPayee,
  amIContractPayer,
  isMilestoneFundable,
  isMilestoneFundReleasable,
  isMilestoneFundRequestable,
  isMilestonePaymentReleased,
  isMilestoneStartable,
  isMilestoneWorkSubmittable,
  useManageContract,
} from "@/services/ContractsService";
import ShimmerLoader from "@/components/misc/ShimmerLoader";
import ReleasePaymentButton from "@/features/contracts/ReleasePaymentButton";

import { SystemMessage, SystemMessageType } from "../../../ChatService.types";
import Icon from "@/components/misc/Icon";
import SubmitWorkButton from "@/features/contracts/ContractTimeline/components/SubmitWorkButton";
import RequestPaymentButton from "@/features/contracts/RequestPaymentButton";
import FundMilestoneButton from "@/features/contracts/ContractTimeline/components/FundMilestoneButton";
import StartMilestoneButton from "@/features/contracts/StartMilestoneButton";

const StyledSuccessText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5em;
  font-weight: 600;
  border-radius: 0.75rem;
  border: 1px solid rgba(29, 191, 115, 1);
  color: #fff;
  background: rgba(29, 191, 115, 1);
`;

const ReleasePaymentActionMessage: React.FC<{
  systemMessage: SystemMessage;
  messageText: string;
  className?: string;
  hideActionButtons?: boolean;
}> = ({ systemMessage, messageText, hideActionButtons }) => {
  const contractId = systemMessage?.data?.contract_id;
  const { isLoading, contractDetails } = useManageContract({
    contractId,
  });

  const amIPayer = useMemo(() => {
    if (!contractDetails) {
      return false;
    }

    return amIContractPayer(contractDetails);
  }, [contractDetails]);

  const milestone = useMemo(() => {
    if (!contractDetails) {
      return null;
    }

    const milestone = contractDetails.milestones.find(
      (m) => m.id === systemMessage?.data?.milestone_id
    );

    return milestone || null;
  }, [contractDetails, systemMessage]);

  const { canRelease, isReleased } = useMemo(() => {
    if (!milestone) {
      return { canRelease: false, isReleased: true };
    }

    return {
      canRelease: isMilestoneFundReleasable(milestone),
      isReleased: isMilestonePaymentReleased(milestone),
    };
  }, [milestone]);

  if (isLoading || !contractDetails || !milestone) {
    return (
      <>
        <ShimmerLoader.Default
          style={{ height: "17px", borderRadius: "4px" }}
        />
        <ShimmerLoader.Default
          className="mt-4"
          style={{ height: "40px", borderRadius: "0.75rem" }}
        />
      </>
    );
  }

  if (!milestone) {
    return messageText;
  }

  const { name } = milestone;

  if (hideActionButtons) {
    return <div>Milestone {name} can be released</div>;
  }

  return (
    <>
      <>
        <div>Milestone {name} can be released</div>

        {isReleased ? (
          <StyledSuccessText className="w-100 mt-4">
            <Icon isSrcRelative src="double_tick.svg" size="sm" /> Payment
            Released
          </StyledSuccessText>
        ) : (
          <ReleasePaymentButton
            colorVariant={(canRelease && amIPayer) ? "primary" : "disabled"}
            className="w-100 mt-3 py-2"
            contractDetails={contractDetails}
            disabled={!canRelease || !amIPayer}
          />
        )}
      </>
    </>
  );
};

const RequestPaymentActionMessage: React.FC<{
  systemMessage: SystemMessage;
  messageText: string;
  className?: string;
  hideActionButtons?: boolean;
}> = ({ systemMessage, messageText, hideActionButtons }) => {
  const contractId = systemMessage?.data?.contract_id;
  const milestoneId = systemMessage?.data?.milestone_id;
  const { isLoading, contractDetails } = useManageContract({
    contractId,
  });

  const amIPayee = useMemo(() => {
    if (!contractDetails) {
      return false;
    }

    return amIContractPayee(contractDetails);
  }, [contractDetails]);

  const milestone = useMemo(() => {
    if (!contractDetails) {
      return null;
    }

    const milestone = contractDetails.milestones.find(
      (m) => m.id === systemMessage?.data?.milestone_id
    );

    return milestone || null;
  }, [contractDetails, systemMessage]);

  const { canRequest, canSubmitWork } = useMemo(() => {
    if (!milestone) {
      return { canSubmitWork: false, canRequest: false };
    }

    return {
      canSubmitWork: isMilestoneWorkSubmittable(milestone),
      canRequest: isMilestoneFundRequestable(milestone),
    };
  }, [milestone]);

  if (isLoading || !contractDetails || !contractId || !milestoneId) {
    return (
      <>
        <ShimmerLoader.Default
          style={{ height: "17px", borderRadius: "4px" }}
        />
        <ShimmerLoader.Default
          className="mt-4"
          style={{ height: "40px", borderRadius: "0.75rem" }}
        />
      </>
    );
  }

  if (!milestone) {
    return messageText;
  }

  const { name } = milestone;
  const hasRequested = !canRequest && !canSubmitWork;

  if (hideActionButtons) {
    return <div>Milestone {name} can be released</div>;
  }

  return (
    <>
      <div>Milestone {name} can be requested</div>

      {hasRequested ? (
        <StyledSuccessText className="w-100 mt-4">
          <Icon isSrcRelative src="double_tick.svg" size="sm" /> Payment
          Requested
        </StyledSuccessText>
      ) : (
        <>
          <SubmitWorkButton
            className="w-100 mt-4 py-2"
            contractId={contractId}
            milestoneId={milestoneId}
            style={{ padding: "0.625rem 0.75rem" }}
            disabled={!canSubmitWork || !amIPayee}
          />
          <RequestPaymentButton
            colorVariant={(canRequest && amIPayee) ? "white-primary" : "disabled"}
            className="w-100 mt-3 py-2"
            contractDetails={contractDetails}
            disabled={!canRequest || !amIPayee}
          />
        </>
      )}
    </>
  );
};

const FundMilestoneActionMessage: React.FC<{
  systemMessage: SystemMessage;
  messageText: string;
  className?: string;
  hideActionButtons?: boolean;
}> = ({ systemMessage, messageText, hideActionButtons }) => {
  const contractId = systemMessage?.data?.contract_id;
  const milestoneId = systemMessage?.data?.milestone_id;
  const { isLoading, contractDetails } = useManageContract({
    contractId,
  });

  const amIPayer = useMemo(() => {
    if (!contractDetails) {
      return false;
    }

    return amIContractPayer(contractDetails);
  }, [contractDetails]);

  const milestone = useMemo(() => {
    if (!contractDetails) {
      return null;
    }

    const milestone = contractDetails.milestones.find(
      (m) => m.id === systemMessage?.data?.milestone_id
    );

    return milestone || null;
  }, [contractDetails, systemMessage]);

  const { canRelease, isReleased } = useMemo(() => {
    if (!milestone) {
      return { canRelease: false, isReleased: true };
    }

    return {
      canRelease: isMilestoneFundable(milestone),
      isReleased: !isMilestoneFundable(milestone),
    };
  }, [milestone]);

  if (isLoading || !contractDetails || !contractId || !milestoneId) {
    return (
      <>
        <ShimmerLoader.Default
          style={{ height: "17px", borderRadius: "4px" }}
        />
        <ShimmerLoader.Default
          className="mt-4"
          style={{ height: "40px", borderRadius: "0.75rem" }}
        />
      </>
    );
  }

  if (!milestone) {
    return messageText;
  }

  const { name } = milestone;

  if (hideActionButtons) {
    return <div>Milestone {name} can be released</div>;
  }

  return (
    <>
      <div>Milestone {name} can be funded</div>

      {isReleased ? (
        <StyledSuccessText className="w-100 mt-4">
          <Icon isSrcRelative src="double_tick.svg" size="sm" /> Milestone
          Funded
        </StyledSuccessText>
      ) : (
        <FundMilestoneButton
          colorVariant={(canRelease && amIPayer) ? "primary" : "disabled"}
          className="w-100 mt-3 py-2"
          contractId={contractId}
          milestoneId={milestoneId}
          disabled={!canRelease || !amIPayer}
        />
      )}
    </>
  );
};

const StartMilestoneActionMessage: React.FC<{
  systemMessage: SystemMessage;
  messageText: string;
  className?: string;
  hideActionButtons?: boolean;
}> = ({ systemMessage, messageText, hideActionButtons }) => {
  const contractId = systemMessage?.data?.contract_id;
  const milestoneId = systemMessage?.data?.milestone_id;
  const { isLoading, contractDetails } = useManageContract({
    contractId,
  });

  const amIPayee = useMemo(() => {
    if (!contractDetails) {
      return false;
    }

    return amIContractPayee(contractDetails);
  }, [contractDetails]);

  const milestone = useMemo(() => {
    if (!contractDetails) {
      return null;
    }

    const milestone = contractDetails.milestones.find(
      (m) => m.id === systemMessage?.data?.milestone_id
    );

    return milestone || null;
  }, [contractDetails, systemMessage]);

  const { canStart, isStarted } = useMemo(() => {
    if (!milestone) {
      return { canStart: false, isStarted: true };
    }

    return {
      canStart: isMilestoneStartable(milestone),
      isStarted: !isMilestoneStartable(milestone),
    };
  }, [milestone]);

  if (isLoading || !contractDetails || !contractId || !milestoneId) {
    return (
      <>
        <ShimmerLoader.Default
          style={{ height: "17px", borderRadius: "4px" }}
        />
        <ShimmerLoader.Default
          className="mt-4"
          style={{ height: "40px", borderRadius: "0.75rem" }}
        />
      </>
    );
  }

  if (!milestone) {
    return messageText;
  }

  const { name } = milestone;

  if (hideActionButtons) {
    return <div>Milestone {name} can be started</div>;
  }

  return (
    <>
      <div>Milestone {name} can be started</div>

      {isStarted ? (
        <StyledSuccessText className="w-100 mt-4">
          <Icon isSrcRelative src="double_tick.svg" size="sm" /> Milestone
          Started
        </StyledSuccessText>
      ) : (
        <StartMilestoneButton
          colorVariant={(canStart && amIPayee) ? "primary" : "disabled"}
          className="w-100 mt-3 py-2"
          contractId={contractId}
          milestoneId={milestoneId}
          disabled={!canStart || !amIPayee}
        />
      )}
    </>
  );
};

const CustomMessageRenderer: React.FC<{
  systemMessage: SystemMessage;
  messageText: string;
  className?: string;
  hideActionButtons?: boolean;
}> = ({ systemMessage, messageText, hideActionButtons }) => {
  if (systemMessage.type === SystemMessageType.ReleasePayment) {
    return (
      <ReleasePaymentActionMessage
        systemMessage={systemMessage}
        messageText={messageText}
        hideActionButtons={hideActionButtons}
      />
    );
  }

  if (systemMessage.type === SystemMessageType.RequestPayment) {
    return (
      <RequestPaymentActionMessage
        systemMessage={systemMessage}
        messageText={messageText}
        hideActionButtons={hideActionButtons}
      />
    );
  }

  if (systemMessage.type === SystemMessageType.FundMilestone) {
    return (
      <FundMilestoneActionMessage
        systemMessage={systemMessage}
        messageText={messageText}
        hideActionButtons={hideActionButtons}
      />
    );
  }

  if (systemMessage.type === SystemMessageType.StartMilestone) {
    return (
      <StartMilestoneActionMessage
        systemMessage={systemMessage}
        messageText={messageText}
        hideActionButtons={hideActionButtons}
      />
    );
  }

  return messageText;
};

export default CustomMessageRenderer;
