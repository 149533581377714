import { useCallback, useState } from "react";
import lodashGet from "lodash/get";

import { contractService } from "@/config/services";
import { useToast } from "@/components/misc/Toast";

import useContractDetails from "./useContractDetails";

function useStartContractMilestone(params: {
  contractId: string;
  milestoneId: number;
}) {
  const { contractId } = params;
  const { createToast } = useToast();
  const { reload: reloadContractDetails } = useContractDetails({ contractId });

  const [isStarting, setIsStarting] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);

  const start = useCallback(() => {
    if (isStarting) {
      return Promise.reject();
    }

    setIsStarting(true);
    setHasStarted(false);

    return contractService
      .startContractMilestone({ ...params })
      .then(() => {
        setHasStarted(true);
        createToast({
          title: "Request Sent!",
          description: "Funding request was sent to the client",
          variant: "success",
          timeoutInMilliseconds: 5000,
        });
      })
      .catch((err) => {
        setHasStarted(false);
        const message =
          lodashGet(err, "response.data.message") ||
          "Something went wrong, milestone was not started.";
        createToast({
          title: "Could not start milestone",
          description: message,
          variant: "error",
        });
      })
      .finally(() => {
        setIsStarting(false);
        reloadContractDetails();
      });
  }, [params, isStarting, reloadContractDetails, createToast]);

  return {
    start,
    hasStarted,
    isStarting,
  };
}

export default useStartContractMilestone;
