import React from "react";
import styled from "@emotion/styled";

import { PayoutStatus } from "@/services/UserService";
import TransactionStatus from "@/features/pages/contract/TransactionsPage/components/TransactionStatus";
import { Body } from "@/components/Typography";
import Icon from "@/components/misc/Icon";
import OnboardStripeButton from "@/features/user/OnboardStripeButton";

import usePendingContractorOnboardingPrompt from "./hooks/usePendingContractorOnboardingPrompt";

const StyledTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

const StyledContent = styled(Body)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: rgba(164, 172, 185, 1);
  padding-inline: 0.25rem;
`;

const PendingContractorOnboardingPrompt: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { show } = usePendingContractorOnboardingPrompt();

  if (!show) {
    return null;
  }

  return (
    <div className={className}>
      <StyledTopContainer>
        <TransactionStatus payoutStatus={PayoutStatus.PENDING_VERIFICATION} />

        <OnboardStripeButton className="px-3">Verify me</OnboardStripeButton>
      </StyledTopContainer>

      <StyledContent size="md" className="mt-2">
        <Icon isSrcRelative src="info_3.svg" size="xxs" />
        To access your funds you must complete your account verification.
      </StyledContent>
    </div>
  );
};

export default PendingContractorOnboardingPrompt;
