import { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import { Channel, MessageInput, MessageList } from "stream-chat-react";
import styled from "@emotion/styled";
import lodashGet from "lodash/get";

import {
  CHANNEL_TYPE_FILTER_URL_KEY,
  ChannelType,
  useChatService,
  useConnectChannel,
} from "@/services/ChatServiceNew";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { SITE_PATHS } from "@/config/routing";
import Avatar from "@/components/misc/Avatar";
import { ContractId } from "@/services/ContractsService";
import { ChatRoomLoading } from "@/features/chat/ChatRoom";
import { screenSmallerThan } from "@/styles";

import "stream-chat-react/dist/css/v2/index.css";

import {
  StyledBottomSection,
  StyledButton,
  StyledHeader,
  StyledRoomTitle,
  StyledRoomTitleSecondary,
  StyledTopLeftContent,
  StyledTopSection,
  StyledTopSectionContent,
} from "./ChatRoom.styles";
import { useParamState } from "@/services/Routing";

import { checkIsSystemMessage } from "../../ChatService.utils";
import CustomMessageRenderer from "./components/CustomMessageRenderer";

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;

  .str-chat__container {
    display: flex;
    flex-direction: column;
  }

  ${screenSmallerThan.tablet} {
    .str-chat__message-input {
      padding-block: 0.75rem;
    }
  }
`;

export type UserId = string;

export type User = {
  id: UserId;
  name: string;
  image?: string;
  email?: string;
  paypipeId?: string;
};

interface IChatRoomProps {
  contractId: ContractId;
  currentUserId: UserId;
  users: User[];
  menuButton?: ReactNode;
  className?: string;
  showBackButton?: boolean;
  header?: {
    appendContent?: ReactNode;
    appendBelowContent?: ReactNode;
  };
  hideInputs?: boolean;
  footer?: {
    appendContent?: React.ReactNode;
  };
}

const ChatRoom: FC<IChatRoomProps> = ({
  contractId,
  className,
  showBackButton,
  currentUserId,
  users,
  header = {},
  hideInputs,
  footer,
}) => {
  const { isActive, connectUser, isConnecting } = useChatService();
  const { channel } = useConnectChannel({
    contractId,
  });

  const usersExceptCurrent = useMemo(
    () => users.filter((user) => user.id !== currentUserId),
    [users, currentUserId]
  );
  const displayUserDetails = usersExceptCurrent[0];

  const channelType = lodashGet(
    channel,
    "data.extraData.channelType",
    ""
  ) as ChannelType;
  const requestUserId = lodashGet(channel, "data.extraData.requestUserId", 0);
  const isRequestChannel = channelType === ChannelType.ChatRequest;
  const showAcceptRequestButton = !!requestUserId && isRequestChannel;
  const [channelTypeFilter, setChannelTypeFilter] = useParamState<string>(
    CHANNEL_TYPE_FILTER_URL_KEY,
    "",
    {
      parseJson: false,
    }
  );

  const renderText = useCallback((text = "") => {
    const { isSystemMessage, messageData } = checkIsSystemMessage({ text });

    if (!isSystemMessage || !messageData) {
      return <>{text}</>;
    }

    return (
      <div className="p-2">
        <CustomMessageRenderer systemMessage={messageData} messageText={text} />
      </div>
    );
  }, []);

  useEffect(() => {
    if (!!channelType && channelType !== channelTypeFilter) {
      setChannelTypeFilter(channelType);
    }
  }, [channelType, channelTypeFilter, setChannelTypeFilter]);

  useEffect(() => {
    if (isConnecting) {
      return;
    }

    if (contractId && !isActive) {
      connectUser();
    }
  }, [isActive, contractId, connectUser, isConnecting]);

  if (isConnecting) {
    return <ChatRoomLoading />;
  }

  if (!isActive || !channel) {
    return null;
  }

  return (
    <StyledContainer className={className}>
      <Channel channel={channel} LoadingIndicator={() => <ChatRoomLoading />}>
        <StyledHeader>
          <StyledTopSection>
            <StyledTopLeftContent>
              {showBackButton && (
                <Button variant="ghost" link={SITE_PATHS.CHAT_PAGE}>
                  <Icon isSrcRelative size="xxs" src="chevron_left.svg" />
                </Button>
              )}
              <Avatar
                img={displayUserDetails?.image}
                size="lg"
                firstName={displayUserDetails?.name}
              />
            </StyledTopLeftContent>

            <StyledTopSectionContent>
              <StyledRoomTitle>{displayUserDetails?.name}</StyledRoomTitle>
              <StyledRoomTitleSecondary>
                {displayUserDetails?.paypipeId
                  ? `@${displayUserDetails?.paypipeId}`
                  : displayUserDetails?.email}
              </StyledRoomTitleSecondary>
            </StyledTopSectionContent>

            {header?.appendContent || null}
          </StyledTopSection>

          {header?.appendBelowContent || null}
        </StyledHeader>

        <MessageList renderText={renderText} />

        {isRequestChannel ? (
          <>
            {showAcceptRequestButton && (
              <StyledBottomSection>
                <StyledButton userId={requestUserId}>
                  Accept & Reply
                </StyledButton>
              </StyledBottomSection>
            )}
          </>
        ) : (
          <>
            {!showAcceptRequestButton && !hideInputs && (
              <MessageInput grow maxRows={5} />
            )}
          </>
        )}

        {footer?.appendContent || null}
      </Channel>
    </StyledContainer>
  );
};

export default ChatRoom;
