"use client";

import React from "react";
import styled from "@emotion/styled";

import ImageTitleContent from "@/components/data/ImageTitleContent";
import Icon from "@/components/misc/Icon";
import Button from "@/components/input/Button";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import { SITE_PATHS } from "@/config/routing";
import {
  ContractCompleteDetails,
  getContractManagePageUrl,
  useShareOfferViaEmail,
} from "@/services/ContractsService";
import { analytics } from "@/config/services";
import { useOnboardStripe } from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import { Body } from "@/components/Typography";
import OnboardStripeButton from "@/features/user/OnboardStripeButton";

import {
  StyledBottomContainer,
  StyledButton,
  StyledContainer,
  StyledShareButton,
  StyledTopContainer,
  StyledButtonContainer,
} from "./SuccessScreen.styles";
import { useContractForm } from "../../../ContractForm.context";
import AiFeedback from "../../misc/AiFeedback";

const StyledMainContainer = styled.div`
  ${screenLargerThan.tablet} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 10;
  }
`;

const StyledMobileAiFeedback = styled(AiFeedback)`
  ${screenLargerThan.tablet} {
    margin-top: 2rem;
    box-shadow: none !important;
    border-top: 1px solid rgba(0, 0, 0, 0.075);
    padding-top: 2.5rem;
    border-radius: 0 !important;
  }

  ${screenSmallerThan.tablet} {
    margin: -1.5rem;
    margin-top: auto;
    width: unset;
  }
`;

const StyledInfoContainer = styled.div`
  text-align: center;
  padding: 1rem;
  background: rgba(246, 248, 250, 1);
  border-radius: 0.5rem;
  max-width: 56ch;
  margin-inline: auto;
`;

const SuccessScreen: React.FC = () => {
  const { createdContractDetails } = useContractForm();
  const { isReady, isOnboarded } = useOnboardStripe();

  const { triggerShare, formModalJsx } = useShareOfferViaEmail({
    contractDetails: createdContractDetails || ({} as ContractCompleteDetails),
    successRedirectRoute: SITE_PATHS.HOME_PAGE,
    share_source: "after_form_create",
    customOfferFeedback: (
      <StyledMobileAiFeedback
        variant="drawer"
        successRedirectRoute={SITE_PATHS.HOME_PAGE}
      />
    ),
  });

  const contractId = createdContractDetails?.id || "";
  const contractTitle = createdContractDetails?.title || "";

  if (!isReady) {
    return <SplashScreen />;
  }

  const onboardJsx = (
    <StyledInfoContainer>
      <Body size="md" style={{ color: "rgba(89, 90, 99, 1)" }}>
        Your offer is live and can be shared, but the released funds will only
        be made accessible to you after verifying your account.
      </Body>
      <OnboardStripeButton
        variant="ghost"
        colorVariant="black"
        className="mt-3"
      >
        Verify my account
      </OnboardStripeButton>
    </StyledInfoContainer>
  );

  return (
    <>
      {formModalJsx}

      <StyledMainContainer>
        <StyledContainer>
          <StyledButtonContainer>
            <StyledButton
              variant="ghost"
              link={getContractManagePageUrl(contractId)}
            >
              <Icon
                src="/assets/images/icons/chevron_left.svg"
                size="xxs"
                colorVariant="black"
              />
            </StyledButton>

            <StyledButton variant="ghost" link={SITE_PATHS.HOME_PAGE}>
              <Icon
                src="/assets/images/icons/cross.svg"
                size="xxs"
                colorVariant="black"
              />
            </StyledButton>
          </StyledButtonContainer>

          <AiFeedback variant="gradient-card" />

          <StyledTopContainer>
            <ImageTitleContent
              title="Offer Created!"
              content={
                <>
                  Your offer has been successfully created and is ready to share
                  with your client.
                </>
              }
              image="/assets/images/pages/contracts/contract_created.svg"
            />

            {false && !isOnboarded && onboardJsx}
          </StyledTopContainer>

          <StyledBottomContainer>
            <Button onClick={triggerShare}>
              Send to email <Icon isSrcRelative src="mail.svg" size="xs" />
            </Button>

            <StyledShareButton
              className="mt-1"
              contractId={contractId}
              contractDetails={createdContractDetails || undefined}
              variant="ghost"
              onClick={({ shareType }) => {
                analytics.triggerShareOfferEvent("share_offer", {
                  source: "after_form_create",
                  share_type: shareType,
                  offer_id: contractId,
                  offer_name: contractTitle,
                });
              }}
            >
              Or share via
              <Icon
                isSrcRelative
                src="share_2.svg"
                size="xxs"
                colorVariant="black"
              />
            </StyledShareButton>
          </StyledBottomContainer>
        </StyledContainer>
      </StyledMainContainer>
    </>
  );
};

export default SuccessScreen;
