import { useEffect, useRef, useState } from "react";

function FindReact(dom: any, traverseUp = 0) {
  const key: any = Object.keys(dom).find((key) => {
    return (
      key.startsWith("__reactFiber$") || // react 17+
      key.startsWith("__reactInternalInstance$")
    ); // react <17
  });

  const nodes = [];

  const domFiber = dom[key];
  if (domFiber == null) return null;

  // react <16
  if (domFiber._currentElement) {
    let compFiber = domFiber._currentElement._owner;
    for (let i = 0; i < traverseUp; i++) {
      compFiber = compFiber._currentElement._owner;
    }
    return compFiber._instance;
  }

  // react 16+
  const GetCompFiber = (fiber: any) => {
    //return fiber._debugOwner; // this also works, but is __DEV__ only
    let parentFiber = fiber.return;
    while (typeof parentFiber.type == "string") {
      parentFiber = parentFiber.return;
    }
    return parentFiber;
  };
  let compFiber = GetCompFiber(domFiber);
  for (let i = 0; i < traverseUp; i++) {
    compFiber = GetCompFiber(compFiber);
    nodes.push(compFiber);
  }
  return { compFiber, nodes };
}

export const useParentName = ({
  parentLevel = 1,
}: {
  parentLevel?: number;
} = {}) => {
  const ref = useRef();
  const [parentName, setParentName] = useState("");
  const [path, setPath] = useState("");

  useEffect(() => {
    if (ref.current) {
      let { compFiber: node, nodes } = FindReact(ref.current, parentLevel);
      setParentName(node.type?.name);

      const path = nodes.map((node: any) => node.type?.name || "?").join("->");
      setPath(path);
    }
  }, [parentLevel]);

  return { ref, parentName, path };
};
