import { useCallback, useState } from "react";

import { contractService } from "@/config/services";
import { AtLeastOne } from "@/types/common";

import { AiGeneratedContractDetails } from "../ContractsService.types";

function useGenerateContractFromTranscript() {
  const [inProgress, setInProgress] = useState(false);
  const [contractDetails, setContractDetails] =
    useState<AiGeneratedContractDetails | null>(null);

  const generate = useCallback(
    ({ content, file }: AtLeastOne<{ content: string; file: File }>) => {
      if (!content && !file) return;

      if (inProgress) return;

      setInProgress(true);

      if (content) {
        return contractService
          .generateContractFromTranscript({
            content,
          })
          .then((res) => {
            if (res.details) {
              setContractDetails(res.details);
            }

            return res;
          })
          .finally(() => {
            setInProgress(false);
          });
      } else if (file) {
        return contractService
          .generateContractFromDocument({
            file,
          })
          .then((res) => {
            if (res.details) {
              setContractDetails(res.details);
            }

            return res;
          })
          .finally(() => {
            setInProgress(false);
          });
      }
    },
    [inProgress]
  );

  return {
    generate,
    inProgress,
    contractDetails,
  };
}

export default useGenerateContractFromTranscript;
