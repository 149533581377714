"use client";

import styled from "@emotion/styled";

import { getViewPortHeightCssString } from "@/styles";

export const StyledPage = styled.div`
  display: grid;
  place-items: center;
  width: 100vw;
  height: ${getViewPortHeightCssString()};
  background: var(--clr-background-white, #fff);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
`;

export const StyledLogo = styled.img`
  width: 10rem;
  max-width: 100%;
`;
