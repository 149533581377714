import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import Wysiwyg from "@/components/input/LexicalWysiwyg";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body, Heading } from "@/components/Typography";
import {
  AI_OFFER_PROMPT_MAX_CHAR_COUNT,
  AI_OFFER_PROMPT_MIN_CHAR_COUNT,
} from "@/services/ContractsService";

import { OfferInputType } from "../AiOfferForm";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledEditor = styled(Wysiwyg)`
  flex-grow: 1;
  overflow: auto;
  min-height: 45vh;
`;

const StyledActionContentContainer = styled.div`
  padding-inline: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid rgba(236, 239, 243, 1);
  padding-top: 1.5rem;
`;

const StyledModalActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  & > * {
    border-radius: 10rem !important;
  }

  & > *:first-child {
    flex-grow: 1;
    max-width: 10rem;
  }
`;

const StyledPennyLogo = styled.img`
  display: block;
  margin-inline: 0;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TextInputScreen: React.FC<{
  text: string;
  setText: (value: string) => void;
  handleGenerate: () => void;
  inputType?: "" | OfferInputType;
  showRegenerateButton?: boolean;
}> = ({ text, setText, handleGenerate, showRegenerateButton, inputType }) => {
  const [clipboardHasText, setClipboardHasText] = useState(false);
  const [disableGenerateButton, setDisableGenerateButton] =
    useState(showRegenerateButton);
  const [showRegenerateText, setShowRegenerateText] = useState(false);

  const minContentModalState = useModalState();
  const maxContentModalState = useModalState();

  const showPasteButton = clipboardHasText;

  useEffect(() => {
    try {
      navigator.clipboard.readText().then((clipText) => {
        setClipboardHasText(!!clipText.trim());
      });
    } catch {}
  }, []);

  const pasteFromClipboard = useCallback(() => {
    try {
      navigator.clipboard.readText().then((clipText) => {
        if (clipText) {
          setText(clipText);
        }
      });
    } catch {}
  }, [setText]);

  const handleGenerateClick = useCallback(() => {
    if (text.length < AI_OFFER_PROMPT_MIN_CHAR_COUNT) {
      minContentModalState.open();
    } else if (text.length > AI_OFFER_PROMPT_MAX_CHAR_COUNT) {
      maxContentModalState.open();
    } else {
      handleGenerate();
    }
  }, [handleGenerate, text, minContentModalState, maxContentModalState]);

  const placeholder =
    inputType === "description"
      ? "Describe the job to me using at least 50 characters..."
      : "Paste here...";

  return (
    <>
      <Modal
        state={minContentModalState}
        contentContainerProps={{ style: { textAlign: "center" } }}
        width="320px"
      >
        <StyledLogoContainer>
          <StyledPennyLogo
            alt="Logo"
            src="/assets/images/branding/penny/logo_round.svg"
          />
        </StyledLogoContainer>
        <Heading size="lg" className="mt-4">
          Uh oh!
        </Heading>
        <Body size="lg" className="mt-2" style={{ color: "#818898" }}>
          I need more information. Please add more text.
        </Body>
        <StyledModalActionsContainer className="mt-3">
          <Button
            variant="secondary"
            colorVariant="gray"
            onClick={minContentModalState.close}
          >
            Ok
          </Button>
        </StyledModalActionsContainer>
      </Modal>

      <Modal
        state={maxContentModalState}
        contentContainerProps={{ style: { textAlign: "center" } }}
        width="320px"
      >
        <StyledLogoContainer>
          <StyledPennyLogo
            alt="Logo"
            src="/assets/images/branding/penny/logo_round.svg"
          />
        </StyledLogoContainer>
        <Heading size="lg" className="mt-4">
          Uh oh!
        </Heading>
        <Body size="lg" className="mt-2" style={{ color: "#818898" }}>
          There seems to be too many characters. Please make sure to keep it
          under {AI_OFFER_PROMPT_MAX_CHAR_COUNT} characters.
        </Body>
        <StyledModalActionsContainer className="mt-3">
          <Button
            variant="secondary"
            colorVariant="gray"
            onClick={minContentModalState.close}
          >
            Ok
          </Button>
        </StyledModalActionsContainer>
      </Modal>

      <StyledContainer>
        <StyledEditor
          placeholder={placeholder}
          value={{ markdown: text }}
          onChange={({ markdown = "" }) => {
            setText(markdown);
            setDisableGenerateButton(false);

            if (showRegenerateButton) {
              setShowRegenerateText(true);
            }
          }}
        />

        <StyledActionContentContainer>
          {showPasteButton && (
            <Button
              variant="ghost"
              colorVariant="black"
              onClick={pasteFromClipboard}
            >
              <Icon
                src="/assets/images/branding/penny/paste.svg"
                size="xs"
                style={{
                  marginTop: "-0.25rem",
                }}
              />
              Paste from clipboard
            </Button>
          )}

          <Button
            className="py-3 w-100"
            colorVariant={disableGenerateButton ? "disabled" : "gradient"}
            onClick={handleGenerateClick}
            disabled={disableGenerateButton}
            style={{
              display: "flex",
              maxWidth: "28rem",
              marginInline: "auto",
            }}
          >
            {showRegenerateText ? "Regenerate Offer" : "Generate Offer"}
            <Icon
              size="sm"
              alt="Illustration"
              src="/assets/images/branding/penny/star.svg"
              colorVariant={disableGenerateButton ? "gray" : "white"}
            />
          </Button>
        </StyledActionContentContainer>
      </StyledContainer>
    </>
  );
};

export default TextInputScreen;
