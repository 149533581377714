"use client";

import React, { useEffect, useMemo, useRef } from "react";
import styled from "@emotion/styled";

import {
  isContractCompleted,
  useContractUrlId,
} from "@/services/ContractsService";
import ContractCard, {
  ContractCardLoading,
} from "@/features/contracts/ContractCard";

import { useContractsListPage } from "../ContractsListPage.context";
import { ContractTab } from "../ContractsListPage.types";
import PendingContractorOnboardingPrompt from "@/features/contracts/PendingContractorOnboardingPrompt";

const StyledContainer = styled.div`
  hr {
    opacity: 0.25;
    margin-block: 0.5rem;
  }
`;

const ContractList: React.FC<{ className?: string }> = ({ className }) => {
  const { isContractListLoading, contractList, setActiveTab, activeTab } =
    useContractsListPage();
  const { idFromUrl } = useContractUrlId();

  const fixedInitialTab = useRef(false);

  const hasNoContracts = !contractList.length && !isContractListLoading;

  const filteredContractlist = useMemo(() => {
    return contractList.filter((contract) => {
      const isCompleted = isContractCompleted(contract);

      if (activeTab === "Active") {
        return !isCompleted;
      }

      return true;
    });
  }, [contractList, activeTab]);

  const chatCardsJsx = isContractListLoading ? (
    <>
      <ContractCardLoading />
      <hr />
      <ContractCardLoading />
      <hr />
      <ContractCardLoading />
    </>
  ) : (
    filteredContractlist.map((contract, i) => {
      const isActive = contract.id === idFromUrl;

      return (
        <div
          key={contract.id}
          ref={(ref) => {
            if (isActive && ref) {
              ref.scrollIntoView({ block: "nearest", behavior: "smooth" });
            }
          }}
        >
          {i !== 0 && <hr />}
          <ContractCard contract={contract} active={isActive} />
        </div>
      );
    })
  );

  const activeContract = useMemo(
    () => contractList.find(({ id }) => id === idFromUrl) || null,
    [contractList, idFromUrl]
  );

  useEffect(() => {
    if (!activeContract || fixedInitialTab.current) {
      return;
    }

    const isCompleted = isContractCompleted(activeContract);

    if (isCompleted && activeTab !== ContractTab.Completed) {
      setActiveTab(ContractTab.Completed);
      fixedInitialTab.current = true;
    }

    if (!isCompleted && activeTab !== ContractTab.Active) {
      setActiveTab(ContractTab.Active);
      fixedInitialTab.current = true;
    }
  }, [activeContract, setActiveTab, activeTab]);

  if (hasNoContracts) {
    return null;
  }

  const onboardingPromptJsx = (
    <PendingContractorOnboardingPrompt className="mb-3 mt-3" />
  );

  return (
    <StyledContainer className={className}>
      {!!onboardingPromptJsx && (
        <>
          {onboardingPromptJsx}
          <hr />
        </>
      )}
      
      {chatCardsJsx}
    </StyledContainer>
  );
};

export default ContractList;
