import React, { useMemo } from "react";
import styled from "@emotion/styled";

import Card from "@/components/data/Card";
import {
  filterOutBrokenContracts,
  getContractCreateUrl,
  useOffersList,
} from "@/services/ContractsService";
import { SITE_PATHS } from "@/config/routing";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import Button from "@/components/input/Button";

import {
  StyledLink,
  StyledPrimaryHeading,
  StyledSecondaryHeading,
} from "../HomePage.styles";
import { StyledContractCard } from "./OffersSection/OffersSection.styles";
import { screenLargerThan, screenSmallerThan, useResponsive } from "@/styles";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }

  ${screenSmallerThan.tablet} {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }

  ${screenLargerThan.tablet} {
    min-height: 26.25rem;
    max-height: 20rem;
  }
`;

const StyledNoContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > *:first-child {
    flex: 1;
  }
`;

const ContractActionCard: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { data: offersList, isLoading } = useOffersList();
  const { isScreenSmallerThanTablet } = useResponsive();

  const contractlist = useMemo(
    () => filterOutBrokenContracts(offersList).slice(0, 2),
    [offersList]
  );

  const hasOffers = !!contractlist.length;

  if (isLoading) {
    return null;
  }

  if (isScreenSmallerThanTablet && !hasOffers) {
    return null;
  }

  return (
    <StyledCard className={className}>
      <StyledSecondaryHeading $bold>
        {isScreenSmallerThanTablet ? (
          <StyledPrimaryHeading>Recent offers</StyledPrimaryHeading>
        ) : (
          <>Recent offers</>
        )}

        {hasOffers && (
          <StyledLink to={SITE_PATHS.OFFERS_PAGE}>View all</StyledLink>
        )}
      </StyledSecondaryHeading>

      <hr className="mt-3" />

      {hasOffers ? (
        contractlist.map((contract, i) => (
          <StyledContractCard
            key={contract.id}
            contract={contract}
            className="mb-3"
          />
        ))
      ) : (
        <StyledNoContentContainer>
          <ImageTitleContent
            image="/assets/images/pages/contracts/no_contracts.svg"
            title={<>You don&apos;t have any offers</>}
            content={"Select the Plus (+) icon below to create an offer"}
          />
          <Button className="w-100" link={getContractCreateUrl()}>
            Create offer
          </Button>
        </StyledNoContentContainer>
      )}
    </StyledCard>
  );
};

export default ContractActionCard;
