import React, { useEffect, useRef } from "react";

import { ContractMilestone } from "@/services/ContractsService";

import {
  StyledBox,
  StyledDesktopOnlyStepHeading,
  StyledStep,
  StyledStepFloatingContent,
  StyledStepTopContent,
} from "../../ContractForm.styles";
import StepContinueButton from "../inputs/StepContinueButton";
import HeaderLayout from "../misc/HeaderLayout";
import TransactionNameInput from "../inputs/TransactionNameInput";
import ClientNameInput from "../inputs/ClientNameInput";
import FilesInput from "../inputs/TransactionFilesInput";
import DescriptionField from "../inputs/TransactionDescriptionField";
import MilestoneInput from "../inputs/MilestoneInput";
import { useContractForm } from "../../ContractForm.context";
import StepBackButton from "../inputs/StepBackButton";

const MilestoneContractDetailsStep: React.FC = () => {
  const { values, setFieldValue } = useContractForm();

  const milestonesRef = useRef(values.milestones as ContractMilestone[]);

  useEffect(() => {
    const milestoneWithValues = milestonesRef.current.filter(
      (milestone) => !!Object.keys(milestone).length
    );
    setFieldValue("milestones", milestoneWithValues);
  }, [setFieldValue]);

  return (
    <HeaderLayout>
      <StyledDesktopOnlyStepHeading className="mb-3">
        Create an offer
      </StyledDesktopOnlyStepHeading>

      <StyledBox>
        <StyledStep>
          <StyledStepTopContent>
            <TransactionNameInput />

            <ClientNameInput className="mt-3" />

            <DescriptionField className="mt-4" />

            <FilesInput className="mt-2" />

            <MilestoneInput className="mt-3" />
          </StyledStepTopContent>

          <StyledStepFloatingContent>
            <StepBackButton />
            <StepContinueButton>Review</StepContinueButton>
          </StyledStepFloatingContent>
        </StyledStep>
      </StyledBox>
    </HeaderLayout>
  );
};

export default MilestoneContractDetailsStep;
