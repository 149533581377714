"use client";
import React from "react";

import PageWithHeaderLayoutProps from "@/features/layouts/PageWithHeaderLayout";
import Card from "@/components/data/Card";
import { ContractType } from "@/services/ContractsService";
import { SITE_PATHS } from "@/config/routing";
import ContractShareButton from "@/features/contracts/ContractShareButton";

import {
  StyledContainer,
  StyledContentContainer,
} from "./ContractManagementPage.styles";
import { Tab } from "./ContractManagementPage.types";
import StatusSection from "./components/StatusSection";
import ReviewSubmissions from "./components/ReviewSubmissions";
import { useContractManagement } from "./ContractManagementPage.context";
import ActionsButton from "./components/ActionsButton";
import OtherUserDetails from "./components/OtherUserDetails";
import FilesSection from "./components/FilesSection";
import Tabs from "./components/Tabs";
import OverviewSection from "./components/OverviewSection";
import ActionsSection from "./components/ActionsSection";
import JobDetails from "./components/JobDetails";
import MilestonesDetails from "./components/MilestonesDetails";
import Icon from "@/components/misc/Icon";

const ContractManagementPage: React.FC = () => {
  const { contractDetails, activeTab, layoutProps } = useContractManagement();

  const { title, contract_type, id: contractId } = contractDetails;

  const isMilestoneContract = contract_type === ContractType.Milestone;

  return (
    <PageWithHeaderLayoutProps
      {...layoutProps}
      headerProps={{
        titleProps: { children: title },
        appendContent: (
          <>
            <ContractShareButton
              variant="ghost"
              colorVariant="black"
              contractId={contractId}
              contractDetails={contractDetails}
            >
              <Icon
                isSrcRelative
                src="share_2.svg"
                size="xs"
                colorVariant="black"
              />
            </ContractShareButton>
            <ActionsButton />
          </>
        ),
        ...(layoutProps?.headerProps || {}),
        backButtonProps: {
          link: SITE_PATHS.HOME_PAGE,
        },
      }}
    >
      <StyledContainer>
        <StyledContentContainer>
          <Card className="mt-3">
            <StatusSection />
          </Card>

          <Card className="mt-4">
            <OtherUserDetails />
          </Card>

          <Card className="mt-4">
            <Tabs />
            {activeTab === Tab.Overview && <OverviewSection className="mt-4" />}
            {activeTab === Tab.Details && (
              <>
                <div className="mt-4">
                  <Card className="mt-4">
                    <JobDetails />
                  </Card>
                  {isMilestoneContract && (
                    <Card className="mt-3">
                      <MilestonesDetails />
                    </Card>
                  )}
                </div>
              </>
            )}
          </Card>

          <FilesSection className="mt-3" />

          <ReviewSubmissions className="mt-3" />
        </StyledContentContainer>

        <ActionsSection className="p-3" />
      </StyledContainer>
    </PageWithHeaderLayoutProps>
  );
};

export default ContractManagementPage;
