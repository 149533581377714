import * as yup from "yup";

import {
  ContractCreatorType,
  ContractTransactionType,
  ContractType,
  MAX_MILESTONE_VALUE,
  MIN_MILESTONE_VALUE,
} from "@/services/ContractsService";

import {
  CLIENT_NAME_OPTIONAL,
  DATES_OPTIONAL,
  ERRORS,
} from "./ContractForm.config";

export function getYupValidationSchema({
  milestoneMaxValue = MAX_MILESTONE_VALUE,
}: {
  milestoneMaxValue?: number;
}) {
  return yup.object().shape({
    title: yup.string().required(),
    description: yup.string().required(),
    transaction_type: yup
      .string()
      .required()
      .oneOf(Object.values(ContractTransactionType)),
    contract_type: yup.string().required().oneOf(Object.values(ContractType)),
    created_as: yup
      .string()
      .required()
      .oneOf(Object.values(ContractCreatorType)),
    // client_email_number: yup.string().required(),
    client_name: CLIENT_NAME_OPTIONAL ? yup.string() : yup.string().required(),

    milestones: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(),
          description: yup.string().required(),
          // currency: yup.string().required(),
          value: yup
            .number()
            .required()
            .min(MIN_MILESTONE_VALUE, ERRORS.MIN_PRICE_ERROR)
            .max(
              milestoneMaxValue || MAX_MILESTONE_VALUE,
              ERRORS.MAX_PRICE_ERROR
            ),

          ...(DATES_OPTIONAL
            ? {}
            : {
                start_date: yup.string().required(),
                end_date: yup.string().required(),
              }),
        })
      )
      .min(1),
  });
}
