import useSWR from "swr";
import { useEffect, useMemo } from "react";
import dayjs from "dayjs";

import { appFeatures, contractService } from "@/config/services";
import useCountdown from "@/hooks/useCountdown";

import { getFormattedDuration } from "./useReviewContractMilestone/useReviewContractMilestone.utils";

function useReleasePaymentStatus(params: {
  contractId: string;
  milestoneId: number;
}) {
  const { contractId, milestoneId } = params;
  const { data, isLoading } = useSWR(
    !contractId || !milestoneId
      ? null
      : `/contract/${contractId}/milestone/${milestoneId}/release-fund-status`,
    () => contractService.getPaymentReleaseStatus(contractId, milestoneId)
  );

  const fundReleaseAllowedDate = useMemo(
    () =>
      data?.paymentReleasableDate
        ? new Date(data?.paymentReleasableDate)
        : null,
    [data?.paymentReleasableDate]
  );

  const passedWaitDuration = useMemo(() => {
    if (!fundReleaseAllowedDate) {
      return true;
    }

    const passedWaitDuration = dayjs(fundReleaseAllowedDate) < dayjs();
    return passedWaitDuration;
  }, [fundReleaseAllowedDate]);

  const { remainingTimeInMs, updateTargetTime, start } = useCountdown({
    targetTime: fundReleaseAllowedDate,
    countUp: true,
    startOnMount: !passedWaitDuration,
  });
  const canReleaseFund = passedWaitDuration || remainingTimeInMs >= 0;

  const remainingTimeFormatted = useMemo(
    () => getFormattedDuration(remainingTimeInMs),
    [remainingTimeInMs]
  );

  //-----------------------------

  useEffect(() => {
    if (fundReleaseAllowedDate) {
      updateTargetTime(fundReleaseAllowedDate);
      start();
    }
  }, [fundReleaseAllowedDate, updateTargetTime, start]);

  const hookData = useMemo(() => {
    const allowWitoutWait = appFeatures.isSupported(
      "CONTRACT.ALLOW_RELEASE_PAYMENT_WITHOUT_WAIT"
    );

    return {
      data,
      isLoading,
      remainingTimeInMs: allowWitoutWait ? 0 : remainingTimeInMs,
      canReleaseFund: allowWitoutWait ? true : canReleaseFund,
      remainingTimeFormatted,
    };
  }, [
    canReleaseFund,
    data,
    isLoading,
    remainingTimeFormatted,
    remainingTimeInMs,
  ]);

  //-----------------------------

  return hookData;
}

export default useReleasePaymentStatus;
