import { BREAKPOINT_NAMES } from "@/styles";
import Icon from "@/components/misc/Icon";

import { Tab } from "./ContractManagementPage.types";

export const TABS = [
  {
    id: Tab.Overview,
    title: "Overview",
  },
  {
    id: Tab.Details,
    title: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.75rem",
        }}
      >
        Details <Icon isSrcRelative src="edit_3.svg" size="xs" />
      </div>
    ),
  },
];

export const RESPONSEIVE_BREAKPOINT: BREAKPOINT_NAMES = "widescreen";
