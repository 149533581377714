import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { useNotifyForFeature } from "@/services/AppFeatures";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { appFeatures } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import TextField from "@/components/input/TextField";
import { useGenerateContractFromTranscript } from "@/services/ContractsService";
import { SITE_PATHS } from "@/config/routing";
import { Heading } from "@/components/Typography";

export const StyledIcon = styled(Icon)`
  filter: var(--filter-gray);
`;

export const StyledGradientButton = styled(Button)`
  gap: 0.4rem;
  padding: 1rem;
  min-height: 3rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  background: var(--clr-background-primary, #fff);
  border: 1px solid var(--clr-border-ukwn-2, #efefef);
  color: var(--clr-text-900, #0d0d12);
  border-radius: 1rem;

  & + & {
    margin-top: 0.8rem;
  }

  &:disabled,
  &[disabled] {
    color: var(--clr-ukwn-3, #a4acb9);
    pointer-events: none;
    cursor: not-allowed;
  }

  position: relative;
  background-color: #ffffff;
  background-image: url("/assets/images/misc/gradient.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;


  /* Frame 1000002760 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px;
gap: 12px;

width: 345px;
height: 53px;

background: radial-gradient(229.79% 7983.83% at 50% 50%, #564DFF 0%, #308BFF 44.4%, #00D8FF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
box-shadow: 0px 2px 19.3px rgba(4, 49, 162, 0.18);
border-radius: 15px;

/* Inside auto layout */
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;


  svg {
    display: none;
  }
`;

const GenerateContractWithAiButton: React.FC = () => {
  const { modalState: notifyModalState, jsx: notifyJsx } = useNotifyForFeature({
    featureName: "GENERATE_WITH_AI",
  });
  const contentModalState = useModalState();
  const [content, setContent] = useState("");
  const { generate, inProgress } = useGenerateContractFromTranscript();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (appFeatures.isSupported("CONTRACT.CREATE_WITH_AI")) {
      contentModalState.open();
    } else {
      notifyModalState.open();
    }
  }, [notifyModalState, contentModalState]);

  const handleContinueClick = useCallback(() => {
    generate({
      content,
    })?.then((aiGeneratedcontractDetails) => {
      navigate(
        {
          pathname: SITE_PATHS.CREATE_CONTRACT_PAGE,
        },
        {
          state: { aiGeneratedcontractDetails },
        }
      );
    });
  }, [content, generate, navigate]);

  const pasteFromClipboard = useCallback(() => {
    navigator.clipboard.readText().then((clipText) => {
      if (clipText) {
        setContent(clipText);
      }
    });
  }, []);

  return (
    <>
      {notifyJsx}

      <Modal
        width="980px"
        state={contentModalState}
        shouldCloseOnInteractOutside={() => !inProgress}
        mobileProps={{
          fullscreen: true,
        }}
      >
        <Heading size="lg">Generate with AI</Heading>
        <TextField
          textArea
          className="mt-3"
          variant="background"
          disabled={inProgress}
          value={content}
          onChange={setContent}
          style={{ minHeight: "70vh" }}
        />
        <Button
          disabled={inProgress}
          className="w-100 mt-3"
          variant="secondary"
          onClick={pasteFromClipboard}
        >
          Paste from clipboard
        </Button>
        <Button
          className="w-100 mt-3"
          disabled={inProgress}
          onClick={handleContinueClick}
        >
          {inProgress ? "Generating..." : "Continue"}
        </Button>
      </Modal>

      <StyledGradientButton className="mt-4" onClick={handleClick}>
        <StyledIcon
          isSrcRelative
          src="star_4_p.svg"
          size="xs"
          colorVariant="black"
        />
        Generate with AI
      </StyledGradientButton>
    </>
  );
};

export default GenerateContractWithAiButton;
