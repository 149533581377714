import React from "react";

import Portal from "@/components/misc/Portal";
import { useParentName } from "@/services/debug";
import { IS_STAGING_ENV } from "@/config/app";
import { Body } from "@/components/Typography";

import { StyledLogo, StyledPage } from "./SplashScreen.styles";

const StagingSplashScreen: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { ref, path } = useParentName({
    parentLevel: 7,
  });

  return (
    <Portal>
      <StyledPage ref={ref as any}>
        {children || <StyledLogo src="/assets/images/branding/logo.gif" />}
        <Body size="lg" className="mt-4" style={{ textAlign: "center" }}>
          Loader Source : {path || "-"}
        </Body>
      </StyledPage>
    </Portal>
  );
};

const SplashScreen: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  if (IS_STAGING_ENV) {
    return <StagingSplashScreen>{children}</StagingSplashScreen>;
  }

  return (
    <Portal>
      <StyledPage>
        {children || <StyledLogo src="/assets/images/branding/logo.gif" />}
      </StyledPage>
    </Portal>
  );
};

export default SplashScreen;
