import React from "react";

import Logo from "@/features/app/Logo";
import PwaInstallButton from "@/features/app/PwaInstallButton";

import {
  StyledHeader,
  StyledLogoContainer,
  StyledSearchButton,
} from "./Header.styles";
import { IHeaderProps } from "./Header.types";
import UserAvatar from "./components/UserAvatar";

const Header: React.FC<IHeaderProps> = ({
  logo: { component: logoComponent } = { component: undefined },
  appendContent,
  prependContent,
}) => {
  return (
    <StyledHeader>
      {prependContent?.children}

      <StyledLogoContainer>
        {logoComponent === undefined ? <Logo /> : logoComponent}
      </StyledLogoContainer>

      <StyledSearchButton />

      <PwaInstallButton />

      <UserAvatar />

      {appendContent?.children}
    </StyledHeader>
  );
};

export default Header;
