import React, { useMemo, useState } from "react";

import { Body } from "@/components/Typography";
import RadioCardGroup, { Item } from "@/components/input/RadioCardGroup";
import {
  CONTRACT_CREATOR_TYPE_CONFIG,
  ContractCreatorType,
} from "@/services/ContractsService";
import { useResponsive } from "@/styles";

import {
  StyledBox,
  StyledStep,
  StyledStepFloatingContent,
  StyledStepHeading,
  StyledStepTopContent,
} from "../../../ContractForm.styles";
import { useContractForm } from "../../../ContractForm.context";
import StepContinueButton from "../../inputs/StepContinueButton";
import HeaderLayout from "../../misc/HeaderLayout";
import NotifySoon from "../../misc/NotifySoon";
import StepBackButton from "../../inputs/StepBackButton";
import { useNotifyForFeature } from "@/services/AppFeatures";

const ContractCreatorTypeStep: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { values, setFieldValue } = useContractForm();

  const [selectedOption, setSelectedOption] = useState(values.created_as || "");

  const { modalState, jsx } = useNotifyForFeature({
    featureName: "CREATE_OFFER_AS_CLIENT",
    onModalOpenChange: (isOpen) => {
      if (!isOpen) {
        setSelectedOption("" as any);
        setFieldValue("created_as", "");
      }
    },
  });

  const Options = useMemo(
    () =>
      Object.entries(CONTRACT_CREATOR_TYPE_CONFIG).map(
        ([userType, { displayName }]) =>
          ({ children: displayName, value: userType } satisfies Item)
      ),
    []
  );

  return (
    <>
      {jsx}
      <HeaderLayout>
        <StyledStep>
          <StyledStepTopContent>
            <StyledStepHeading>Create an offer as a</StyledStepHeading>
            <Body
              size="md"
              className="mt-2"
              style={
                isScreenSmallerThanTablet
                  ? {
                      color: "var(--clr-icon-text-200, #818898)",
                      maxWidth: "34ch",
                      marginBottom: "3.5rem",
                    }
                  : {
                      color: "var(--clr-icon-text-200, #818898)",
                      marginBottom: "2rem",
                    }
              }
            >
              This information will determine how your transaction is created.
            </Body>

            <StyledBox
              style={
                isScreenSmallerThanTablet
                  ? {}
                  : {
                      color: "var(--clr-icon-text-200, #818898)",
                      paddingTop: "3.5rem",
                      flexGrow: 1,
                    }
              }
            >
              <RadioCardGroup
                label="contract-creator-type"
                value={selectedOption || ""}
                onChange={(value: string) => {
                  if (value === ContractCreatorType.Payer) {
                    modalState.open();
                    setFieldValue("created_as", "");
                  } else {
                    setFieldValue("created_as", value);
                  }

                  setSelectedOption(value as ContractCreatorType);
                }}
                items={Options}
                itemStyles={{ minHeight: "5rem" }}
              />

              {false && selectedOption === ContractCreatorType.Payer && (
                <NotifySoon className="mt-3" onClick={modalState.open}>
                  Create offer as client is coming soon! Would you like us to
                  notify you when this feature is live?
                </NotifySoon>
              )}

              {!isScreenSmallerThanTablet && (
                <StyledStepFloatingContent
                  style={{ padding: 0, marginTop: "8rem" }}
                >
                  <StepBackButton />
                  <StepContinueButton />
                </StyledStepFloatingContent>
              )}
            </StyledBox>
          </StyledStepTopContent>

          {isScreenSmallerThanTablet && (
            <StyledStepFloatingContent>
              <StepContinueButton />
            </StyledStepFloatingContent>
          )}
        </StyledStep>
      </HeaderLayout>
    </>
  );
};

export default ContractCreatorTypeStep;
