import { useCallback, useState } from "react";
import lodashSet from "lodash/set";
import lodashGet from "lodash/get";
import styled from "@emotion/styled";

import { analytics, contractService } from "@/config/services";
import Modal, { useModalState } from "@/components/misc/Modal";
import ContractEmailShareForm, {
  ShareFormValue,
} from "@/features/contracts/ContractEmailShareForm";
import { Body, Display, Heading } from "@/components/Typography";
import { useToast } from "@/components/misc/Toast";
import RatingInput from "@/components/input/RatingInput";
import Button from "@/components/input/Button";
import { UserProfile, useUpdateProfile } from "@/services/UserService";
import Icon from "@/components/misc/Icon";
import { useNavigate } from "@/services/Routing";
import { getViewPortHeightCssString, screenSmallerThan } from "@/styles";
import { ShareOfferSource } from "@/services/Analytics";
import Drawer from "@/components/misc/Drawer";
import EditorField from "@/components/input/EditorFieldNew";

import { ContractCompleteDetails } from "../ContractsService.types";
import { OFFER_FEEDBACK_KEY } from "../ContractsService.config";

const StyledImage = styled.img`
  width: 7rem;
  margin-inline: auto;
`;

const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 0.6rem;
  right: 0;
  z-index: 2;
`;

const StyledFeedbackContent = styled.div`
  .email {
    font-weight: 400;
    font-size: 1rem;
  }

  .email,
  .content {
    color: #4e5876;
  }

  .cta-button {
    margin-top: 2rem;
  }

  ${screenSmallerThan.tablet} {
    display: flex;
    flex-direction: column;
    min-height: calc(${getViewPortHeightCssString()} - 3rem);

    .cta-button {
      margin-top: auto;
    }
  }
`;

const StyledEditorField = styled(EditorField)`
  margin-bottom: 2rem;

  .placeholder {
    padding-bottom: 6rem !important;
  }
`;

function useShareOfferViaEmail({
  contractDetails,
  share_source,
  successRedirectRoute,
  tempSolutionTest,
  scrollToTopDelayInMs,
  customOfferFeedback,
}: {
  contractDetails: ContractCompleteDetails;
  share_source: ShareOfferSource;
  successRedirectRoute?: string;
  tempSolutionTest?: "fullscreen" | "scroll" | "scroll-fullscreen" | "default";
  scrollToTopDelayInMs?: number;
  customOfferFeedback?: React.ReactNode;
}) {
  const emailFormModalState = useModalState({
    urlHash: tempSolutionTest ? undefined : "#share-by-email",
  });
  const feedbackFormModalState = useModalState();
  const { createToast } = useToast();
  const { navigate } = useNavigate();

  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(true);
  const [formValues, setFormValues] = useState<ShareFormValue | null>(null);

  const [experienceRating, setExperienceRating] = useState(0);
  const [experienceFeedback, setExperienceFeedback] = useState("");

  const {
    profileData,
    update: updateProfileData,
    isUpdating: isUpdatingFeedbackData,
  } = useUpdateProfile();

  const hasFeedbackFromBefore =
    !!lodashGet(
      profileData,
      `misc.featuresFeedback[${OFFER_FEEDBACK_KEY}].rating`
    ) ||
    !!lodashGet(
      profileData,
      `misc.featuresFeedback[${OFFER_FEEDBACK_KEY}].content`
    );

  //---------------------------------

  const triggerSuccessRedirect = useCallback(() => {
    if (successRedirectRoute) {
      navigate(successRedirectRoute);
    }
  }, [successRedirectRoute, navigate]);

  const { id, title } = contractDetails;
  const handleSubmit = useCallback(
    (values: ShareFormValue) => {
      const { name, email } = values;
      setFormValues(values);

      if (!!tempSolutionTest) {
        return Promise.resolve().then(() => {
          emailFormModalState.close();
          feedbackFormModalState.open();
        });
      }

      if (isSending || !id || !email || !name) {
        return Promise.reject();
      }

      setIsSending(true);
      setIsSent(false);

      contractService
        .shareOfferViaEmail({
          clientName: name,
          email,
          offerId: id,
          offerName: title,
        })
        .then(() => {
          setIsSent(true);
          emailFormModalState.close();

          analytics.triggerShareOfferEvent("share_offer", {
            source: share_source,
            share_type: "email",
            client_name: name,
            email,
            offer_id: id,
            offer_name: title,
          });

          if (hasFeedbackFromBefore) {
            triggerSuccessRedirect();

            setTimeout(() => {
              createToast({
                title: `Offer sent to ${email}`,
                description: `Please remind your clients to check their spam or promotions folder if
            they don't see our email in their inbox.`,
                timeoutInMilliseconds: 15000,
              });
            }, 1000);
          } else {
            feedbackFormModalState.open();
          }
        })
        .finally(() => {
          setIsSending(false);
        });
    },
    [
      id,
      title,
      emailFormModalState,
      createToast,
      isSending,
      feedbackFormModalState,
      hasFeedbackFromBefore,
      triggerSuccessRedirect,
      share_source,
      tempSolutionTest,
    ]
  );

  const triggerShare = useCallback(
    () => emailFormModalState.open(),
    [emailFormModalState]
  );

  //-----------------------

  const submitFeedback = useCallback(() => {
    if (isUpdatingFeedbackData) {
      return;
    }

    analytics.triggerFeedbackEvent("feature_feedback", {
      feature_name: OFFER_FEEDBACK_KEY,
      rating: experienceRating,
    });

    const updatedProfileData = JSON.parse(
      JSON.stringify(profileData)
    ) as UserProfile;
    lodashSet(
      updatedProfileData,
      `misc.featuresFeedback[${OFFER_FEEDBACK_KEY}]`,
      {
        rating: experienceRating,
        content: experienceFeedback,
      }
    );

    return updateProfileData(updatedProfileData, { notify: false })
      .then(() => {
        feedbackFormModalState.close();

        createToast({
          title: `Thank you for your feedback!`,
          description: `Thank you for taking the time to share your feedback with us.`,
          timeoutInMilliseconds: 10000,
        });

        triggerSuccessRedirect();
      })
      .catch(() => {
        analytics.triggerFeedbackEvent("feature_feedback_save_failed", {
          feature_name: OFFER_FEEDBACK_KEY,
        });
      });
  }, [
    isUpdatingFeedbackData,
    profileData,
    updateProfileData,
    experienceRating,
    experienceFeedback,
    feedbackFormModalState,
    createToast,
    triggerSuccessRedirect,
  ]);

  //------------------------------------

  const emailFormModalJsx = (
    <Drawer fullscreenByDefault state={emailFormModalState}>
      <div className="p-3">
        <Display size="md">Enter details</Display>
        <ContractEmailShareForm
          className="mt-4"
          onSubmit={handleSubmit}
          isSending={isSending}
          tempSolutionTest={
            tempSolutionTest === "scroll" ||
            tempSolutionTest === "scroll-fullscreen"
          }
          scrollToTopDelayInMs={scrollToTopDelayInMs}
        />
      </div>
    </Drawer>
  );

  const feedbackFormModalJsx = (
    <Modal
      allowScroll
      isKeyboardDismissDisabled
      isDismissable={false}
      shouldCloseOnInteractOutside={() => false}
      state={feedbackFormModalState}
      width="580px"
      mobileProps={{ fullscreen: true }}
    >
      <StyledFeedbackContent>
        <StyledCloseButton
          variant="ghost"
          onClick={feedbackFormModalState.close}
        >
          <Icon isSrcRelative src="cross.svg" size="xs" />
        </StyledCloseButton>
        <StyledImage src="/assets/images/misc/feedback.svg" alt="" />

        <Heading className="text-center" size="md">
          Offer sent to &nbsp;<span className="email">{formValues?.email}</span>
        </Heading>

        <Body className="content mt-3 text-center" size="md">
          Please remind your clients to check their spam or promotions folder if
          they don't see our email in their inbox.
        </Body>

        {customOfferFeedback || (
          <>
            <hr className="mt-4 mb-4" />

            <Body className="content text-center" size="lg">
              We value your feedback! Please tell us about your experience
              creating and sending an offer.
            </Body>

            <Heading size="md" className="mt-4">
              How was your experience creating an offer?
            </Heading>
            <RatingInput
              useNumbers
              className="mt-3"
              value={experienceRating}
              onChange={setExperienceRating}
              disabled={isUpdatingFeedbackData}
            />
            <Heading size="md" className="mt-4">
              Tell us in your words:
            </Heading>

            <StyledEditorField
              showSaveButton
              label=""
              variant="background"
              placeholderVariant="bg-input"
              placeholder="Write your feedback here"
              value={{ markdown: experienceFeedback }}
              onChange={({ markdown }) => {
                setExperienceFeedback(markdown || "");
              }}
              drawerTitle="Tell us in your words"
              prependContent={null}
            />

            <Button
              disabled={isUpdatingFeedbackData}
              className="cta-button w-100"
              onClick={submitFeedback}
            >
              {isUpdatingFeedbackData ? "Submitting" : "Submit"}
            </Button>
          </>
        )}
      </StyledFeedbackContent>
    </Modal>
  );

  //------------------------------------

  return {
    isSending,
    isSent,
    formModalJsx: (
      <>
        {emailFormModalJsx} {isSent && feedbackFormModalJsx}
      </>
    ),
    triggerShare,
  };
}

export default useShareOfferViaEmail;
