import { ApiService } from "@/services/Api";

import { PAY_PIPE_API_URL } from "@/config/api";
import Authentication from "@/services/Authentication";
import ContractsService from "@/services/ContractsService";
import MediaService from "@/services/MediaService";
import UserService from "@/services/UserService";
import AppFeatures from "@/services/AppFeatures";
import AppSettings from "@/services/AppSettings";
import { Analytics } from "@/services/Analytics";
import { PushNotifications } from "@/services/PushNotifications";
import ErrorReporting from "@/services/ErrorReporting";
import Location from "@/services/Location";
import ChatService from "@/services/ChatServiceNew";
import Feedback from "@/services/Feedback";

export const api = new ApiService({ baseUrl: PAY_PIPE_API_URL });

export const authService = new Authentication(api);

export const contractService = new ContractsService(api);

export const mediaService = new MediaService(api);

export const userService = new UserService(api);

export const appFeatures = new AppFeatures();

export const appSettings = new AppSettings();

export const analytics = new Analytics();

export const pushNotifications = new PushNotifications();

export const location = new Location();

export const chatService = new ChatService(api);

export const feedbackService = new Feedback(api);

export const errorReporting = new ErrorReporting();
