import { useCallback } from "react";
import styled from "@emotion/styled";

import { contractService } from "@/config/services";
import { getFileExtentionFromName, isBlobUrl, openLink } from "@/utils/files";
import Modal, { useModalState } from "@/components/misc/Modal";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { useFileSignedUrl } from "@/services/ContractsService";
import FilePreviewer from "@/components/misc/FilePreviewer";
import { screenLargerThan } from "@/styles";

const StyledLayout = styled(PageWithHeaderLayout)`
  height: 100%;

  ${screenLargerThan.tablet} {
    height: 48rem;
    max-height: calc(100vh - 4.5rem);
    overflow: hidden;

    main {
      overflow: hidden;
    }
  }
`;

function usePreviewFile(params: {
  fileUrl?: string;
  file?: File;
  fileName?: string;
  allowDownload?: boolean;
}) {
  const {
    fileUrl: fileUrlFromProps = "",
    file,
    fileName = "",
    allowDownload,
  } = params;
  const modalState = useModalState();
  const { url: presignedUrl, isLoading } = useFileSignedUrl(
    file ? "" : fileUrlFromProps
  );

  const fileUrl =
    (isBlobUrl(fileUrlFromProps) ? fileUrlFromProps : presignedUrl) || "";
  const fileExtention = file
    ? getFileExtentionFromName(file.name) || ""
    : getFileExtentionFromName(fileUrl) || "";

  const preview = useCallback(() => {
    if (!file && !fileUrl) {
      return;
    }
    const usePreviewer = !["pdf"].includes(fileExtention);
    if (usePreviewer) {
      modalState.open();
    } else {
      if (fileUrl) {
        if (isBlobUrl(fileUrl)) {
          openLink(fileUrl);
          return;
        }

        const isLink = fileUrl.includes("https://");
        if (isLink) {
          openLink(fileUrl);
        } else {
          contractService.getFileSignedUrl(fileUrl).then((res) => {
            const fileUrl = res.data.data.url;

            openLink(fileUrl);
          });
        }
      }
    }
  }, [fileUrl, file, modalState, fileExtention]);

  const filePreviewJsx = (
    <Modal
      mobileProps={{ fullscreen: true, className: "p-0" }}
      desktopProps={{ width: "1020px" }}
      className="p-0"
      state={modalState}
    >
      <StyledLayout
        stickyHeader
        headerProps={{
          backButtonProps: { onClick: modalState.close },
          titleProps: {
            children: fileName ? `Preview of ${fileName}` : "Preview",
          },
        }}
      >
        {isLoading ? null : (
          <FilePreviewer
            allowDownload={allowDownload}
            url={fileUrl}
            extention={fileExtention}
          />
        )}
      </StyledLayout>
    </Modal>
  );

  return {
    preview,
    filePreviewJsx,
    fileUrl,
    fileExtention,
  };
}

export default usePreviewFile;
