import React from "react";

import { StyledContainer } from "./MilestonesDisplay.styles";
import { IMilestoneInputProps } from "./MilestonesDisplay.types";
import MilestoneCard from "./components/MilestoneCard";

const MilestonesDisplay: React.FC<IMilestoneInputProps> = ({
  milestones = [],
  className,
}) => {
  return (
    <StyledContainer className={className}>
      {milestones.map((milestone) => (
        <MilestoneCard key={milestone.id} milestone={milestone} />
      ))}
    </StyledContainer>
  );
};

export default MilestonesDisplay;
