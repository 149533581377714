import { useMemo } from "react";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import {
  getNextActiveMilestone,
  useReviewContractMilestone,
} from "@/services/ContractsService";

import { IReleasePaymentButtonProps } from "./ReleasePaymentButton.types";

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }

  & > *:last-child {
    flex: 2;
  }
`;

const ReleasePaymentButton: React.FC<IReleasePaymentButtonProps> = ({
  contractDetails,
  ...restProps
}) => {
  const nextActiveMilestone = useMemo(
    () => getNextActiveMilestone(contractDetails).milestone,
    [contractDetails]
  );
  const {
    approve: handleReleasePayment,
    isApproving: isReleasing,
    jsx: releasePaymentHookJsx,
    loading,
  } = useReviewContractMilestone({
    contractId: contractDetails?.id || "",
    milestoneId: nextActiveMilestone?.id || 0,
  });

  const submitButtonText = isReleasing
    ? "Releasing Payment..."
    : "Release Payment";

  return (
    <>
      {releasePaymentHookJsx}

      <Button
        {...restProps}
        disabled={isReleasing || loading || restProps.disabled}
        onClick={handleReleasePayment}
      >
        {submitButtonText}
      </Button>
    </>
  );
};

export default ReleasePaymentButton;
