"use client";

import React, { useCallback, useState } from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import {
  useManageOnboardingCountry,
  useOnboardStripe,
} from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";

const OnboardStripeButton: React.FC<IButtonProps> = ({
  children,
  onClick,
  ...restProps
}) => {
  const { isOnboarding, onboard } = useOnboardStripe();
  const { onboardingCountry } = useManageOnboardingCountry();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      if (!onboardingCountry) {
        return;
      }

      setIsLoading(true);

      if (onClick) {
        onClick(event);
      }

      onboard({
        countryCode: onboardingCountry,
      })?.finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
      });
    },
    [onboard, onboardingCountry, onClick]
  );

  return (
    <>
      {(isOnboarding || isLoading) && <SplashScreen />}
      <Button {...restProps} onClick={handleClick}>
        {children || "Onboard"}
      </Button>
    </>
  );
};

export default OnboardStripeButton;
