import { useMemo } from "react";

import {
  useManageOnboardingCountry,
  useOnboardStripe,
} from "@/services/UserService";
import { amIContractPayee, useContractList } from "@/services/ContractsService";

const usePendingContractorOnboardingPrompt = () => {
  const { data, isLoading } = useContractList();
  const { isOnboarded, isReady } = useOnboardStripe();
  const { isStrictOnboardCountry, onboardingCountry } =
    useManageOnboardingCountry();

  const show = useMemo(() => {
    if (onboardingCountry && isStrictOnboardCountry && !isOnboarded) {
      return true;
    }

    const hasContractsAsPayer = !!data.filter((contract) =>
      amIContractPayee(contract)
    ).length;

    return hasContractsAsPayer && !isOnboarded;
  }, [data, isOnboarded, onboardingCountry, isStrictOnboardCountry]);

  if (isLoading || !isReady) {
    return {
      show: false,
    };
  }

  return {
    show,
  };
};

export default usePendingContractorOnboardingPrompt;
