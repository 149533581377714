import React from "react";
import styled from "@emotion/styled";

import { usePwa } from "@/services/Pwa";
import Button, { IButtonProps } from "@/components/input/Button";
import { screenSmallerThan } from "@/styles";

const StyledButton = styled(Button)`
  ${screenSmallerThan.tablet} {
    padding-block: 0.25rem;
  }
`;

const PwaInstallButton: React.FC<IButtonProps> = ({
  children,
  ...restProps
}) => {
  const { canInstall, triggerInstall, isPwaInstalled, isReady } = usePwa();

  if (!canInstall || isPwaInstalled || !isReady) {
    return null;
  }

  return (
    <StyledButton {...restProps} variant="secondary" onClick={triggerInstall}>
      Install
    </StyledButton>
  );
};

export default PwaInstallButton;
