"use client";

import React from "react";
import dayjs from "dayjs";
import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { ContractCreationFlow } from "@/services/ContractsService";
import { Body, Heading } from "@/components/Typography";
import { useAuth } from "@/services/Authentication";
import { FeedbackFeature, useFeedbackDetails } from "@/services/Feedback";
import { ANIMATION_CLASSNAMES } from "@/styles";
import { joinClassNames } from "@/utils/classNames";
import useToggle from "@/hooks/useToggle";

import { useContractForm } from "../../ContractForm.context";

const StyledModalActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  & > * {
    border-radius: 10rem !important;
  }

  & > *:last-child {
    flex-grow: 1;
  }

  & > *:first-child {
    flex-grow: 3;
  }
`;

const StyledPennyLogo = styled.img`
  display: block;
  margin-inline: 0;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledGradientContainer = styled.div`
  text-align: center;
  color: #fff;
  background: linear-gradient(
    240.96deg,
    #bd9cff -7.5%,
    #7f86ea 51.55%,
    #86d8ff 94.1%
  );
  padding: 1.75rem;
  padding-bottom: 1.5rem;
  border-radius: 1.125rem;
  width: 100%;
  max-width: 760px;
  margin-inline: auto;
`;

const StyledDrawerContainer = styled.div`
  text-align: center;
  background: #fff;
  padding: 1rem;
  padding-top: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
  width: 100%;
  max-width: 760px;
  margin-inline: auto;
  box-shadow: 0px -3px 45.6px 0px rgba(91, 93, 94, 0.15);
`;

const AiFeedback: React.FC<{
  variant?: "drawer" | "gradient-card";
  className?: string;
  successRedirectRoute?: string;
}> = ({ variant = "drawer", className, successRedirectRoute }) => {
  const { userData } = useAuth();
  const { flow } = useContractForm();
  const { data, isLoading } = useFeedbackDetails({
    feature: FeedbackFeature.AiContractGeneration,
  });

  const isAiFlow = flow === ContractCreationFlow.AI;

  const providedFeedbackBefore = !!data;

  const { disable: hide, state: canShow } = useToggle({
    initialValue: () => {
      const dateString = localStorage.getItem(
        "PAYPIPE:LAST_CLOSED_PENNY_FEEDBACK_PROMPT"
      );
      const date = dateString ? new Date(dateString) : null;

      if (date) {
        const diffInDays = Math.abs(dayjs().diff(dayjs(date), "day"));

        if (diffInDays >= 1) {
          return true;
        }

        return false;
      }

      return true;
    },
    onChange: (state) => {
      if (state === false) {
        localStorage.setItem(
          "PAYPIPE:LAST_CLOSED_PENNY_FEEDBACK_PROMPT",
          new Date().toUTCString()
        );
      }
    },
  });

  if (!isAiFlow || !userData || providedFeedbackBefore || !canShow) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  const { email, given_name } = userData;
  const TALLY_FORM_LINK = `https://tally.so/r/3Xz40V?name=${given_name}&email=${email}`;

  if (variant === "gradient-card") {
    return (
      <StyledGradientContainer
        className={joinClassNames(
          className,
          ANIMATION_CLASSNAMES.FADE_IN_FROM_TOP
        )}
      >
        <Heading size="lg">How did I do?</Heading>
        <Body size="lg" className="mt-2">
          Could you spare a moment to share your experience? Your insights help
          me improve.
        </Body>
        <StyledModalActionsContainer className="mt-4">
          <Button
            openInNewTab
            link={TALLY_FORM_LINK}
            colorVariant="white-primary"
            onClick={hide}
          >
            Provide Feedback
          </Button>
          <Button
            variant="secondary"
            colorVariant="white-primary"
            onClick={hide}
            link={successRedirectRoute}
          >
            Maybe Later
          </Button>
        </StyledModalActionsContainer>
      </StyledGradientContainer>
    );
  }

  return (
    <StyledDrawerContainer
      className={joinClassNames(
        className,
        ANIMATION_CLASSNAMES.FADE_IN_FROM_BOTTOM
      )}
    >
      <StyledLogoContainer>
        <StyledPennyLogo
          alt="Logo"
          src="/assets/images/branding/penny/logo_round.svg"
        />
      </StyledLogoContainer>
      <Heading size="lg" className="mt-2">
        How did I do?
      </Heading>
      <Body size="lg" className="mt-2">
        Could you spare a moment to share your experience? Your insights help me
        improve.
      </Body>

      <Button
        openInNewTab
        className="w-100 mt-5"
        link={TALLY_FORM_LINK}
        onClick={hide}
      >
        Provide Feedback
      </Button>
      <Button
        className="w-100 mt-2"
        variant="ghost"
        colorVariant="black"
        link={successRedirectRoute}
        onClick={hide}
      >
        Maybe Later
      </Button>
    </StyledDrawerContainer>
  );
};

export default AiFeedback;
