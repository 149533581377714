import React, { useCallback, useMemo } from "react";

import Button, { IButtonProps } from "@/components/input/Button";
import {
  ContractId,
  useContractDetails,
  useUpdateOffer,
} from "@/services/ContractsService";
import Modal, { useModalState } from "@/components/misc/Modal";
import OfferUpdateForm, {
  OfferFormValues,
} from "@/features/contracts/OfferUpdateForm";
import PageWithHeaderLayout from "@/features/layouts/PageWithHeaderLayout";
import { appFeatures } from "@/config/services";

const EditOfferButton: React.FC<
  IButtonProps & {
    contractId: ContractId;
  }
> = ({ contractId, children, ...buttonProps }) => {
  const formModalState = useModalState();
  const { updateOffer, isUpdating, jsx } = useUpdateOffer({
    contractId,
    onSuccess: formModalState.close,
  });

  const { contractDetails: contractDetailsFromHook } = useContractDetails({
    contractId,
  });
  const initialFormValues = useMemo(() => {
    if (!contractDetailsFromHook) {
      return undefined;
    }

    return {
      title: contractDetailsFromHook.title,
      description: contractDetailsFromHook.description,
      transaction_type: contractDetailsFromHook.transaction_type,
      contract_type: contractDetailsFromHook.contract_type,
      files: contractDetailsFromHook.files.map((mediaFile) => ({ mediaFile })),
      milestones: contractDetailsFromHook.milestones.map((milestone) => ({
        id: milestone.id,
        name: milestone.name,
        description: milestone.name,
        start_date: milestone.start_date,
        end_date: milestone.end_date,
        value: +milestone.value,
        currency: milestone.currency,
      })),
    } satisfies OfferFormValues;
  }, [contractDetailsFromHook]);

  const buttonText = isUpdating ? "Updating Offer..." : "Update Offer";

  const handleUpdateOffer = useCallback(
    async (values: OfferFormValues) => {
      updateOffer(values);
    },
    [updateOffer]
  );

  if (!appFeatures.isSupported("CONTRACT.UPDATE_OFFER")) {
    return null;
  }

  if (!initialFormValues) {
    return null;
  }

  return (
    <>
      {jsx}

      <Modal
        shouldCloseOnInteractOutside={() => !isUpdating}
        state={formModalState}
        contentContainerProps={{ style: { padding: 0 } }}
        width="1020px"
        mobileProps={{
          fullscreen: true,
        }}
      >
        <PageWithHeaderLayout
          headerProps={{
            titleProps: {
              children: "Edit Offer",
            },
            backButtonProps: {
              onClick: formModalState.close,
              disabled: isUpdating,
            },
          }}
        >
          <div className="p-3">
            <OfferUpdateForm
              initialValues={initialFormValues}
              onSubmit={handleUpdateOffer}
              submitButtonProps={{
                disabled: isUpdating,
                children: buttonText,
              }}
            />
          </div>
        </PageWithHeaderLayout>
      </Modal>
      <Button
        variant="secondary"
        {...buttonProps}
        disabled={isUpdating}
        onClick={formModalState.open}
      >
        {children || buttonText}
      </Button>
    </>
  );
};

export default EditOfferButton;
