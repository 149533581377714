import React, { useEffect, useRef, useState } from "react";

import Drawer, { useDrawerState } from "@/components/misc/Drawer";
import ImageTitleContent from "@/components/data/ImageTitleContent";
import styled from "@emotion/styled";
import { screenSmallerThan } from "@/styles";
import LocalStorage from "@/services/LocalStorage";
import OnboardStripeButton from "@/features/user/OnboardStripeButton";

import usePendingContractorOnboardingPrompt from "./hooks/usePendingContractorOnboardingPrompt";

const StyledContainer = styled.div`
  ${screenSmallerThan.tablet} {
    padding: 1rem;
  }
`;

const PendingContractorOnboardingModal: React.FC = () => {
  const { show } = usePendingContractorOnboardingPrompt();
  const drawerState = useDrawerState();
  const drawerStateRef = useRef(drawerState);
  const localStorage = useRef(
    new LocalStorage<{
      shown_onboard_prompt: boolean;
    }>("PAYPIPE.ONBOARDING")
  ).current;
  const [shownOnboardPrompt] = useState<boolean>(
    localStorage.get("shown_onboard_prompt", false)
  );

  useEffect(() => {
    if (show) {
      drawerStateRef.current.open();
      localStorage.set("shown_onboard_prompt", true);
    }
  }, [show, localStorage]);

  if (!show || shownOnboardPrompt) {
    return null;
  }

  return (
    <Drawer state={drawerState}>
      <StyledContainer>
        <ImageTitleContent
          image="/assets/images/pages/contracts/onboard_red.svg"
          title="Complete verification"
          content="You need to complete the identity verification to access your earnings."
        />
        <OnboardStripeButton className="w-100 mt-4">
          Verify me
        </OnboardStripeButton>
      </StyledContainer>
    </Drawer>
  );
};

export default PendingContractorOnboardingModal;
