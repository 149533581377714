"use client";

import React from "react";
import lodashGet from "lodash/get";
import styled from "@emotion/styled";

import { PayoutStatus } from "@/services/UserService";
import { Label } from "@/components/Typography";
import Icon from "@/components/misc/Icon";

const STATUS_CONFIG: Record<
  any,
  { color: string; bgColor: string; label: string; icon: string }
> = {
  [PayoutStatus.PROCESSING]: {
    color: "#F2AE40",
    bgColor: "#FFFBEB",
    label: "Processing",
    icon: "cash-flow/in_review.svg",
  },
  [PayoutStatus.DEFAULT]: {
    color: "#F2AE40",
    bgColor: "#FFFBEB",
    label: "In Review",
    icon: "cash-flow/in_review.svg",
  },
  [PayoutStatus.IN_REVIEW]: {
    color: "#F2AE40",
    bgColor: "#FFFBEB",
    label: "In Review",
    icon: "cash-flow/in_review.svg",
  },
  [PayoutStatus.IN_TRANSIT]: {
    color: "#5F57FF",
    bgColor: "rgba(95, 87, 255, 0.1)",
    label: "In Transit",
    icon: "cash-flow/in_transit.svg",
  },
  [PayoutStatus.DEPOSITED]: {
    color: "#16A34A",
    bgColor: "rgba(240, 253, 244, 1) ",
    label: "Paid out",
    icon: "cash-flow/paid_out.svg",
  },
  [PayoutStatus.AVAILABLE]: {
    color: "#16A34A",
    bgColor: "rgba(240, 253, 244, 1) ",
    label: "Available",
    icon: "cash-flow/paid_out.svg",
  },
  [PayoutStatus.REJECTED]: {
    color: "rgba(220, 38, 38, 1)",
    bgColor: "rgba(254, 242, 242, 1)",
    label: "Rejected",
    icon: "cash-flow/rejected.svg",
  },
  [PayoutStatus.CANCELLED]: {
    color: "rgba(220, 38, 38, 1)",
    bgColor: "rgba(254, 242, 242, 1)",
    label: "Cancelled",
    icon: "cash-flow/rejected.svg",
  },
  [PayoutStatus.PENDING_VERIFICATION]: {
    color: "rgba(249, 115, 22, 1)",
    bgColor: "rgba(254, 242, 242, 1)",
    label: "Pending verification",
    icon: "cash-flow/pending_verification.svg",
  },
};

const StyledContainer = styled.div<{
  $status: PayoutStatus;
}>`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  font-size: 0.815rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;

  ${({ $status }) => {
    const color = lodashGet(STATUS_CONFIG, `[${$status}].color`);
    const bgColor = lodashGet(STATUS_CONFIG, `[${$status}].bgColor`);
    return `
          color: ${color};
          background: ${bgColor};
        `;
  }};
`;

const TransactionStatus: React.FC<{
  payoutStatus: PayoutStatus;
  className?: string;
}> = ({ payoutStatus, className }) => {
  if (payoutStatus === PayoutStatus.DEFAULT) {
    return null;
  }

  const statusName: any = lodashGet(
    STATUS_CONFIG,
    `[${payoutStatus}].label`,
    ""
  );
  const icon: any = lodashGet(STATUS_CONFIG, `[${payoutStatus}].icon`, "");

  if (!statusName) {
    return null;
  }

  return (
    <StyledContainer $status={payoutStatus} className={className}>
      {!!icon && <Icon isSrcRelative src={icon} size="sm" />}
      <Label size="sm">{statusName}</Label>
    </StyledContainer>
  );
};

export default TransactionStatus;
