import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";

import AiOfferForm, { LoaderScreen } from "@/features/contracts/AiOfferForm";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { useParamState } from "@/services/Routing";
import {
  AiGeneratedContractError,
  ContractCreatePayload,
  ContractCreatorType,
  ContractTransactionType,
  ContractType,
} from "@/services/ContractsService";
import Modal, { useModalState } from "@/components/misc/Modal";
import { Body, Heading } from "@/components/Typography";
import { SITE_PATHS } from "@/config/routing";
import { useManageOnboardingCountry } from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";

import { useContractForm } from "../../ContractForm.context";
import { ContractFormValues, Step } from "../../ContractForm.types";
import { INITIAL_VALUES } from "../../ContractForm.config";
import PayoutOnboardingStep from "./PayoutOnboardingStep";

const StyledModalActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  & > * {
    border-radius: 10rem !important;
  }

  & > *:first-child {
    flex-grow: 1;
  }

  & > *:last-child {
    flex-grow: 3;
  }
`;

const StyledPennyLogo = styled.img`
  display: block;
  margin-inline: 0;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledAiOfferForm = styled(AiOfferForm)`
  height: 100%;
`;

const AiGenerationStep: React.FC = () => {
  const {
    setValues,
    gotToStep,
    aiFlowTextInput,
    setAiFlowTextInput,
    aiFlowFileInput,
    setAiFlowFileInput,
    didOfferGenerated,
    setDidOfferGenerated,
    aiGeneratedMissingValuesModelState,
    setAiGeneratedOfferDetails,
  } = useContractForm();
  const { isUpdating, update } = useManageOnboardingCountry();

  const [showLoader, setShowLoader] = useState(false);
  const [inputType] = useParamState<string>("ai_offer_input_type", "", {
    parseJson: true,
  });
  const isOnAiInputTypeStep = !inputType;

  const invalidContractModalState = useModalState();
  const maxTriesDepletedModalState = useModalState();
  const invalidDocumentModalState = useModalState();

  const handleSuccess = useCallback(
    async ({ contractDetails }: { contractDetails: ContractCreatePayload }) => {
      const hasSomeValues =
        !!contractDetails.description ||
        !!contractDetails.title ||
        !!contractDetails.milestones.some((milestone) => {
          return (
            !!milestone.name || !!milestone.description || !!milestone.value
          );
        });

      if (contractDetails) {
        if (hasSomeValues) {
          const contractDetailsSanitized = {
            ...contractDetails,
            files: [],
            client_name: "",
            contract_type: ContractType.Milestone,
          } as ContractFormValues;

          if (setAiGeneratedOfferDetails) {
            setAiGeneratedOfferDetails(contractDetailsSanitized);
          }

          const result = await setValues(contractDetailsSanitized, true);
          const hasErrors = !!Object.keys(result || {}).length;

          if (hasErrors) {
            aiGeneratedMissingValuesModelState.open();
            gotToStep(Step.ContractDetails);
          } else {
            gotToStep(Step.Review);
          }

          setShowLoader(false);
        } else {
          invalidContractModalState.open();
          setShowLoader(false);
        }
      } else {
        invalidContractModalState.open();
        setShowLoader(false);
      }
    },
    [
      gotToStep,
      setValues,
      invalidContractModalState,
      aiGeneratedMissingValuesModelState,
      setAiGeneratedOfferDetails,
    ]
  );

  const handleCreateManually = useCallback(() => {
    invalidContractModalState.close();
    setValues({
      ...INITIAL_VALUES,
      contract_type: ContractType.Milestone,
      created_as: ContractCreatorType.Payee,
      transaction_type: ContractTransactionType.FullPayment,
    } as ContractFormValues);
    setTimeout(() => {
      gotToStep(Step.ContractDetails);
    }, 500);
  }, [setValues, gotToStep, invalidContractModalState]);

  const handleFormGenerateTrigger = useCallback(
    ({ promise }: { promise?: Promise<any> }) => {
      setShowLoader(true);
      setDidOfferGenerated(true);

      promise?.catch(() => {
        invalidContractModalState.open();
        setShowLoader(false);
      });
    },
    [setDidOfferGenerated, invalidContractModalState]
  );

  const handleError = useCallback(
    ({ error }: { error: AiGeneratedContractError }) => {
      setShowLoader(false);

      if (error === AiGeneratedContractError.DailyTriesDepleted) {
        maxTriesDepletedModalState.open();
      } else if (error === AiGeneratedContractError.PdfReadError) {
        invalidDocumentModalState.open();
      }
    },
    [maxTriesDepletedModalState, invalidDocumentModalState]
  );

  //--------------------------------------

  if (isUpdating) {
    return <SplashScreen />;
  }

  return (
    <>
      {showLoader && <LoaderScreen />}

      <Modal
        state={invalidContractModalState}
        contentContainerProps={{ style: { textAlign: "center" } }}
        width="410px"
      >
        <StyledLogoContainer>
          <StyledPennyLogo
            alt="Logo"
            src="/assets/images/branding/penny/logo_round.svg"
          />
        </StyledLogoContainer>
        <Heading size="lg" className="mt-4">
          I'm 404-ing!
        </Heading>
        <Body size="lg" className="mt-2" style={{ color: "#818898" }}>
          I could not process the information you've provided. Please try again
          or create an offer manually.
        </Body>
        <StyledModalActionsContainer className="mt-3">
          <Button
            variant="secondary"
            colorVariant="gray"
            link={SITE_PATHS.HOME_PAGE}
          >
            Back to home
          </Button>
          <Button onClick={handleCreateManually}>Create manually</Button>
        </StyledModalActionsContainer>
      </Modal>

      <Modal
        state={maxTriesDepletedModalState}
        contentContainerProps={{ style: { textAlign: "center" } }}
        width="410px"
      >
        <StyledLogoContainer>
          <StyledPennyLogo
            alt="Logo"
            src="/assets/images/branding/penny/logo_round.svg"
          />
        </StyledLogoContainer>
        <Heading size="lg" className="mt-4">
          Uh oh!
        </Heading>
        <Body size="lg" className="mt-2" style={{ color: "#818898" }}>
          You've reached your limit for today. Please try again tomorrow.
        </Body>
        <StyledModalActionsContainer className="mt-3">
          <Button
            variant="secondary"
            colorVariant="gray"
            link={SITE_PATHS.HOME_PAGE}
          >
            Back to home
          </Button>
          <Button onClick={handleCreateManually}>Create manually</Button>
        </StyledModalActionsContainer>
      </Modal>

      <StyledAiOfferForm
        text={aiFlowTextInput}
        setText={setAiFlowTextInput}
        file={aiFlowFileInput}
        setFile={setAiFlowFileInput}
        showRegenerateButton={didOfferGenerated}
        onGenerateTrigger={handleFormGenerateTrigger}
        onError={handleError}
        onCreateManually={handleCreateManually}
        invalidDocumentModalState={invalidDocumentModalState}
        appendHeaderContent={
          didOfferGenerated && (
            <Button
              variant="ghost"
              onClick={() => {
                gotToStep(Step.Review);
              }}
            >
              <Icon
                isSrcRelative
                src="chevron_left.svg"
                customSize="1rem"
                colorVariant={isOnAiInputTypeStep ? "white" : "black"}
                style={{
                  transform: "rotate(180deg)",
                }}
              />
            </Button>
          )
        }
        onSuccess={handleSuccess}
        customOnboardingJsx={
          <PayoutOnboardingStep
            simpleLayout
            showBackButton={false}
            onCountrySelect={({ selectedCountry }) => {
              if (selectedCountry) {
                update({ country: selectedCountry });
                return Promise.resolve({ closeCountrySelect: false });
              }
            }}
          />
        }
      />
    </>
  );
};

export default AiGenerationStep;
