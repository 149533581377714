import * as React from "react";
// @ts-ignore
import createHost from "cross-domain-storage/host";

const getStorageHost = () =>
  createHost([
    {
      origin: "http://penny.paypipe.io",
      allowedMethods: ["set", "get", "remove"],
    },
  ]);

export default function useAiPromptFromPennySite() {
  const storage = React.useRef(getStorageHost());

  React.useEffect(() => {
    storage.current = getStorageHost();
  }, []);

  return null;
}
