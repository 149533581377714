import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Client, ClientOptions, BaseTransportOptions } from "@sentry/types";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import posthog, { PostHog } from "posthog-js";

import { APP_ENV } from "@/config/app";
import { api } from "@/config/services";

import packageJson from "@/../package.json";

export const POSTHOG_API_HOST =
  process.env.REACT_APP_POSTHOG_API_HOST! || "https://us.i.posthog.com";
export const POSTHOG_KEY =
  process.env.REACT_APP_POSTHOG_KEY! ||
  "phc_V6ww8snnXayZvXMDTRIyMzczADCi1JDmdBspKFdkmMc";

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN!;
const USE_POSTHOG_USER_DATA = false;

class ErrorReporting {
  sentryClient: null | Client<ClientOptions<BaseTransportOptions>> = null;
  posthogClient: PostHog | null = null;

  init = () => {
    if (!SENTRY_DSN) {
      return;
    }

    this.sentryClient =
      Sentry.init({
        dsn: SENTRY_DSN,
        debug: true,
        release: packageJson.version,
        environment: APP_ENV,
        integrations: [
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      }) || null;

    this.initPostHog();
  };

  //----------------------------

  initPostHog = () => {
    if (!POSTHOG_API_HOST || !POSTHOG_KEY) {
      return;
    }

    this.posthogClient =
      posthog.init(POSTHOG_KEY, {
        api_host: POSTHOG_API_HOST,
        person_profiles: "always",
        autocapture: true,
      }) || null;

    if (this.posthogClient) {
      this.posthogClient.capture("$set", {
        $set: {
          release: packageJson.version,
          environment: APP_ENV,
        },
      });
    }
  };

  //----------------------------

  setUser = (data?: {
    username?: string;
    id?: string | number;
    email?: string;
  }) => {
    if (this.sentryClient && data) {
      Sentry.setUser(data);
    }

    if (this.posthogClient && data) {
      this.posthogClient.identify(`${data.id || ""}`, {
        email: data.email,
        name: data.username,
        release: packageJson.version,
        environment: APP_ENV,
      });

      if (USE_POSTHOG_USER_DATA) {
        api.anonGet(
          `${POSTHOG_API_HOST}/api/projects/85934/persons/${data.id}/`,
          {
            headers: {
              Authorization: `Bearer phx_I8Fqov577cttzE3vPIh2GeuGR7QnggEDTjrk6jb0dUf9EDU`,
            },
          }
        );
      }
    }
  };

  report = (err: any) => {
    if (this.sentryClient && err) {
      this.sentryClient.captureException(err);
    }

    if (this.posthogClient && err) {
      this.posthogClient.captureException(err);
    }
  };
}

export default ErrorReporting;
